<flow-abs-preloader
  *ngIf="isLoading$ | async"
  [completed]="isSaved$ | async">
</flow-abs-preloader>

<div class="single-page">
  <div class="background"></div>
  <div class="container br-3 mb-5 panel">

    <div class="text-right pos-absolute" style="right: 0;">
      <a routerLink="/auth/logout" class="logoutLink button text-color-blue">
        <i class="material-icons color-danger">power_settings_new</i>
        {{ labels['general.label.logout'] }}
      </a>
    </div>

    <div class="content-wrapper text-center">
      <div class="d-flex justify-content-center mb-5">
        <flow-portal-logo
          [backgroundPosition]="['center']">
        </flow-portal-logo>
      </div>
      <h3>{{ labels['account_settings.label.two_factor_authentication'] }}</h3>
      <ng-container *ngIf="customer$ | async as customer">
        <flow-enforced-2fa-form
          [model]="customer"
          [allowed2FaConfig]="allowed2Fa"
          [isSaved]="isSaved$ | async"
          (save)="doSave($event)">
        </flow-enforced-2fa-form>
      </ng-container>
    </div>
  </div>
</div>
<flow-footer></flow-footer>
