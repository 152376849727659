/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

 import { Component, OnInit, ChangeDetectionStrategy, Inject, ViewEncapsulation } from '@angular/core';
 import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

 import { FlowHelpers, FlowZipCodeValidationService } from '@flow/core';
 import { FlowCountriesService } from '@flow/shared';
 import { FlowTranslateLabel, FlowTranslateService } from '@flow/translate';

 interface DataInterface {
  companies: any;
  source: string;
  countries: Array<any>;
 }

 @Component({
   selector: 'flow-company-search',
   templateUrl: './company-search.component.html',
   styleUrls: ['./company-search.component.scss'],
   changeDetection: ChangeDetectionStrategy.OnPush,
   encapsulation: ViewEncapsulation.None
 })
 export class FlowModalCompanySearchComponent implements OnInit {

  labels: FlowTranslateLabel;

  multipleCompanies: boolean;

  companyItems: string[];

  selectedCompanyIndex: number|null = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DataInterface,
    private dialogRef: MatDialogRef <FlowModalCompanySearchComponent>,
    private ZipCodeValidationService: FlowZipCodeValidationService,
    private CountriesService: FlowCountriesService,
    private TranslateService: FlowTranslateService
  ) { }

  ngOnInit() {
    this.multipleCompanies = this.data.companies.length > 1;

    this.labels = this._translateLabels();

    this.companyItems = this._getCompanyItems();
  }

  /**
   * User accepts company recommendation
   */
  onAccept(): void {
    const acceptedCompany = this.multipleCompanies
      ? this.data.companies[this.selectedCompanyIndex]
      : this.data.companies[0];

    this.dialogRef.close(acceptedCompany);
  }

  /**
   * User declines company recommendation
   */
  onDecline() {
    this.dialogRef.close(false);
  }

  /**
   * Get company items to show
   */
  private _getCompanyItems() {
    const items = [];

    this.data.companies.forEach(company => {
      items.push(this._getRecommendationContent(company));
    });

    return items;
  }

  /**
   * Get the source label
   */
  private _getSourceLabel(source: string): string {
    if ((source !== 'flow') && (source !== 'synergy')) {
      return this.TranslateService.instant({ key: `general.label.source.${source}` });
    }

    return source.toUpperCase();
  }

  /**
   * Get content for recommendation modal
   */
  private _getRecommendationContent(company: any) {
    // Get address line
    const _getAddressLine = (address) => {
      if (address && !FlowHelpers.isEmptyArray(address)) {
        const firstAddress = address[0];

        let line = '';

        line = firstAddress.address
               ? firstAddress.address + ' '
               : '';
        line = firstAddress.houseNumber
               ? line + firstAddress.houseNumber
               : line;
        line = firstAddress.houseLetter
               ? line + firstAddress.houseLetter
               : line;
        line = (firstAddress.postcode || firstAddress.city)
               ? line + '<br>'
               : line;
        line = firstAddress.postcode
               ? line + _prettifyZip(firstAddress.postcode, firstAddress.country)
               : line;
        line = firstAddress.city
               ? firstAddress.postcode
                 ? line + ' ' + firstAddress.city
                 : line + firstAddress.city
               : line;
        line = firstAddress.country
               ? line + '<br>' + this.CountriesService.getCountryViewName(this.data.countries, firstAddress.country)
               : line;

        return line;
      }

      return '';
    };

    const _prettifyZip = (zipCode: string, countryCode: string) => countryCode ? this.ZipCodeValidationService.prettifyValue(zipCode, countryCode) : zipCode;

    const { address } = company;
    const addressLine = _getAddressLine(address);
    const vatNumberFound = company.extras && company.extras.find(extra => extra.fieldName === 'edi_vatNumber');
    const kvkNumberFound = company.extras && company.extras.find(extra => extra.fieldName === 'edi_chamberOfCommerce');
    const glnNumberFound = company.extras && company.extras.find(extra => extra.fieldName === 'edi_GLN');
    const dunsNumberFound = company.extras && company.extras.find(extra => extra.fieldName === 'edi_DUNS');

    const vatNumber = (
      vatNumberFound
      && vatNumberFound.value !== ''
      && vatNumberFound.value.toLowerCase() !== 'null'
      && vatNumberFound.value.toLowerCase() !== 'false'
      && vatNumberFound.value.toLowerCase() !== 'true'
    ) ? vatNumberFound.value
      : '';

    const kvkNumber = (
      kvkNumberFound
      && kvkNumberFound.value !== ''
      && kvkNumberFound.value.toLowerCase() !== 'null'
      && kvkNumberFound.value.toLowerCase() !== 'false'
      && kvkNumberFound.value.toLowerCase() !== 'true'
      ) ? kvkNumberFound.value
        : '';

    const glnNumber = (
      glnNumberFound
      && glnNumberFound.value !== ''
      && glnNumberFound.value.toLowerCase() !== 'null'
      && glnNumberFound.value.toLowerCase() !== 'false'
      && glnNumberFound.value.toLowerCase() !== 'true'
      ) ? glnNumberFound.value
        : '';

    const dunsNumber = (
      dunsNumberFound
      && dunsNumberFound.value !== ''
      && dunsNumberFound.value.toLowerCase() !== 'null'
      && dunsNumberFound.value.toLowerCase() !== 'false'
      && dunsNumberFound.value.toLowerCase() !== 'true'
      ) ? dunsNumberFound.value
        : '';

      let numberLines = '';

      if (vatNumber) {
        numberLines +=
        '<div class="mt-3">' +
        this.TranslateService.instant({ key: 'general.label.vat_number' }) +
        '<br>' +
        '<span class="text-bold">' + vatNumber + '</span>' +
        '</div>';
      }

      if (kvkNumber) {
        numberLines +=
        '<div class="mt-3">' +
        this.TranslateService.instant({ key: 'general.label.chamber_of_commerce' }) +
        '<br>' +
        '<span class="text-bold">' + kvkNumber + '</span>' +
        '</div>';
      }

      if (dunsNumber) {
        numberLines +=
        '<div class="mt-3">' +
        this.TranslateService.instant({ key: 'general.label.duns_number' }) +
        '<br>' +
        '<span class="text-bold">' + dunsNumber + '</span>' +
        '</div>';
      }

      if (glnNumber) {
        numberLines +=
        '<div class="mt-3">' +
        this.TranslateService.instant({ key: 'general.label.gln_number' }) +
        '<br>' +
        '<span class="text-bold">' + glnNumber + '</span>' +
        '</div>';
      }

    return '<div class="ws-normal text-bold">' + (company.description || '') + '</div>' +
           '<div class="ws-normal text-bold mt-1">' + addressLine + '</div>' +
           '<div>' + numberLines + '</div>';
  }

  /**
   * Translate the labels
   */
  private _translateLabels(): FlowTranslateLabel {
    const labelsToTranslate: any = [
      'general.label.yes',
      'general.label.no',
      'general.label.confirm',
      'general.label.decline'
    ];

    if (this.data.companies.length > 1) {
      labelsToTranslate.push({
        key: 'general.modal.company_recommendation.title_multiple',
        params: {
          source: this._getSourceLabel(this.data.source.toLowerCase())
        }
      });

      labelsToTranslate.push('account_settings.label.accept_recommended_company_multiple');
    }
    else {
      labelsToTranslate.push({
        key: 'general.modal.company_recommendation.title',
        params: {
          source: this._getSourceLabel(this.data.source.toLowerCase())
        }
      });

      labelsToTranslate.push('account_settings.label.accept_recommended_company');
    }

    return this.TranslateService.translateSync(labelsToTranslate);
  }
}
