<!--
This component was used in help area before.
As help area was removed this template is now different.
Keep this here until we have a final decision about the help area.
<ng-content></ng-content>
<button *ngIf="!loading && !displayed" class="button button-help-area button-block br-3" [innerHTML]="label" (click)="open()"></button>
-->
<!-- <flow-preloader *ngIf="loading" [classes]="'white-foreground'"></flow-preloader> -->

<button
  *ngIf="!loading; else inactive"
  class="button"
  (click)="open()">
  <i class="material-icons" [matTooltip]="label">help</i>
</button>

<ng-template #inactive>
  <button class="button icon loading">
    <i class="material-icons" [matTooltip]="label">help</i>
  </button>
</ng-template>
