import * as fromWidgets from '../actions/widget.actions';
import { FlowWidgetModelInterface } from '@flow/core';

export interface WidgetModelStateInterface {
  flowWidgets: FlowWidgetModelInterface[];
  customerWidgets: FlowWidgetModelInterface[];
  data: FlowWidgetModelInterface[];
  loaded: boolean;
}

const initialState: WidgetModelStateInterface = {
  flowWidgets: [],
  customerWidgets: [],
  data: [],
  loaded: false,
};

export function reducer(
  state = initialState,
  action: fromWidgets.FlowWidgetsActions
): WidgetModelStateInterface {
  switch (action.type) {
    case fromWidgets.WidgetsActionTypes.LoadWidgets: {
      return state;
    }

    case fromWidgets.WidgetsActionTypes.LoadWidgetsSuccess: {
      return {
        ...state,
        data: action.payload.allWidgets,
        flowWidgets: action.payload.flowWidgets,
        customerWidgets: action.payload.customerWidgets,
        loaded: true
      };
    }

    case fromWidgets.WidgetsActionTypes.LoadWidgetsFail: {
      return {
        ...state,
        loaded: true
      };
    }

    case fromWidgets.WidgetsActionTypes.UnloadWidgets: {
      return {
        ...state,
        loaded: false,
        data: [],
        flowWidgets: [],
        customerWidgets: []
      };
    }

    default: {
      return { ...state };
    }
  }
}
