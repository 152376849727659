/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

import { FlowHelpers } from '@flow/core';

@Injectable({
  providedIn: 'root'
})
export class FlowHasNavigationExtrasStateGuard  {

  constructor(
    private router: Router
  ) { }

  /**
   * This guard is used to check existing state properties on NavigationExtras on given path.
   * Reloading the current path without having the state property redirects to root path (/)
   * to get the state properties from the main guards in app-routing.
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const routeExtrasState = this.router.getCurrentNavigation().extras.state;

    if (
      !routeExtrasState
      ||
      (routeExtrasState && !FlowHelpers.hasProperty(routeExtrasState, route.data['extrasStateKey']))
    ) {
      this.router.navigate(['/']);
      return false;
    }

    return true;
  }
}
