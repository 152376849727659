<form id="modal-terms" [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="d-flex align-items-center justify-content-between p-3 modal-title bg-primary color-white">
    <h5 class="truncate" [innerHTML]="labels['general.label.flow_privacy']"></h5>
  </div>
  <mat-dialog-content>
    <div>

      <ng-container *ngIf="data.isSupplierPortal; else defaultTermsTpl">

        <pre class="reAcceptTermsMsg mb-4" *ngIf="data.reAcceptTermsMsg">
          <span class="material-icons">info</span>
          <span>{{ data.reAcceptTermsMsg }}</span>
        </pre>

        <div class="mat-checkbox-wrapper d-block mt-2 mb-3" *ngIf="form.get('ProjectSupplierTerms')">
          <mat-checkbox formControlName="ProjectSupplierTerms">
            <span [flowInterpolateHtml]="labels['general.label.projectsupplier_terms_acceptance']"></span>
          </mat-checkbox>
        </div>

        <div class="mat-checkbox-wrapper d-block mt-2 mb-3" *ngIf="form.get('CustomerTerms')">
          <mat-checkbox formControlName="CustomerTerms">
          <span [flowInterpolateHtml]="labels['general.label.terms_customer_acceptance']"
                [interpolationOptions]="interpolationOptions"
          ></span>
          </mat-checkbox>
        </div>

        <div class="mat-checkbox-wrapper d-block mt-2 mb-3" *ngIf="form.get('CustomerPrivacy')">
          <mat-checkbox formControlName="CustomerPrivacy">
          <span [flowInterpolateHtml]="labels['general.label.privacy_terms_customer_acceptance']"
                [interpolationOptions]="interpolationOptions"
          ></span>
          </mat-checkbox>
        </div>

        <div class="mat-checkbox-wrapper d-block mt-2 mb-3" *ngIf="form.get('CustomerOperationalCommunication')">
          <mat-checkbox formControlName="CustomerOperationalCommunication">
            <span [flowInterpolateHtml]="labels['general.label.operational_communication_acceptance']"></span>
          </mat-checkbox>
        </div>
      </ng-container>

      <ng-template #defaultTermsTpl>
        <div class="mat-checkbox-wrapper d-block mt-2 mb-3" *ngIf="form.get('EULA')">
          <mat-checkbox formControlName="EULA">
            <span [flowInterpolateHtml]="labels['general.label.flow_terms_acceptance']"></span>
          </mat-checkbox>
        </div>

        <div class="mat-checkbox-wrapper d-block mt-2 mb-3" *ngIf="form.get('FlowPrivacyPolicy')">
          <mat-checkbox formControlName="FlowPrivacyPolicy">
            <span [flowInterpolateHtml]="labels['general.label.flow_privacy_acceptance']"></span>
          </mat-checkbox>
        </div>

        <div class="mat-checkbox-wrapper d-block mt-2 mb-3" *ngIf="form.get('FlowMarketingPolicy')">
          <mat-checkbox formControlName="FlowMarketingPolicy">
            <span [flowInterpolateHtml]="labels['general.label.marketing_policy']"></span>
          </mat-checkbox>
        </div>

        <div class="mat-checkbox-wrapper d-block mt-2 mb-3" *ngIf="form.get(currentPortalTermsKey)">
          <mat-checkbox [formControlName]="currentPortalTermsKey">
          <span class="color-blue-link" [innerHTML]="labels['general.label.company_terms_headline']"
                (click)="openCurrentPortalTerms($event)"></span>
          </mat-checkbox>
        </div>
      </ng-template>

    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <flow-save-button
      [buttonClass]="'button-primary'"
      [isSaving]="isLoading$ | async"
      [form]="form"
      [valid]="form.valid">
    </flow-save-button>
  </mat-dialog-actions>
</form>
