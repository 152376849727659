/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { FlowComponentsModule, FlowMaterialModule, Flow2FaModule } from '@flow/shared';

import { FlowLayoutModule } from '../layout/layout.module';

import { FlowEnforced2FaRoutingModule } from './enforced-2fa-routing.module';
import { FlowEnforced2FaComponent } from './containers/enforced-2fa/enforced-2fa.component';
import { FlowEnforced2FaFormComponent } from './components/enforced-2fa-form/enforced-2fa-form.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlowComponentsModule,
    FlowMaterialModule,
    Flow2FaModule,
    FlowLayoutModule,
    FlowEnforced2FaRoutingModule
  ],
  declarations: [
    FlowEnforced2FaComponent,
    FlowEnforced2FaFormComponent
  ]
})

export class FlowEnforced2FaModule {
}
