/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import {
  Component,
  ChangeDetectionStrategy,
  Input,
  HostBinding,
  OnChanges,
  SimpleChanges,
  HostListener,
  Output,
  EventEmitter
} from '@angular/core';

@Component({
  selector: 'flow-chat-conversation',
  templateUrl: './chat-conversation.component.html',
  styleUrls: ['./chat-conversation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlowChatConversationComponent implements OnChanges {

  @Input() conversation: any;

  @Input() isOnline: boolean;

  @Output() conversationOpened: EventEmitter <void> = new EventEmitter();

  @HostBinding('class')
  className = 'd-flex align-items-center list-item justify-content-between';

  @HostListener('click')
  onClick() {
    this.conversationOpened.emit();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.onlineUsers && !changes.onlineUsers.firstChange) {
      this.isOnline = !!changes.onlineUsers.currentValue.find(user => user._id === this.conversation.to);
    }
  }
}
