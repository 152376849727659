<div class="row p-4" *ngIf="!saving && !saved else savingContainerTpl">

  <form [formGroup]="form" style="max-width: 500px; min-width: 260px; margin: 0 auto;">
    <mat-form-field *ngIf="missing.GivenName">
      <mat-label>{{ labels['account_settings.label.given_name'] }}</mat-label>
      <input
        matInput
        formControlName="GivenName"
        required
        minlength="{{ nameMinLength }}">
      <mat-error
        [hidden]="!form.get('GivenName').invalid"
        [innerHTML]="form.get('GivenName').hasError('required')
          ? labels['generic.error.required']
          : form.get('GivenName').hasError('minlength')
            ? labelNameMinLengthError
            : form.get('GivenName').hasError('nameNotAllowedCharacters')
              ? labelNameInvalidCharsError( form.get('GivenName').getError('nameNotAllowedCharacters') )
              : form.get('GivenName').hasError('nameNotNumber')
                ? labelFieldCannotBeNumberError( labels['account_settings.label.given_name'] )
                : null"
      ></mat-error>
    </mat-form-field>

    <mat-form-field *ngIf="missing.FamilyName" class="mt-4">
      <mat-label>{{ labels['account_settings.label.family_name'] }}</mat-label>
      <input
        matInput
        formControlName="FamilyName"
        required
        minlength="{{ nameMinLength }}">
      <mat-error
        [hidden]="!form.get('FamilyName').invalid"
        [innerHTML]="form.get('FamilyName').hasError('required')
          ? labels['generic.error.required']
          : form.get('FamilyName').hasError('minlength')
            ? labelNameMinLengthError
            : form.get('FamilyName').hasError('nameNotAllowedCharacters')
              ? labelNameInvalidCharsError( form.get('FamilyName').getError('nameNotAllowedCharacters') )
              : form.get('FamilyName').hasError('nameNotNumber')
                ? labelFieldCannotBeNumberError( labels['account_settings.label.family_name'] )
                : null"
      ></mat-error>
    </mat-form-field>

    <div>
      <mat-error
        class="mt-3"
        [hidden]="!form.invalid"
        [innerHTML]="form.hasError('notAllowedFullName')
          ? labelNotAllowedFullNameError( form.getError('notAllowedFullName') )
          : null"
      ></mat-error>
    </div>

    <div class="text-right pt-3 pull-right">
      <button
        type="button"
        [disabled]="form.invalid || saving"
        class="button button-primary-outline"
        (click)="save()"
      >
        {{ saving ? labels['general.btn.saving'] : labels['generic.btn.save'] }}
      </button>
    </div>

  </form>
</div>

<ng-template #savingContainerTpl>
  <div class="d-block p-4 text-center">
    <ng-container *ngIf="saveError else saveSuccessTpl">
      <i class="material-icons icon-3x">error</i>
      <p class="mt-2" [innerHTML]="labels['general.error.request_error']"></p>
    </ng-container>
    <ng-template #saveSuccessTpl>
      <flow-preloader
        [completed]="saved"></flow-preloader>
      <p class="mt-2" *ngIf="saved" [innerHTML]="labels['msg.updated.information']"></p>
    </ng-template>
  </div>
</ng-template>
