<div class="single-page">
  <div class="background"></div>
  <div class="container br-3 mb-5 panel disclaimer">
    <div class="content-wrapper">
      <flow-abs-preloader *ngIf="loading$ | async else content"></flow-abs-preloader>
      <ng-template #content>
        <div class="logo-area" [ngClass]="{'project-logo': labels['projectTerms']}">
          <div>
            <ng-container *ngIf="labels['projectTerms']">
              <div
                class="portal-logo up-xs"
                flowBackgroundImage
                [imageUrl]="portalLogo"
                [backgroundSizeType]="'contain'"
                [backgroundPosition]="['center', 'left']">
              </div>
              <div
                class="portal-logo down-xs"
                flowBackgroundImage
                [imageUrl]="portalLogo"
                [backgroundSizeType]="'contain'"
                [backgroundPosition]="['center']">
              </div>
            </ng-container>
          </div>
          <div
            class="tiekinetix-logo"
            flowBackgroundImage
            [imageUrl]="'assets/images/tie_kinetix_sps_commerce_logo.svg'"
            [backgroundSizeType]="'contain'">
          </div>
        </div>
        <div [ngClass]="{'mt-4': labels['projectTerms'], 'mt-3': !labels['projectTerms']}">
          <div class="mb-3 text-right">
            <a
              href="/auth/login"
              [innerHTML]="labels['loginBtn']"
              class="button button-small button-primary-outline">
            </a>
          </div>
          <h5
            [innerHTML]="labels['title']"
            class="p-2 mb-3 text-center bg-primary color-white" >
          </h5>
        </div>
        <mat-tab-group
          *ngIf="labels['projectTerms'] else tieTermsOnly"
          mat-stretch-tabs="false"
          mat-align-tabs="start">
          <mat-tab [label]="labels['project']">
            <div class="p-3" [innerHTML]="labels['projectTerms'] | safe:'html'"></div>
          </mat-tab>
          <mat-tab label="TIE Kinetix">
            <div class="p-3" [innerHTML]="labels['tieTerms'] | safe:'html'"></div>
          </mat-tab>
        </mat-tab-group>
        <ng-template #tieTermsOnly>
          <div class="p-3" [innerHTML]="labels['tieTerms'] | safe:'html'"></div>
        </ng-template>
      </ng-template>
    </div>
  </div>
</div>
<flow-footer [showLinks]="false"></flow-footer>
