<ng-container *ngIf="projectLanguages$ | async">

  <div *ngIf="hasTranslations() && hasMissingTranslations" class="row mt-2 mb-4">
    <h5 *ngIf="helpTextId" class="col-12 col-md-9">{{ labels[helpTextId] }}</h5>
    <div class="col-12 text-right" [ngClass]="{ 'col-md-3': helpTextId}">
      <button
        type="button"
        class="button button-primary-outline button-small"
        [innerHTML]="labels['general.label.add_translation']"
        (click)="addTranslation()"
      ></button>
    </div>
  </div>

  <h5 *ngIf="!hasMissingTranslations && helpTextId" class="mt-2 mb-4">{{ labels[helpTextId] }}</h5>

  <ul class="translations-list">
    <li *ngIf="!hasTranslations() && helpTextId">
      <h5 class="mb-4">{{ labels[helpTextId] }}</h5>
    </li>
    <li *ngIf="!hasTranslations()" class="no-results py-4">
      <i class="material-icons icon-3x color-primary">edit</i>
      <span [innerHTML]="labels['general.label.no_translations']"></span>
      <button
        type="button"
        class="button button-primary-outline mt-2"
        [innerHTML]="labels['general.label.add_translation']"
        (click)="addTranslation()"
      ></button>
    </li>
    <li *ngFor="let translation of textBlock.translations | keyvalue; trackBy: trackByFn" [@fadeInOutAnimation]>
      <div class="translation-language">
        <span [innerHTML]="getLanguageName(translation.key)"></span>
        <small class="d-block color-infotext" [innerHTML]="translation.key"></small>
      </div>

      <div *ngIf="textBlock.type === 'input'" class="translation-preview d-flex mb-2">
        <mat-form-field [floatLabel]="'never'">
          <mat-label>{{ labels['general.label.text'] }}</mat-label>
          <input
            matInput
            name="translation-{{translation.key}}"
            [(ngModel)]="translation.value"
            [maxlength]="maxLength"
            (change)="updateTranslation(translation.key, translation.value)"
            required>
          <mat-hint align="end">{{ translation.value?.length || 0 }}/{{ maxLength }}</mat-hint>
          <div *ngIf="translation.value?.length === maxLength" class="mat-error custom">
            {{ labels['generic.error.maxlength'] }}
          </div>
        </mat-form-field>
      </div>

      <div *ngIf="textBlock.type === 'textarea'" class="translation-preview d-flex mb-2">
        <mat-form-field [floatLabel]="'never'">
          <mat-label>{{ labels['general.label.text'] }}</mat-label>
          <textarea
            matInput
            [rows]="rows"
            name="translation-{{translation.key}}"
            [(ngModel)]="translation.value"
            [maxlength]="maxLength"
            (change)="updateTranslation(translation.key, translation.value)"
            required>
          </textarea>
          <mat-hint align="end">{{ translation.value?.length || 0 }}/{{ maxLength }}</mat-hint>
          <div *ngIf="translation.value?.length === maxLength" class="mat-error custom">
            {{ labels['generic.error.maxlength'] }}
          </div>
        </mat-form-field>
      </div>

      <ul class="translation-options list-inline">
        <li class="mr-0">
          <button
            *ngIf="textBlock.type === 'htmlarea'"
            type="button"
            class="button button-icon color-primary"
            [matTooltip]="labels['general.btn.edit']"
            (click)="editTranslation(translation)">
            <i class="material-icons">edit</i>
          </button>
        </li>
        <li>
          <button
            type="button"
            class="button button-icon color-text"
            [matTooltip]="labels['general.btn.delete']"
            (click)="removeTranslation(translation.key)">
            <i class="material-icons">delete</i>
          </button>
        </li>
      </ul>
    </li>
  </ul>

</ng-container>