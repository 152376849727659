/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { Component, OnInit, OnDestroy, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators, ValidatorFn } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';

import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, pluck, shareReplay, takeUntil } from 'rxjs/operators';

import { FlowUtilsService, FlowRegexService, FlowHelpers, FlowTimezoneInterface, FlowEnvService } from '@flow/core';

import {
  FlowSsoUserInterface,
  FlowSsoCustomerFormModel,
  FlowSsoCustomerFormModelInterface,
  FlowSsoExtraInterface,
  FlowSsoCompanyInterface,
  FlowCustomerService,
  FlowProjectService,
  FlowUserService,
  FlowUserInterface,
  FlowSsoService
} from '@flow/auth';

import { FlowTranslateLabel, FlowTranslateService } from '@flow/translate';
import {
  FlowDialogsService,
  Flow2faService,
  nameNotAllowedCharsValidator,
  nameNotNumberValidator,
} from '@flow/shared';

import { FlowProfileChangePasswordComponent } from '../../modals/profile-change-password/profile-change-password.component';

import { slideInOutAnimation } from '../../../../animations';
import {
  FlowInterpolateHtmlService
} from '../../../marketplace-shared/directives/interpolate-html/interpolate-html.service';


@Component({
  selector: 'flow-profile-form',
  templateUrl: './profile-form.component.html',
  styleUrls: ['./profile-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [slideInOutAnimation]
})
export class FlowProfileFormComponent implements OnInit, OnDestroy {

  @Input() model: FlowSsoUserInterface;

  @Input() company: FlowSsoCompanyInterface;

  @Input() countries: any[];

  @Input() isExporting: boolean;

  @Input() disableDefaultBookmarkTemplate: boolean;

  @Output() startExport: EventEmitter <UntypedFormGroup> = new EventEmitter();

  @Output() save: EventEmitter <boolean | 'logout'> = new EventEmitter();

  private readonly _TFA = this.TwoFaService.tfaMappings;
  private readonly _TFA_LABELS = this.TwoFaService.tfaLabels;
  private readonly _DEFAULT_TFA = { id: this._TFA.googleAuthenticator, label: this._TFA_LABELS.googleAuthenticator };

  timezones: FlowTimezoneInterface[];

  labels: FlowTranslateLabel;
  labelNameMinLengthError: string;

  nameMinLength = 2;

  customerModel: FlowSsoCustomerFormModelInterface;

  form: UntypedFormGroup;
  impersonationForm: UntypedFormGroup;

  projectTerms: FlowSsoExtraInterface[];

  user: FlowUserInterface;

  vendorTimezoneText: string;
  showVendorTimezone$$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  lastLoginDate: boolean | string;

  tfa = this._TFA;
  selected2Fa = this._TFA.googleAuthenticator;
  allowed2Fa = [this._DEFAULT_TFA];

  isUsing2Fa: FlowSsoExtraInterface;
  isChatBought: boolean;
  isEinvoBought: boolean;
  isActingAsPartner: boolean;

  isSupplierPortal = false;
  customerTerms = [];

  showMarketingPolicy = true;

  interpolateHtmlParseLabelToString = label => this.InterpolateHtmlService.parseLabel(label, {}, false);


  get phoneNumber(): UntypedFormControl {
    return this.form.get('PhoneNumber') as UntypedFormControl;
  }

  get extendedProperties(): UntypedFormArray {
    return this.form.get('ExtendedProperties') as UntypedFormArray;
  }

  get marketingPolicyAcceptanceDate(): string {
    const value = this.getValueFromExtra(12);

    if (!value) {
      return null;
    }

    return value.indexOf && value.indexOf('|skip') > -1 ? value.replace('|skip', '') : value;
  }

  get geoProfilesText(): string {
    return this.getValueFromExtra(13) ?
      this.getValueFromExtra(13).split(',').map(element => this.countries.filter(country => country.cca2 === element).map(result => result.viewName)).join(', ') : '';
  }

  private _preSavedForTwoFactor = false;

  private _destroy$$ = new Subject<void>();

  private readonly _isSupplierPortal$: Observable <boolean|any>;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private TranslateService: FlowTranslateService,
    private CustomerService: FlowCustomerService,
    private EnvService: FlowEnvService,
    private UtilsService: FlowUtilsService,
    private RegexService: FlowRegexService,
    private UserService: FlowUserService,
    private SsoService: FlowSsoService,
    private ProjectService: FlowProjectService,
    private DialogsService: FlowDialogsService,
    private TwoFaService: Flow2faService,
    private InterpolateHtmlService: FlowInterpolateHtmlService,
  ) {
    this.timezones = this.UtilsService.getTimezones();

    this._isSupplierPortal$ = this.SsoService.getOnBoardingFormForPartners()
      .pipe(
        pluck('isSupplierPortal'),
        shareReplay(1),
      );

    // subscribe to pre-fetch and populate the buffer
    this._isSupplierPortal$.subscribe();
  }

  ngOnInit() {
    this.labels = this._translateLabels();
    this.labelNameMinLengthError = this.TranslateService.instant({
      key: 'generic.error.minlength',
      params: { number: this.nameMinLength }
    });

    this.isChatBought = this.UserService.isBoughtProduct('chat');
    this.isEinvoBought = this.UserService.isBoughtProduct('einvo');
    this.isActingAsPartner = this.UserService.isActingAsPartner();

    this.lastLoginDate = this._getLastLoginDate();

    this.ProjectService.getProjectExtras().subscribe(
      (response) => {
        if (response) {
          const allowed2Fa = this.UtilsService.getExtra(response, 'allowed2fa', 'fieldName');

          const showMarketingPolicy = this.UtilsService.getExtra(response, 'showMarketingPolicy', 'fieldName');

          if (allowed2Fa) {
            const { value } = allowed2Fa;

            if (value) {
              const allowed2FaParts = value.split(',');

              // clear previous options if any
              this.allowed2Fa = [];

              allowed2FaParts.forEach(tfa => {
                switch (tfa.trim().toLowerCase()) {
                  case 'google':
                    this.allowed2Fa.push({ id: this._TFA.googleAuthenticator, label: this._TFA_LABELS.googleAuthenticator });
                    break;
                  case 'sms':
                    this.allowed2Fa.push({ id: this._TFA.smsAuthenticator, label: this._TFA_LABELS.smsAuthenticator });
                    break;
                  case 'yubico':
                    this.allowed2Fa.push({ id: this._TFA.yubico, label: this._TFA_LABELS.yubico });
                    break;

                  default:
                    break;
                }
              });

              // set default tfa in case of wrong configuration
              if (FlowHelpers.isEmptyArray(this.allowed2Fa)) {
                this.allowed2Fa = [this._DEFAULT_TFA];
              }
            }
          }

          if (showMarketingPolicy) {
            this.showMarketingPolicy = showMarketingPolicy.value.toLowerCase() === 'true';
          }
        }

        this.vendorTimezoneText = this._getVendorTimezoneText();

        this.setForm();
        this.setImpersonationForm();

        // Force showing phonenumber error on init if existing phone number is not valid
        if (this.phoneNumber.hasError('pattern')) {
          this.phoneNumber.markAsTouched();
        }
      }
    );

    // Remove whitespaces from phone number field
    // only for non Safari browsers as Safari put the cursor always at the end
    if (!this.UtilsService.isSafari()) {
      this.phoneNumber.valueChanges.pipe(
        debounceTime(100),
        distinctUntilChanged(),
        takeUntil(this._destroy$$)
      )
      .subscribe(value => {
        this.phoneNumber.setValue(value.replace(/[\s\(\)\-\.]/g,''));

        // Force immediately showing error
        if (this.phoneNumber.hasError('pattern')) {
          this.phoneNumber.markAsTouched();
        }
      });
    }

    // Show vendor time zone based on users timezone
    this.extendedProperties.at(17).valueChanges.pipe(
      takeUntil(this._destroy$$)
    )
    .subscribe(extra => {
      this.showVendorTimezone$$.next(this.vendorTimezoneText && extra.Value === 'false');
    });

    this._isSupplierPortal$.subscribe(isSupplierPortal => {
      this.isSupplierPortal = isSupplierPortal;

      if ( isSupplierPortal ) {
        this.checkCustomerTermsAgreement();
      }
    });
  }

  ngOnDestroy(): void {
    this._destroy$$.next();
    this._destroy$$.complete();
    this.showVendorTimezone$$.complete();
  }

  getExtra(at: number): UntypedFormGroup {
    return this.extendedProperties.at(at) as UntypedFormGroup;
  }

  getValueFromExtra(at: number): any {
    return this.getExtra(at).value.Value;
  }

  hasAcceptedMarketingPolicy(): boolean {
    const value: any = this.getValueFromExtra(12);

    if (value === true || value === false) {
      return value;
    }
    else if (typeof value === 'string' || value instanceof String) {
      return value.indexOf('|skip') > -1;
    }
    else {
      return false;
    }
  }

  labelNameInvalidCharsError({chars}) {
    return `${ this.labels['generic.error.invalid_characters'] } <b>${ chars.join('') }</b>`;
  }

  labelFieldCannotBeNumberError(field) {
    return this.TranslateService.instant({
      key: 'generic.error.field_cannot_be_number',
      params: { field }
    });
  }

  setForm(): void {
    this.customerModel = new FlowSsoCustomerFormModel(this.formBuilder, this.TranslateService);
    this.form = this.customerModel.createForm(this.model);

    [
      this.form.get('GivenName'),
      this.form.get('FamilyName'),
    ].forEach( ctrl => ctrl.setValidators([
      nameNotAllowedCharsValidator(),
      nameNotNumberValidator(),
    ]) );

    // Remove LockoutEndDateUtc control as it is not used for the profile
    this.form.removeControl('LockoutEndDateUtc');

    // Set AutoLoadProduct as stored value from V1 differs from V2 value
    const autoloadProduct = this.getValueFromExtra(14);

    if (typeof autoloadProduct === 'string') {
      const autoloadProductParts = autoloadProduct.split(',');

      this.extendedProperties.at(14).get('Value').setValue(autoloadProductParts[autoloadProductParts.length - 1]);
    }

    // Show vendor time zone text
    this.showVendorTimezone$$.next(this.vendorTimezoneText && !this.extendedProperties.at(17).get('Value').value);

    this.projectTerms = this.extendedProperties.value.filter(extra => extra.PropertyName.endsWith('Terms'));

    // Determine the 2fa status.
    // this.isUsing2Fa = this.extendedProperties.value.find(extra => extra.PropertyName === 'FlowTwoFactorAuthentication' && extra.Value === true);
    this.isUsing2Fa = this.extendedProperties.value.find(extra =>
      extra.PropertyName === this._TFA.yubico
      ||
      extra.PropertyName === this._TFA.googleAuthenticator
      ||
      extra.PropertyName === this._TFA.smsAuthenticator
    );

    // Selected two factor auth method.
    this.selected2Fa = this.isUsing2Fa ? this.isUsing2Fa.PropertyName : this.allowed2Fa[0].id;

    // Force the value of the extra based on the extras that identify the 2factor.
    this.extendedProperties.at(15).get('Value').setValue(this.isUsing2Fa ? true : false);

    // add recovery email validators if 2fa is toggled on
    this._toggleRecoveryEmailValidators( !!this.extendedProperties.at(15).get('Value').value );

    if (this.isChatBought) {
      this._setChatEnabledControl();
    }
  }

  setImpersonationForm() {
    const values = {  //defaults
      byFlow: true,
      byVendor: false,
      allowedVendorUsers: [],
      inform: false,
    };
    let legacyAllowImpersonation = this._getExtra('AllowImpersonation');
    const allowImpersonationByFlowSupport = this._getExtra('AllowImpersonationByFlowSupport');
    const allowImpersonationByVendor = this._getExtra('AllowImpersonationByVendor');
    const allowedImpersonators = this._getExtra('AllowedImpersonationUsersShadow');
    const notifyOnImpersonation = this._getExtra('NotifyOnImpersonation');
    let byFlow;
    let allowedVendorUsers = [];
    let notify;

    // if property has not been set yet, then default to true
    if ( legacyAllowImpersonation === undefined || legacyAllowImpersonation === '' ) {
      legacyAllowImpersonation = true;
    }

    if (legacyAllowImpersonation === 'ask') {
      byFlow = true;
      notify = true;
    }
    else if ( legacyAllowImpersonation === true ) {
      byFlow = true;
    }

    // if no (truthy) legacy, then check if the new props are truthy
    if (!byFlow) {
      byFlow = allowImpersonationByFlowSupport;
    }
    if (!notify) {
      notify = notifyOnImpersonation;
    }

    if (allowedImpersonators) {
      allowedVendorUsers = allowedImpersonators.split(',');
    }

    Object.assign(values, {
      byFlow,
      byVendor: allowImpersonationByVendor,
      allowedVendorUsers,
      inform: notify
    });

    this.impersonationForm = this.formBuilder.group({
      byFlow:             [ values.byFlow ],
      byVendor:           [ values.byVendor ],
      allowedVendorUsers: [ values.allowedVendorUsers ],
      inform:             [ values.inform ],
    });
  }

  triggerPasswordChange(): void {
    this.DialogsService.open(FlowProfileChangePasswordComponent, {
      disableClose: true,
      autoFocus: false,
      maxWidth: '600px'
    });
  }

  onAddAllowedVendorUser($event: MatChipInputEvent) {
    // console.warn('onAddAllowedVendorUser user', event);

    if ($event.value) {
      const allowedVendorUsersCtrl = this.impersonationForm.get('allowedVendorUsers');

      allowedVendorUsersCtrl.value.push( $event.value );
      allowedVendorUsersCtrl.updateValueAndValidity();

      $event.input.value = '';
    }
  }

  onRemoveAllowedVendorUser(user) {
    // console.warn('onRemoveAllowedVendorUser user', user);

    const allowedVendorUsersCtrl = this.impersonationForm.get('allowedVendorUsers');

    allowedVendorUsersCtrl.value.splice( allowedVendorUsersCtrl.value.indexOf(user), 1 );
    allowedVendorUsersCtrl.updateValueAndValidity();
  }

  onTriggerGoogleAuthRegister() { // TODO: duplicate[2faTrigger]
    /* NOTE:
        This method is here to ensure we save FlowRecoveryEmail and trustDevices values BEFORE the google auth
        registration is complete. Reason is that on google auth register success the BE sets the "pkrd2" extra with a
        hash value. If FE performs a save after the success, then we would basically be saving without the "pkrd2"
        extra, which would then result in the previous BE "pkrd2" extra prop to be removed, which in turn would then
        result in the user NOT having the 2fa.
    */

    if ( !this._preSavedForTwoFactor ) {
      this._preSavedForTwoFactor = true;

      /*
      if either the "FlowRecoveryEmail" (3) or the "trustDevices" (6) or the "FlowTwoFactorAuthentication" (15) has been
      modified by the user, then we trigger a save event to ensure all 2fa values are stored in the BE before the google
      auth registration triggers a page reload.
      The check for the "FlowTwoFactorAuthentication" (15) is necessary to cover scenario where a user might disable the
      2fa, then do a save, and then re-enable the 2fa. E.g. when sms 2fa register fail, and user update the phone and
      unchecks the 2fa in order to save the phone and afterwards enables it again to proceed with the sms register.
      Without the chk, then the FlowTwoFactorAuthentication extra would not be set correctly on the BE.
      */
      if (
        this.extendedProperties.at(3).dirty
        ||
        this.extendedProperties.at(6).dirty
        ||
        this.extendedProperties.at(15).dirty
      ) {
        this.save.emit(false);
      }
    }
  }

  onTriggerSmsAuthRegister() {  // TODO: duplicate[2faTrigger]
    /* NOTE:
        This method is here to ensure we save FlowRecoveryEmail and trustDevices values BEFORE the google auth
        registration is complete. Reason is that on google auth register success the BE sets the "pkrd3" extra with a
        hash value. If FE performs a save after the success, then we would basically be saving without the "pkrd3"
        extra, which would then result in the previous BE "pkrd3" extra prop to be removed, which in turn would then
        result in the user NOT having the 2fa.
    */

    if ( !this._preSavedForTwoFactor ) {
      this._preSavedForTwoFactor = true;

      /*
      if PhoneNumber or
      if either the "FlowRecoveryEmail" (3) or the "trustDevices" (6) or the "FlowTwoFactorAuthentication" (15) has been
      modified by the user, then we trigger a save event to ensure all 2fa values are stored in the BE before the google
      auth registration triggers a page reload.
      The check for the "FlowTwoFactorAuthentication" (15) is necessary to cover scenario where a user might disable the
      2fa, then do a save, and then re-enable the 2fa. E.g. when sms 2fa register fail, and user update the phone and
      unchecks the 2fa in order to save the phone and afterwards enables it again to proceed with the sms register.
      Without the chk, then the FlowTwoFactorAuthentication extra would not be set correctly on the BE.
      */
      if (
        this.form.get('PhoneNumber').dirty
        ||
        this.extendedProperties.at(3).dirty
        ||
        this.extendedProperties.at(6).dirty
        ||
        this.extendedProperties.at(15).dirty
      ) {
        this.save.emit(false);
      }
    }
  }

  onSelectAuthType(): void {
    console.log(this.selected2Fa);
  }

  onChange2Fa(obj): void {
    this._toggleRecoveryEmailValidators( obj.checked );
  }

  confirm2Fa(result: boolean): void {
    if (true === result) {
      // this.extendedProperties.at(15).get('Value').setValue(true);
      // this.save.emit(true);
      window.location.reload();
    }
    else {
      this._preSavedForTwoFactor = false; // reset
    }
  }

  disableTwoFactorAuthentication(): void {
    this.DialogsService.textModal({
      closeButtonLabel: 'general.btn.cancel',
      title: 'general.label.warning',
      content: 'account_settings.label.confirm_disable_two_factor_auth',
      okButtonLabel: 'general.label.yes',
      cancelButtonLabel: 'general.label.no'
    })
    .afterClosed()
    .subscribe(result => {
      if (true === result) {
        // Search for the two factor auth method.
        const twoFactorTypeEnabled = this.extendedProperties.value
            .findIndex(extra =>
              extra.PropertyName === this._TFA.yubico
              ||
              extra.PropertyName === this._TFA.googleAuthenticator
              ||
              extra.PropertyName === this._TFA.smsAuthenticator
            );

        // Remove it.
        this.extendedProperties.removeAt(twoFactorTypeEnabled);

        // Search for the 'FlowTwoFactorAuthentication' extra.
        const flowTwoFactorAuthenticationIndex = this.extendedProperties.value
          .findIndex(extra => extra.PropertyName === 'FlowTwoFactorAuthentication' );

        // Remove it. (ST033461)
        flowTwoFactorAuthenticationIndex > -1 && this.extendedProperties.removeAt(flowTwoFactorAuthenticationIndex);

        // this.extendedProperties.at(15).get('Value').setValue(false);

        // Emit the save action.
        this.save.emit(true);
      }
    });
  }

  withdrawConsent(agreementExtraName) {
    this.DialogsService.textModal({
      title: 'general.label.warning',
      content: 'account_settings.label.confirm_withdraw_consent',
      okButtonLabel: 'general.label.yes',
      cancelButtonLabel: 'general.label.no'
    })
    .afterClosed()
    .subscribe(result => {
      if (result === true) {
        const extendedProperties = this.extendedProperties.value;

        // Search for the agreement extra index.
        const agreementExtraIndex = extendedProperties.findIndex(extra => extra.PropertyName === agreementExtraName);

        // Remove it.
        this.extendedProperties.removeAt(agreementExtraIndex);

        // Emit the save action.
        this.save.emit('logout');
      }
    });
  }

  private checkCustomerTermsAgreement() {
    // NOTE: business-logic should be in sync with "src/app/modules/layout/containers/wrapper/wrapper.component.ts"

    /* BUSINESS-LOGIC
      - If you are a user of a supplier in a portal (like AMS/ROT) and you are the company admin.. you must agree with
        all 4.

      - If you are a normal user of a supplier in a portal (like AMS/ROT), then you only need to agree with the latest
        2 (privacy_terms_customer_acceptance + operational_communication_acceptance).

      - If you are a user of the company that has purchased the FLOW portal (like AMS/ROT)  you should only accept the
        latest 2 (privacy_terms_customer_acceptance + operational_communication_acceptance) (as the customer already
        signed the offline contract with TIE Kinetix)

      - If you are entering a TIE Kinetix service bought online.. then the current (old) clauses apply.
    */

    const arrCustomerTerms = [];

    const project = this.EnvService.portalDomain;
    const isPartner = this.UserService.isActingAsPartner();
    const isAdmin = this.UserService.isAdmin();

    const agreementsExtraName = {
      projectSupplierTerms:             `ProjectSupplierTerms__${ project }`,
      customerTerms:                    `CustomerTerms__${ project }`,
      customerPrivacy:                  `CustomerPrivacy__${ project }`,
      customerOperationalCommunication: `CustomerOperationalCommunication__${ project }`,
    };

    const companyName = isPartner
      ? this.UserService.vendorDescription
      : ( this.UserService.customer.Description || this.UserService.customer.Name );

    if (isPartner) {
      if (isAdmin) { // ADMIN user
        // projectSupplierTerms
        arrCustomerTerms.push({
          extraName: agreementsExtraName.projectSupplierTerms,
          label: this.TranslateService.instant({
            key: 'account_settings.label.projectsupplier_terms_acceptance',
            params: { customer: companyName }
          }),
          acceptedOn: this.UserService.getExtraValue(agreementsExtraName.projectSupplierTerms),
          description: this.interpolateHtmlParseLabelToString( this.TranslateService.instant({
            key: 'general.label.projectsupplier_terms_acceptance',
            params: { customer: companyName }
          }) ),
        });

        //customerTerms
        arrCustomerTerms.push({
          extraName: agreementsExtraName.customerTerms,
          label: this.TranslateService.instant({
            key: 'account_settings.label.terms_customer_acceptance',
            params: { customer: companyName }
          }),
          acceptedOn: this.UserService.getExtraValue(agreementsExtraName.customerTerms),
          description: this.interpolateHtmlParseLabelToString( this.TranslateService.instant({
            key: 'general.label.terms_customer_acceptance',
            params: { customer: companyName }
          }) ),
        });
      }
      else {  // NORMAL user
      }
    }

    // customerPrivacy
    arrCustomerTerms.push({
      extraName: agreementsExtraName.customerPrivacy,
      label: this.TranslateService.instant({
        key: 'account_settings.label.privacy_terms_customer_acceptance',
        params: { customer: companyName }
      }),
      acceptedOn: this.UserService.getExtraValue(agreementsExtraName.customerPrivacy),
      description: this.interpolateHtmlParseLabelToString( this.TranslateService.instant({
        key: 'general.label.privacy_terms_customer_acceptance',
        params: { customer: companyName }
      }) ),
    });

    // customerOperationalCommunication
    arrCustomerTerms.push({
      extraName: agreementsExtraName.customerOperationalCommunication,
      label: this.TranslateService.instant({
        key: 'account_settings.label.operational_communication_acceptance',
        params: { customer: companyName }
      }),
      acceptedOn: this.UserService.getExtraValue(agreementsExtraName.customerOperationalCommunication),
      description: this.interpolateHtmlParseLabelToString( this.TranslateService.instant({
        key: 'general.label.operational_communication_acceptance',
        params: { customer: companyName }
      }) ),
    });

    this.customerTerms = arrCustomerTerms;
  }

  private _toggleRecoveryEmailValidators(state: boolean): void {
    const recoveryEmailCtrl = this.extendedProperties.at(3) as UntypedFormControl;

    recoveryEmailCtrl.setValidators( state
      ? [ Validators.required, this._validateRecoveryEmail(this.UserService.user.email) ]
      : []
    );

    // trigger updateValueAndValidity to prevent the field/extra staying as INVALID in case user toggles 2fa on then
    // off again, see ST035245 issue #2.
    recoveryEmailCtrl.updateValueAndValidity();
  }

  private _setChatEnabledControl(): void {
    this.form.addControl('ChatEnabled', this.formBuilder.group({
      enabled: false,
      writerRoleId: ''
    }));

    // Prefill values
    this._setChatEnabledValue();
  }

  private _setChatEnabledValue(): void {
    this.CustomerService.getAllRoles()
      .subscribe(roles => {
        if (!FlowHelpers.isEmptyArray(roles)) {
          const chatWriterRole = this.UtilsService.findIn(roles, 'Name', 'chat:writer');

          if (chatWriterRole && FlowHelpers.hasProperty(chatWriterRole, 'Id')) {
            const foundRole = this.UtilsService.hasExtra(this.model.Roles, chatWriterRole.Id, 'RoleId');
            this.form.get('ChatEnabled').setValue({ enabled: foundRole, writerRoleId: chatWriterRole.Id });
          }
        }
      }
    );
  }

  private _validateRecoveryEmail(userEmail: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const value = control.value.Value;
      const regExp = this.RegexService.getRegex('email');

      if (value && regExp.test(value)) {
        if (value === userEmail) {
          return { 'recoveryEmailMatchesUserEmail': true };
        }
        return null;
      }

      return { 'invalidRecoveryEmail': true };
    };
  }

  private _getLastLoginDate() {
    const { extra } = this.UserService.user;

    return this.UtilsService.getExtraValue(extra, 'LastLoginDate')
            ||
            this.UtilsService.getExtraValue(extra, 'CurrentLoginDate')
            ||
            ( new Date( Date.now() ) ).toISOString(); /* fallback to a new date if no extras available */
  }

  /**
   * finds and return an extra (by name) from the currently available form extendedProperties
   */
  private _getExtra(name) {
    const extraObj = this.extendedProperties.value.find( extra => extra.PropertyName === name );

    return extraObj && extraObj.Value;
  }

  /**
   * Get vendor time zone text
   */
  private _getVendorTimezoneText(): string {
    const vendorTimeZoneExtra = this.company && this.company.ExtendedProperties.find(extra => extra.PropertyName === 'Timezone');

    if (vendorTimeZoneExtra) {
      const timezone = this.UtilsService.getTimeZoneByName(vendorTimeZoneExtra.Value);

      if (timezone) {
        const { name, offset } = timezone;

        return this.TranslateService.instant({
          key: 'account_settings.user_timezone_set_by_company',
          params: {
            timezone: name,
            offset
          }
        });
      }
    }

    return;
  }

  private _translateLabels(): FlowTranslateLabel {
    return this.TranslateService.translateSync([
      'account_settings.label.given_name',
      'account_settings.label.family_name',
      'account_settings.label.phone',
      'account_settings.label.error.invalid.phone',
      'account_settings.label.recovery_email',
      'account_settings.btn.change_password',
      'account_settings.label.my_portal_settings',
      'account_settings.label.language',
      'account_settings.label.hidden_menu',
      'account_settings.label.hidden_menu.description',
      'account_settings.label.autoload_product',
      'account_settings.label.autoload_product.description',
      'account_settings.label.trust_devices',
      'account_settings.label.remember_device.description',
      'account_settings.label.notifications_by_email',
      'account_settings.label.notifications_by_email_description',
      'account_settings.label.notifications_by_email_daily',
      'account_settings.label.notifications_by_email_daily_description',
      'account_settings.label.extra',
      'account_settings.label.last_login',
      'account_settings.label.personal_data',
      'account_settings.label.select_product',
      'account_settings.label.security',
      'account_settings.label.impersonation',
      'account_settings.label.impersonation_allow',
      'account_settings.label.impersonation_allow_and_inform',
      'account_settings.label.impersonation_deny',
      'account_settings.label.impersonation.description',
      'account_settings.label.impersonation_by_flow',
      'account_settings.label.impersonation_by_flow_description',
      'account_settings.label.impersonation_by_vendor',
      'account_settings.label.impersonation_by_vendor_description',
      'account_settings.label.impersonation_allowed_users',
      'account_settings.label.impersonation_enter_email',
      'account_settings.label.impersonation_inform',
      'account_settings.label.impersonation_inform_description',
      'account_settings.label.two_factor_authentication',
      'account_settings.label.two_factor_authentication_description',
      'account_settings.label.notifications',
      'account_settings.label.document_notifications',
      'account_settings.btn.configure_document_notifications',
      'account_settings.label.privacy',
      'account_settings.label.geographic_information',
      'account_settings.label.geo_profiles',
      'account_settings.label.geo_profiles.description',
      'account_settings.label.timezone',
      'account_settings.label.timezone.description',
      'generic.label.eula',
      'generic.label.flow_privacy',
      'generic.label.flow_marketing_privacy',
      'account_settings.label.withdraw_consent',
      'account_settings.label.confirm_withdraw_consent',
      'account_settings.label.not_accepted',
      'account_settings.label.accepted_on',
      'general.label.export_user_data_csv',
      'account_settings.label.privacy_about_description',
      'account_settings.label.use_vendor_timezone',
      'account_settings.btn.change_password',
      'account_settings.label.authentication_type',
      'account_settings.register.hardware_key',
      'account_settings.label.remove_hardware_key',   /*// TODO: still used?*/
      'account_settings.button.register_google_authenticator_app',
      'account_settings.label.tfa_verification_sms_description',
      'account_settings.button.send_tfa_verification_sms',
      'account_settings.label.recovery_email_before_tfa_setup',
      'account_settings.label.disable_two_factor_authentication',
      'account_settings.label.enable_disable_chat',
      'account_settings.label.enable_disable_chat.description',
      'generic.error.invalid.email',
      'generic.error.required',
      'generic.error.minlength',
      'generic.error.invalid_characters',
      'generic.error.field_cannot_be_number',
      'account_settings.error.invalid.recovery_email',
      'account_settings.label.bookmark_template.description',
      'general.label.bookmark_template',
      'general.label.bookmark_template.hint',
      'general.label.flow_terms_acceptance',
      'general.label.flow_privacy_acceptance',
      'general.label.marketing_policy',
    ]);
  }
}
