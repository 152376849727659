/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { Component, ChangeDetectionStrategy, Input, HostBinding, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'flow-chat-conversations',
  templateUrl: './chat-conversations.component.html',
  styleUrls: ['./chat-conversations.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlowChatConversationsComponent {

  private _conversations: any[] = [];

  @Input() onlineUsers: any;

  @Input() currentConversation: any;

  @Input()
  set conversations(value: any[]) {
    if (!value) {
      return;
    }
    this._conversations = value;
  }

  get conversations(): any[] {
    return this._conversations;
  }

  @Output() conversationOpened: EventEmitter <any> = new EventEmitter();

  @HostBinding('class')
  classes = 'full-height full-height-flex';

  openConversation(index: number) {
    this.conversationOpened.emit(this.conversations[index]);
  }

  addConversation($event: any) {
    this._conversations.push($event);
    this.conversationOpened.emit($event);
  }
}
