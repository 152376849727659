import * as fromCategories from '../actions/category.actions';
import { FlowCategoryModelInterface } from '@flow/core';

export interface CategoryModelStateInterface {
  total: number;
  data: { [id: string]: FlowCategoryModelInterface};
  loaded: boolean;
}

const initialState: CategoryModelStateInterface = {
  total: 0,
  data: {},
  loaded: false,
};

export function reducer(
  state = initialState,
  action: fromCategories.FlowCategoriesActions
): CategoryModelStateInterface {
  switch (action.type) {
    case fromCategories.CategoriesActionTypes.LoadCategories: {
      return state;
    }

    case fromCategories.CategoriesActionTypes.LoadCategoriesSuccess: {

      const data = action.payload.data.reduce(
        (entities: { [id: string]: FlowCategoryModelInterface}, category: FlowCategoryModelInterface) => ({
            ...entities,
            [category.name]: category,
          }),
        {
          ...state.data,
        }
      );

      return {
        ...state,
        total: action.payload.total,
        data,
        loaded: true
      };
    }

    case fromCategories.CategoriesActionTypes.LoadCategoriesFail: {
      return {
        ...state,
        total: 0,
        data: {},
        loaded: true
      };
    }

    default: {
      return { ...state };
    }
  }
}
