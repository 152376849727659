import { Action } from '@ngrx/store';
import { FlowCategoryModelInterface } from '@flow/core';

export enum CategoriesActionTypes {
  LoadCategories = '[FLOW Marketplace] FLOW Categories LOAD',
  LoadCategoriesSuccess = '[FLOW Marketplace] FLOW Categories LOAD SUCCESS',
  LoadCategoriesFail = '[FLOW Marketplace] FLOW Categories LOAD FAIL'
}

export class LoadCategories implements Action {
  readonly type = CategoriesActionTypes.LoadCategories;
  constructor(public payload: any = {}) {}
}
export class LoadCategoriesSuccess implements Action {
  readonly type = CategoriesActionTypes.LoadCategoriesSuccess;

  constructor(public payload: { total: number; data: FlowCategoryModelInterface[] }) {}
}

export class LoadCategoriesFail implements Action {
  readonly type = CategoriesActionTypes.LoadCategoriesFail;

  constructor(public payload: string) {
  }
}
export type FlowCategoriesActions = LoadCategories | LoadCategoriesSuccess | LoadCategoriesFail;
