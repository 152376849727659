/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, HostBinding, Output, EventEmitter } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { animate, style, transition, trigger } from '@angular/animations';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'flow-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({transform: 'translateX(-100%)'}),
        animate('200ms ease-in', style({transform: 'translateX(0%)'}))
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({transform: 'translateX(-100%)'}))
      ])
    ])
  ]
})
export class FlowContactFormComponent implements OnInit, OnDestroy {

  show$: BehaviorSubject <boolean> = new BehaviorSubject(false);

  form: UntypedFormGroup;

  @HostBinding('@slideInOut') get slideInOut() {
    return true;
  }

  goBack: EventEmitter <void> = new EventEmitter();

  constructor(
    private formBuilder: UntypedFormBuilder
  ) {
    this.form = this.formBuilder.group({
      name: new UntypedFormControl('', [Validators.required]),
      lastName: new UntypedFormControl('', [Validators.required]),
      email: new UntypedFormControl('', [Validators.required]),
      message: new UntypedFormControl('', [Validators.required]),
    });
  }

  ngOnInit(): void {
    setTimeout(() => this.show$.next(true));
  }

  ngOnDestroy(): void {
    this.show$.next(false);
  }

  onSubmit(): void {
    console.log(this.form.value);
  }
}
