<div class="single-page">
  <div class="background"></div>
  <div class="container br-3 mb-5 panel">

    <div class="text-right pos-absolute" style="right: 0;">
      <a routerLink="/auth/logout" class="logoutLink button text-color-blue">
        <i class="material-icons color-danger mr-0 mb-0" style="font-size: 1.125rem">power_settings_new</i>
        {{ labels['general.label.logout'] }}
      </a>
    </div>

    <div class="content-wrapper text-center">
      <div class="d-flex justify-content-center">
        <flow-portal-logo
          [routerLink]="['/auth/logout']"
          [backgroundPosition]="['center']">
        </flow-portal-logo>
      </div>
      <div class="mt-5">
        <ng-container [ngSwitch]="projectAccess">
          <div *ngSwitchCase="'onboarding_pending'" class="message color-success">
            <em class="material-icons">check_circle</em>
            <div class="text-center" [innerHTML]="labels['login.label.accesspage.onboarding_pending']"></div>
          </div>

          <div *ngSwitchCase="'rejected'">
            <div class="message color-danger">
              <em class="material-icons">cancel</em>
              <div class="text-center" [innerHTML]="labels['login.label.accesspage.onboarding_rejected']"></div>
            </div>
            <div>
              <p class="text-color-blue pt-4 mb-3" [innerHTML]="onboarding.heading"></p>
              <a (click)="onboard()">
                <div class="list-item-with-arrow">
                  <div class="item">
                    <div class="title" [innerHTML]="onboarding.label"></div>
                  </div>
                  <em class="material-icons color-gray ml-5 arrow">arrow_forward</em>
                </div>
              </a>
            </div>
          </div>

          <div *ngSwitchCase="'disabled'" class="message color-danger">
            <em class="material-icons">cancel</em>
            <div class="text-center" [innerHTML]="labels['login.label.accesspage.onboarding_disabled']"></div>
          </div>

          <div *ngSwitchDefault class="message color-danger">
            <div class="text-center"[innerHTML]="labels['error.general.error_loading']"></div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
<flow-footer></flow-footer>
