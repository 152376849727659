/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { FlowAuthService } from '@flow/auth';
import { FlowRouterService } from '@flow/core';
import { FlowPortalService } from '@flow/shared';

@Injectable({
  providedIn: 'root'
})
export class FlowHomepageGuard  {

  constructor(
    private AuthService: FlowAuthService,
    private RouterService: FlowRouterService,
    private PortalService: FlowPortalService
  ) { }

  /**
   * Redirects user to custom redirect url.
   * Defaults to '/dashboard'.
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const afterLoginUrl = this.PortalService.getCustomRedirectUrl();

    this.RouterService.navigate(afterLoginUrl[0] === '/'
      ? this.AuthService.afterLoginUrl
      : afterLoginUrl
    );

    return false;
  }
}
