/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { FlowTranslateLabel, FlowTranslateService } from '@flow/translate';

@Component({
  selector: 'flow-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlowSupportComponent implements OnInit {

  labels: FlowTranslateLabel;

  hideButtons: boolean;

  constructor(
    private activatedRoute: ActivatedRoute,
    private TranslateService: FlowTranslateService
  ) {}

  ngOnInit() {
    const hideButtonsParam = this.activatedRoute.snapshot.queryParamMap.get('hideButtons');

    this.labels = this._translateLabels();

    this.hideButtons = (hideButtonsParam && hideButtonsParam.toLowerCase() === 'true') || false;
  }

  private _translateLabels(): FlowTranslateLabel {
    return this.TranslateService.translateSync([
      'general.btn.login',
      'support.label.page_title',
      'support.label.page_introduction',
      'support.label.features_introduction',
      'support.label.login.description_a',
      'support.label.guide.description_a',
      'support.label.login.description_b',
      'support.label.knowledge_base.title',
      'support.label.knowledge_base.description',
      'support.label.submit_request.title',
      'support.label.request.description',
      'support.label.submit_request.title',
      'support.label.submit_request.description',
      'support.label.guide.title',
      'support.label.guide.description',
      'support.label.system_status.title',
      'support.label.system_status.description',
      'support.label.system_status_without_link.description',
      'support.label.system_status.button'
    ]);
  }
}
