import * as fromCompany from '../actions/company.actions';
import { FlowSsoCompanyInterface } from '@flow/auth';

export interface CompanyStateInterface {
  loading: boolean;
  loaded: boolean;
  saved: boolean;
  data: FlowSsoCompanyInterface;
}

const initialState: CompanyStateInterface = {
  loading: false,
  loaded: false,
  saved: false,
  data: null,
};

export function reducer(
  state = initialState,
  action: fromCompany.FlowCompanyActions
): CompanyStateInterface {
  switch (action.type) {

    case fromCompany.CompanyActionTypes.LoadCompany: {
      return {
        ...state,
        loading: !state.loaded
      };
    }

    case fromCompany.CompanyActionTypes.LoadCompanySuccess: {
      return {
        ...state,
        loaded: true,
        loading: false,
        data: action.payload
      };
    }

    case fromCompany.CompanyActionTypes.LoadCompanyFail: {
      return {
        ...state,
        loaded: false,
        loading: false,
        data: null,
      };
    }

    case fromCompany.CompanyActionTypes.CompanyLoadingStatus: {
      return {
        ...state,
        loading: action.payload
      };
    }

    case fromCompany.CompanyActionTypes.UnloadCompany: {
      return {
        ...state,
        loading: false,
        loaded: false,
        data: null,
      };
    }

    case fromCompany.CompanyActionTypes.UpdateCompany: {
      const newData = {
        ...state.data,
        ...action.payload
      };

      return {
        ...state,
        loading: true,
        saved: false,
        data: action.payload
      };
    }

    case fromCompany.CompanyActionTypes.UpdateCompanyResult: {
      return {
        ...state,
        saved: action.payload,
        // loading: false

        // Commented this out, as this would hide the preloader before showing the completed status
        // <flow-abs-preloader *ngIf="isLoading$ | async" [completed]="isSaved$ | async"></flow-abs-preloader>
        // Loading is manually set to false in the isSaved$ observable after a timeout.
      };
    }

    default:
      return {
        ...state
      };
    break;

  }
}
