<div id="top-left">
  <flow-portal-logo [routerLink]="['/dashboard']"></flow-portal-logo>
  <!-- <div id="help-trigger" class="d-none d-xl-flex align-items-center" (click)="toggleHelp()">
    <i class="material-icons mr-2"
       [ngClass]="{'rotate-90': helpPanelVisible === 'yes' }">arrow_forward</i>
    <span [innerHTML]="labels['general.label.help']"></span>
  </div> -->
</div>

<ul class="list-inline d-flex flex-row align-items-center">

  <!-- <li class="d-xl-none">
    <button class="button " e2e="help" (click)="toggleHelp()">
      <i class="material-icons" [matTooltip]="labels['general.label.help']">help</i>
    </button>
  </li> -->

  <!-- start notifications -->
  <li>
    <button #notificationsTrigger="matMenuTrigger"
            class="button"
            e2e="notifications"
            (menuOpened)="updateNotifications()"
            [matMenuTriggerFor]="notificationsMenu">
      <span class="unread-notifications-badge" *ngIf="showNotificationBadge"></span>
      <i class="material-icons" [matTooltip]="labels['general.label.notifications']">
        {{ showNotificationBadge ? 'notification_important' : 'notifications' }}
      </i>
    </button>
    <mat-menu #notificationsMenu="matMenu" [class]="'notifications-menu'">
      <flow-menu-top-notifications
        [isMenuOpen]="notificationsTrigger.menuOpen"
        [notifications$]="notifications$"
        (hasNotifications)="onHasNotifications()">
      </flow-menu-top-notifications>
    </mat-menu>
  </li>
  <!-- end of notifications -->

  <!-- start chat -->
  <li *ngIf="!isMobile && isChatBought">
    <button class="button" e2e="chat" (click)="toggleChat()">
      <span class="unread-notifications-badge"></span>
      <i class="material-icons" [matTooltip]="labels['general.label.chat']">chat</i>
    </button>
  </li>
  <!-- end of chat -->

  <!-- start help -->
  <li>
    <flow-help-icon></flow-help-icon>
  </li>
  <!-- end help -->

  <!-- start userlane -->
  <li *ngIf="!isMobile">
    <button class="button" (click)="openUserlanes()">
      <i class="material-icons large" [matTooltip]="labels['general.label.userlane']">explore</i>
    </button>
  </li>
  <!-- end userlane -->

  <!-- start account menu -->
  <li class="dropdown-menu">
    <button class="button account" e2e="user" [matMenuTriggerFor]="topMenu">
      <flow-gravatar
        [location]="'menu-top'"
        [width]="27"
        [height]="27"
        [email]="profileHeader.email"
        [tooltip]="tooltipAccount">
      </flow-gravatar>
    </button>

    <p class="fs-12 text-center pos-absolute color-gray user-access-limited-warning"
       *ngIf="showAccessLimitedWarning"
    >
      <i class="material-icons fs-14 mr-1">warning</i>
      <span [innerHTML]="labels['general.label.access_limited']"></span>
    </p>

    <mat-menu #topMenu="matMenu" [class]="'topMenuDropdown'">
      <ng-template matMenuContent>
        <div class="p-2 profile-header">
          <h6 class="fw-500 truncate mb-1" [innerHTML]="profileHeader.fullName"></h6>
          <small class="truncate" [innerHTML]="profileHeader.email"></small>
          <small class="truncate" [innerHTML]="profileHeader.company"></small>
        </div>
        <button
          mat-menu-item
          (click)="openProfile()"
          [innerHTML]="labels['general.footer.link.account']">
        </button>
        <ng-container *ngFor="let item of menu.main">
          <button
            mat-menu-item
            [ngClass]="item.class || null"
            [disabled]="item.disabled"
            *ngIf="item.trigger"
            [matMenuTriggerFor]="item.trigger">
            <i class="material-icons color-danger" *ngIf="item.icon" [innerHTML]="item.icon"></i>
            {{ item.name }}
          </button>

          <button
            mat-menu-item
            [ngClass]="item.class || null"
            [disabled]="item.disabled"
            [routerLink]="item.link"
            *ngIf="!item.trigger">
            <i class="material-icons color-danger" *ngIf="item.icon" [innerHTML]="item.icon"></i>
            {{ item.name }}
          </button>
        </ng-container>
      </ng-template>

      <!--
      <mat-menu #productsMenu="matMenu" [class]="'topMenuDropdown'">
        <ng-template matMenuContent>
          <button mat-menu-item
                  *ngFor="let item of menu.products" [innerHTML]="item.name"
                  [disabled]="item.disabled"
                  [routerLink]="item.link"></button>
        </ng-template>
      </mat-menu>

      <mat-menu #customersMenu="matMenu" [class]="'topMenuDropdown'">
        <ng-template matMenuContent>
          <button mat-menu-item *ngFor="let item of menu.customers" [innerHTML]="item.name"
                  [disabled]="item.disabled"
                  [routerLink]="item.link"></button>
        </ng-template>
      </mat-menu>

      <mat-menu #contentMenu="matMenu" [class]="'topMenuDropdown'">
        <ng-template matMenuContent>
          <ng-container *ngFor="let item of menu.content">
            <button
              mat-menu-item
              [ngClass]="item.class || null"
              *ngIf="item.trigger"
              [disabled]="item.disabled"
              [matMenuTriggerFor]="item.trigger">
              <i class="material-icons color-danger" *ngIf="item.icon" [innerHTML]="item.icon"></i>
              {{ item.name + (item.disabled ? ' @todo' : '') }}
            </button>

            <button
              mat-menu-item
              [ngClass]="item.class || null"
              [routerLink]="item.link"
              [disabled]="item.disabled"
              *ngIf="!item.trigger">
              <i class="material-icons color-danger" *ngIf="item.icon" [innerHTML]="item.icon"></i>
              {{ item.name + (item.disabled ? ' @todo' : '') }}
            </button>
          </ng-container>
        </ng-template>
      </mat-menu>

      <mat-menu #cmsMenu="matMenu" [class]="'topMenuDropdown'">
        <ng-template matMenuContent>
          <button mat-menu-item
                  *ngFor="let item of menu.cms" [innerHTML]="item.name"
                  [disabled]="item.disabled"
                  [routerLink]="item.link"></button>
        </ng-template>
      </mat-menu>

      <mat-menu #sitesMenu="matMenu" [class]="'topMenuDropdown'">
        <ng-template matMenuContent>
          <button mat-menu-item
                  *ngFor="let item of menu.sites" [innerHTML]="item.name"
                  [disabled]="item.disabled"
                  [routerLink]="item.link"></button>
        </ng-template>
      </mat-menu>

      <mat-menu #settingsMenu="matMenu" [class]="'topMenuDropdown'">
        <ng-template matMenuContent>
          <button mat-menu-item
                  *ngFor="let item of menu.settings" [innerHTML]="item.name"
                  [disabled]="item.disabled"
                  [routerLink]="item.link"></button>
        </ng-template>
      </mat-menu>
       -->
    </mat-menu>
  </li>
  <!-- end account menu -->
</ul>
