<flow-menu-main-bookmark
  *ngFor="let bookmark of bookmarks"
  [bookmark]="bookmark"
  [routerLink]="['/solutions', bookmark.id]"
  [routerLinkActive]="'current-menu-item'"
  [label]="bookmark.name"
  [iconClass]="'flow-icon'"
  [icon]="bookmark.id"
  [boughtProducts]="boughtProducts">
</flow-menu-main-bookmark>
