/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { Component, OnInit, ChangeDetectionStrategy, Input, ViewChild } from '@angular/core';

import { BehaviorSubject } from 'rxjs';
import { delay } from 'rxjs/operators';

import { FlowTextBlockModelInterface } from '@flow/core';
import { FlowUserService } from '@flow/auth';
import { FlowTranslateLabel, FlowTranslateService } from '@flow/translate';

import { FlowTextBlockTranslationsComponent } from '../textblock-translations/textblock-translations.component';

@Component({
  selector: 'flow-textblock',
  templateUrl: './textblock.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlowTextBlockComponent implements OnInit {

  @Input() labelId: string;

  @ViewChild(FlowTextBlockTranslationsComponent) textBlock: FlowTextBlockTranslationsComponent;

  isLoading$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  isSaving$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  labels: FlowTranslateLabel;
  platform: string;

  textBlockData: FlowTextBlockModelInterface;

  constructor(
    private TranslateService: FlowTranslateService,
    private UserService: FlowUserService
  ) { }

  ngOnInit(): void {
    this.labels = this.TranslateService.translateSync(['general.btn.save']);
    this.platform = this.UserService.company;

    this.TranslateService.getTextBlockById(`${this.labelId}?t=${Date.now()}`, {platform: this.platform})
    .subscribe(
      (result) => {
        this.isLoading$.next(false);

        if (result) {
          this.textBlockData = result;
        }
        else {
          this.textBlockData = {
            id: 'terms.legal',
            page: 'terms',
            type: 'htmlarea',
            translations: {}
          };
        }
      }
    );
  }

  save(): void {
    this.isSaving$.next(true);
    this.TranslateService
      .updateTextBlock(this.textBlock.textBlock, this.platform)
      .pipe(delay(500))
      .subscribe(() => this.isSaving$.next(false));
  }
}
