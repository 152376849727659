<mat-form-field [formGroup]="form">
  <mat-select
    [formControlName]="controlName"
    [required]="required">
    <mat-optgroup
      *ngFor="let group of themeGroups"
      [label]="group.title">
      <mat-option
        *ngFor="let theme of group.themes"
        [value]="theme.slug">
        {{ theme.name }}
      </mat-option>
    </mat-optgroup>
  </mat-select>
</mat-form-field>
