import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';

import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map, pluck, switchMap, withLatestFrom } from 'rxjs/operators';

import {
  FlowHelpers,
  FlowUtilsService,
  FlowModelsService,
  FlowWidgetModelInterface,
  KinetixExtraModel
} from '@flow/core';

import { FlowAuthService, FlowUserService, FlowProjectService } from '@flow/auth';

import * as fromActions from '../actions/widget.actions';

@Injectable()
export class WidgetEffect {

  load$ = createEffect((): Observable <Action> =>
    this.actions$.pipe(
    ofType<fromActions.FlowWidgetsActions>(fromActions.WidgetsActionTypes.LoadWidgets),
    pluck('payload'),
    switchMap(() => {

      // Flow widgets
      const widgets = [
        this.ModelsService.list<FlowWidgetModelInterface>('Widget', {
        platform: 'Flow',
        sort: 'name',
        asc: true
      })];

      if (this.UserService.company !== 'Flow') {
        // Company widgets
        widgets.push(this.ModelsService.list<FlowWidgetModelInterface>('Widget', {
          platform: this.UserService.company,
          sort: 'name',
          asc: true
        }));

        // Project widgets
        if (this.UserService.isActingAsPartner()) {
          widgets.push(this.ModelsService.list<FlowWidgetModelInterface>('Widget', {
            platform: this.AuthService.vendor,
            sort: 'name',
            asc: true
          }));
        }
      }

      return forkJoin(widgets).pipe(
        withLatestFrom(this.ProjectService.getProjectExtras()),
        map(([result, extras]) => {
          this._projectExtras = extras;

          const flowWidgets = this.flagAsFlow(result[0].data);
          const items = [
            ...flowWidgets,
            ...(result[1] && result[1].data) ? result[1].data : [],
            ...(result[2] && result[2].data) ? result[2].data : []
          ];

          return new fromActions.LoadWidgetsSuccess({
            allWidgets: this.parseWidgets(items),
            flowWidgets,
            customerWidgets: result[1] && result[1].data
          });
        }),
        catchError( err => of(new fromActions.LoadWidgetsFail(err)))
      );
    })
    )
  );

  private _projectExtras: KinetixExtraModel[];

  constructor(
    private actions$: Actions,
    private UtilsService: FlowUtilsService,
    private ModelsService: FlowModelsService,
    private AuthService: FlowAuthService,
    private UserService: FlowUserService,
    private ProjectService: FlowProjectService
  ) {}

  parseWidgets(widgets: FlowWidgetModelInterface[]): FlowWidgetModelInterface[] {
    const user = FlowHelpers.deepClone(this.UserService.user);

    // ST040983: Check if project has extra OMM_enabled set to true
    const foundOmmEnabled = this.UtilsService.getExtra(this._projectExtras, 'OMM_enabled', 'fieldName');
    let isOmmEnabledProject = false;

    if (foundOmmEnabled) {
      const { value } = foundOmmEnabled;

      isOmmEnabledProject = FlowHelpers.parseToPrimitive(value) === true;
    }

    const visibleWidgets = [];

    if (!user || FlowHelpers.isEmptyArray(widgets)) {
      return [];
    }

    // Get acquired product from current user
    const acquiredProducts = [...user.acquiredProducts];
    const portalIndex = acquiredProducts.indexOf('portal');
    const projects = !FlowHelpers.isEmptyArray(user.vendors) ? user.vendors.map(vendor => vendor.toLowerCase()) : [];
    const hasPlatform = projects.find(project => project === user.platform.toLowerCase());

    if (!hasPlatform) {
      projects.push(user.platform.toLowerCase());
    }

    // Sort acquired products and add portal to beginning of array
    if (portalIndex > -1) {
      acquiredProducts.splice(portalIndex, 1);
    }

    acquiredProducts.sort().unshift('portal');

    widgets.forEach(widget => {
      const hasSystem = widget.extra.find(setting => setting.fieldName === 'systemWidget');
      const hasBlock = widget.extra.find(setting => setting.fieldName === 'block');
      const hasAllPartnersWidget = widget.extra.find(setting => setting.fieldName === 'allPartnersWidget');

      let addWidget = true;
      let couldBeBlocked = false;
      let checkedInProject = false;

      widget.isSystem = false;
      widget.isAllPartners = false;
      widget.isWhitelistVisible = false;
      widget.isBlocked = false;

      // System widgets are shown to everyone and can not be whitelisted or blacklisted
      if (hasSystem && hasSystem.value !== 'false') {
        widget.isSystem = true;
        addWidget = true;
      }

      // ST040983: For OMM_enabled projects allPartners widgets are shown to all partners of the project and can not be whitelisted or blacklisted
      else if (isOmmEnabledProject && hasAllPartnersWidget && hasAllPartnersWidget.value === 'true') {
        widget.isAllPartners = true;
        addWidget = true;
      }

      // Show project widgets only to project users/companies
      else {
        const widgetWhitelist = widget.extra
          .filter(extra => extra.fieldName === 'whitelistCustomerProjects' && extra.value !== '')
          .map(whitelist => whitelist.value.split(',').map(item => item.trim().toLowerCase()))[0];

        const widgetBlacklist = widget.extra
          .filter(extra => extra.fieldName === 'blacklistCustomerProjects' && extra.value !== '')
          .map(blacklist => blacklist.value.split(',').map(item => item.trim().toLowerCase()))[0];

        if (widgetWhitelist && widgetWhitelist.length >= 1) {
          const whitelist = widgetWhitelist.filter(item => projects.indexOf(item) !== -1);

          addWidget = (whitelist.length >= 1);
          couldBeBlocked = (whitelist.length >= 1);
          checkedInProject = true;
        }
        else {
          if (widgetBlacklist && widgetBlacklist.length >= 1) {
            const blacklist = widgetBlacklist.filter(item => projects.indexOf(item) !== -1);

            addWidget = (blacklist.length === 0);
            checkedInProject = true;
          }
        }

        // Only check these conditions if `whitelistCustomerProjects` or `blacklistCustomerProjects` have not been found.
        if (!checkedInProject) {
          const isWidgetWhitelistedForCompany = widget.extra
            .filter(extra => extra.fieldName === 'whitelistCompany' && extra.value !== '')
            .map(whitelist=> whitelist.value.split(',').map(item => item.trim().toLowerCase()))[0];

          const isWidgetBlacklistedForCompany = widget.extra
            .filter(extra => extra.fieldName === 'blacklistCompany' && extra.value !== '')
            .map(blacklist => blacklist.value.split(',').map(item => item.trim().toLowerCase()))[0];

          if (isWidgetWhitelistedForCompany && isWidgetWhitelistedForCompany.length >= 1) {
            const whitelistForCompany = isWidgetWhitelistedForCompany.filter(item => user.platform.toLowerCase() === item);

            addWidget = (whitelistForCompany.length >= 1);
            couldBeBlocked = (whitelistForCompany.length >= 1);
          }
          else {
            if (isWidgetBlacklistedForCompany && isWidgetBlacklistedForCompany.length >= 1) {
              const blacklistForCompany = isWidgetBlacklistedForCompany.filter(item => user.platform.toLowerCase() === item);

              addWidget = (blacklistForCompany.length === 0);
            }
          }
        }

        // Show document manager widgets only if einvo product has been bought
        if (addWidget) {
          if (widget.type === 'einvo') {
            addWidget = this.UserService.isBoughtProduct('einvo');
          }
        }

        // Additionally check for widget links only if widget can be added based on whitelist/blacklist settings
        if (addWidget) {
          if (!FlowHelpers.isEmptyArray(widget.links)) {
            widget.links.forEach(link => {
              if (link.type === 'connection') {
                const hasPermissionsExtra = link.extra.find(extra => extra.fieldName === 'permission');

                addWidget = false;

                (link.products as any).forEach(product => {
                  const linkedProduct = (typeof product === 'string') ? product : product.id;

                  if (acquiredProducts.indexOf(linkedProduct) > -1) {
                    if (hasPermissionsExtra) {
                      const permissionFound = hasPermissionsExtra.value
                        .split(',')
                        .some(permission => this.UserService.hasRole(linkedProduct, permission.trim()));

                      if (permissionFound) {
                        addWidget = true;
                      }
                    }
                    else {
                      addWidget = true;
                    }
                  }
                });
              }
              /*
              NOT USED FOR NOW
              else if (link.type === 'portal') {
                addWidget = true;

                // Show portal widgets to all users
                if (hasPermissionsExtra) {
                  addWidget = hasPermissionsExtra.value.split(',').some(permission => this.UserService.hasRole('portal', permission.trim()));
                }
              }
              */
            });
          }
        }
      }

      if (addWidget) {
        // Check visibility of whitelisted widgets
        if (couldBeBlocked) {
          const hasVisibility = widget.extra.find(setting => setting.fieldName === 'whitelistCompanyVisible');

          if (!FlowHelpers.isEmptyObject(hasVisibility)) {
            widget.isWhitelistVisible = (hasVisibility.value === 'true') ? true : false;
          }
        }

        // Check if widget could be removed by user
        if (!FlowHelpers.isEmptyObject(hasBlock)) {
          widget.isBlocked = (!hasBlock.value || hasBlock.value === 'no' || hasBlock.value === 'false')
                                ? false
                                : true;
        }

        visibleWidgets.push(widget);
      }
    });

    return visibleWidgets;
  }

  /**
   * Flag widgets created on FLOW platform to make translation work
   * when they are used on customer portals
   */
  flagAsFlow(widgets: FlowWidgetModelInterface[]): FlowWidgetModelInterface[] {
    return widgets.map(widget => ({ ...widget, creator: 'flow' }));
  }
}
