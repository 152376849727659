<ng-container *ngIf="notifications.length else noNotifications">
  <flow-menu-top-notification
    *ngFor="let notification of notifications; let i = index"
    [notificationsCount]="notifications.length"
    [notification]="notification"
    [messagesCount]="notification.messagesCount"
    [isRead]="notification.read"
    [currentIndex]="i"
    [labels]="labels"
    (remove)="onRemove(i)">
  </flow-menu-top-notification>
</ng-container>
<ng-template #noNotifications>
  <div class="p-3 color-black text-center" [innerHTML]="labels['general.label.no_new_notifications']"></div>
</ng-template>
