import { Action } from '@ngrx/store';
import { FlowProductModelInterface } from '@flow/core';

export enum ProductsActionTypes {
  LoadProducts = '[FLOW Marketplace] FLOW Products LOAD',
  LoadProductsSuccess = '[FLOW Marketplace] FLOW Products LOAD SUCCESS',
  LoadProductsFail = '[FLOW Marketplace] FLOW Products LOAD FAIL',
  UnloadProducts = '[FLOW Marketplace] UNLOAD PRODUCTS'
}

export class LoadProducts implements Action {
  readonly type = ProductsActionTypes.LoadProducts;
  constructor(public payload: any = {}) {}
}
export class LoadProductsSuccess implements Action {
  readonly type = ProductsActionTypes.LoadProductsSuccess;

  constructor(public payload: { total: number; data: FlowProductModelInterface[] }) {}
}

export class LoadProductsFail implements Action {
  readonly type = ProductsActionTypes.LoadProductsFail;

  constructor(public payload: string) {
  }
}

export class UnloadProducts implements Action {
  readonly type = ProductsActionTypes.UnloadProducts;
}

export type FlowProductsActions = LoadProducts | LoadProductsSuccess | LoadProductsFail | UnloadProducts;
