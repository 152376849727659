<h2 class="mb-2" [innerHTML]="labels['help.label.title']"></h2>
<h6 class="mb-3" [innerHTML]="labels['help.label.title_description']"></h6>

<div class="row pt-md-5">
  <div class="help-block-wrapper"
       *ngFor="let block of blocks"
       [ngClass]="block.columns">
    <h2 [innerHTML]="block.title"></h2>
    <div class="row" [ngClass]="block.classes ? block.classes.join(' ') : ''">
      <div class="col-12" [ngClass]="innerBlock.columns" *ngFor="let innerBlock of block.blocks">
        <flow-help-block
          [id]="innerBlock.id"
          [title]="innerBlock.title"
          [image]="innerBlock.image"
          [buttonTitle]="innerBlock.buttonTitle"
          (clickEvent)="sectionChanged.emit(innerBlock.id)"
        ></flow-help-block>
      </div>
    </div>
  </div>
</div>
