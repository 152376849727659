/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { BehaviorSubject, forkJoin } from 'rxjs';

import { FlowEnvService, FlowUtilsService } from '@flow/core';
import { FlowAuthService } from '@flow/auth';
import { FlowPortalService } from '@flow/shared';
import { FlowTranslateLabel, FlowTranslateService } from '@flow/translate';

@Component({
  selector: 'flow-disclaimer',
  templateUrl: './disclaimer.component.html',
  styleUrls: ['./disclaimer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlowDisclaimerComponent implements OnInit {

  labels: FlowTranslateLabel = {};

  portalLogo: string;

  loading$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    private EnvService: FlowEnvService,
    private UtilsService: FlowUtilsService,
    private AuthService: FlowAuthService,
    private PortalService: FlowPortalService,
    private TranslateService: FlowTranslateService
  ) {}

  ngOnInit() {
    this.loading$.next(true);

    this.portalLogo = this.PortalService.logo;

    forkJoin([
      this.TranslateService.fetchLabels([
        'general.privacy_policy.headline',
        'general.privacy_policy.content',
        'login.footer.link.privacy',
        'login.btn.go_to_login'
      ]),
      this.TranslateService.getTextBlockById('terms.legal', { platform: this.EnvService.portalDomain })
    ])
    .subscribe(
      results => {
        const currentLang = this.TranslateService. getCurrentLanguage();
        const defaultLang = this.TranslateService. getDefaultLanguage();

        this.labels['tieTerms'] = results[0]['general.privacy_policy.content'];
        this.labels['loginBtn'] = results[0]['login.btn.go_to_login'];

        this.labels['projectTerms'] = results[1]
                                        ? results[1].translations[currentLang]
                                          ? results[1].translations[currentLang]
                                          : results[1].translations[defaultLang]
                                            ? results[1].translations[defaultLang]
                                            : false
                                        : false;

        if (this.labels['projectTerms']) {
          this.labels['projectTerms'] = this.UtilsService.decodeHtml(this.labels['projectTerms']);
          this.labels['title'] = results[0]['login.footer.link.privacy'];
          this.labels['project'] = this.AuthService.vendor;
        }
        else {
          this.labels['title'] = results[0]['general.privacy_policy.headline'];
        }

        this.loading$.next(false);
      }
    );
  }
}
