/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { FlowHelpers, FlowRouterService, FlowEnvService, FlowCmsService  } from '@flow/core';
import { FlowUserInterface } from '@flow/auth';

@Injectable({
  providedIn: 'root'
})
export class FlowProjectTypeGuard  {

  constructor(
    private RouterService: FlowRouterService,
    private EnvService: FlowEnvService,
    private CmsService: FlowCmsService
  ) { }

  /**
   * Check if user of a onboarding_pending project type needs to be redirected to the access path
   * after being rejected, disabled or being in onboarding pending state
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const project = this.EnvService.portalDomain.toLowerCase();

    return this.CmsService.get<FlowUserInterface>('me', { source: project })
    .pipe(
      map(response => {
        if (!FlowHelpers.isEmptyObject(response)) {
          const { projectType, projectAccess } = response;

          let sendToAccessPage = false;

          if (projectType) {
            if (projectType === 'onboarding_pending') {
              if (projectAccess) {
                // Possible values for projectAccess:
                // accepted, onboarding_pending, rejected, disabled
                const casesForAccessRedirect = ['onboarding_pending', 'rejected', 'disabled'];

                if (casesForAccessRedirect.indexOf(projectAccess) > -1) {
                  sendToAccessPage = true;
                }
              }
            }
            else {
              // Not implemented yet
              // Future cases here
            }
          }

          if (sendToAccessPage) {
            this.RouterService.navigate(['access'], { state: { projectAccess, project } });
            return false;
          }
        }

        return true;
      }),
      // TODO: Create an error page to redirect to
      catchError(() => of(false))
    );
  }
}
