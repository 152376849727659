/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { Component, OnInit, ChangeDetectionStrategy, Input, HostBinding, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';

import { select, Store } from '@ngrx/store';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { FlowProductModelInterface } from '@flow/core';
import { FlowTranslateService } from '@flow/translate';
import { FlowMarketplaceStateInterface } from '@flow/marketplaceStore/reducers';
import { getBoughtProducts } from '@flow/marketplaceStore';


@Component({
  selector: 'flow-bought-products-dropdown',
  templateUrl: './bought-products-dropdown.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlowBoughtProductsDropdownComponent implements OnInit {

  @Input() form: UntypedFormGroup;

  @Input() controlName: string;

  @Input() showDashboard = true;

  @Input() required = true;

  @Input() fieldLabel: string;

  @Output() selectionChange: EventEmitter <{id: string; name: string}> = new EventEmitter(null);

  @HostBinding('class') classes = 'd-block';

  products$: Observable <FlowProductModelInterface[]>;

  label: string;

  private _products: FlowProductModelInterface[];

  constructor(
    private store: Store <FlowMarketplaceStateInterface>,
    private TranslateService: FlowTranslateService
  ) { }

  ngOnInit() {
    this.products$ = this.store.pipe(
      select(getBoughtProducts),
      tap((products => this._products = products))
    );
    this.label = this.TranslateService.instant({ key: 'account_settings.label.dashboard' });
  }

  selectionChanged($event: MatSelectChange): void {
    const product = this._products.find(item => item.id === $event.value);
    if (!product) {
      return;
    }
    this.selectionChange.emit({
      id: product.id,
      name: product.name
    });
  }
}
