/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import {
  Component,
  ChangeDetectionStrategy,
  Input,
  AfterViewInit,
  ElementRef,
  HostBinding,
  OnDestroy,
  ViewChildren,
  QueryList,
  Output,
  EventEmitter
} from '@angular/core';

import { merge, Subscription } from 'rxjs';

import { FlowProductModelInterface } from '@flow/core';
import { FlowLayoutService } from '@flow/shared';

import { FlowMenuMainBookmarkComponent } from '../menu-main-bookmark/menu-main-bookmark.component';

@Component({
  selector: 'flow-menu-main-bookmarks',
  templateUrl: './menu-main-bookmarks.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlowMenuMainBookmarksComponent implements AfterViewInit, OnDestroy {

  @Input() bookmarks: any;

  @Input() boughtProducts: FlowProductModelInterface[];

  @HostBinding('class') classes = 'tabs tabs-nav-wrapper';

  @ViewChildren(FlowMenuMainBookmarkComponent) bookmarkNodes: QueryList<FlowMenuMainBookmarkComponent>;

  @Output() bookmarksChanged: EventEmitter<any> = new EventEmitter();

  subscription$$: Subscription = new Subscription();

  width: number;

  constructor(
    private elementRef: ElementRef,
    private LayoutService: FlowLayoutService
  ) { }

  ngAfterViewInit(): void {
    this.subscription$$ = merge(
      this.LayoutService.resize$,
      this.bookmarkNodes.changes
    ).subscribe(() => {
      setTimeout(() => this.calculate(), 100);
    });
  }

  ngOnDestroy(): void {
    this.subscription$$.unsubscribe();
  }

  calculate() {
    this.width = parseInt(this.elementRef.nativeElement.offsetWidth, 10);

    let acc = 0;

    const hiddenBookmarks = [];

    this.bookmarkNodes.forEach(bookmark => {
      acc = acc + parseInt(bookmark.node.nativeElement.offsetWidth + 4, 10);

      if (acc <= this.width) {
        bookmark.node.nativeElement.classList.add('bookmark-visible');
      }
      else {
        const hasHiddenIcon = this._getHiddenIcon(bookmark.bookmark);

        bookmark.node.nativeElement.classList.remove('bookmark-visible');

        bookmark.bookmark.hideIcon = hasHiddenIcon;

        hiddenBookmarks.push(bookmark.bookmark);
      }
    });

    this.bookmarksChanged.emit(hiddenBookmarks);
  }

  private _getHiddenIcon(bookmark): boolean {
    if (this.boughtProducts) {
      const foundProduct = this.boughtProducts.find(product => product.id === bookmark.id);

      if (foundProduct) {
        const { extra } = foundProduct;

        if (extra) {
          const hideIcon = extra.find(item => item.fieldName.toLowerCase() === 'hideicon');

          return hideIcon ? hideIcon.value.toLowerCase() === 'true' : false;
        }
      }
    }

    return false;
  }

}
