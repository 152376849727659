<flow-gravatar
  [classNames]="['mr-2', 'user-avatar', 'bg-white', 'p-1', 'br-3']"
  [width]="36"
  [height]="36"
  [email]="conversation.to"></flow-gravatar>

<span class="online-status"
      [ngClass]="{
        'is-online': isOnline
      }"></span>
