/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, HostBinding, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { debounceTime, filter, startWith, switchMap, tap } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { FlowTranslateLabel, FlowTranslateService } from '@flow/translate';
import { FlowChatService } from '../../services/chat/chat.service';
import { FlowChatSearchResultInterface, FlowChatResultUserInterface } from '../../interfaces/chat-search-result.interface';

@Component({
  selector: 'flow-chat-search',
  templateUrl: './chat-search.component.html',
  styleUrls: ['./chat-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlowChatSearchComponent implements OnInit, OnDestroy {

  searchForm: UntypedFormGroup;

  results$: Observable <FlowChatSearchResultInterface[]>;

  labels: FlowTranslateLabel;

  isSearching$: Subject <boolean> = new Subject();

  @HostBinding('class') class = 'd-flex flex-column';

  @Output() conversationOpen: EventEmitter <FlowChatResultUserInterface> = new EventEmitter();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private TranslateService: FlowTranslateService,
    private ChatService: FlowChatService
  ) {
    this.searchForm = this.formBuilder.group({
      search: new UntypedFormControl('')
    });
  }

  ngOnInit(): void {
    this.labels = this.TranslateService.translateSync([
      'chat.label.search_companies',
      'chat.label.start_conversation',
      'chat.label.scope',
      'chat.label.general'
    ]);

    this.results$ = this.searchForm.get('search')
      .valueChanges
      .pipe(
        startWith(''),
        debounceTime(300),
        filter(value => value.length >= 3),
        switchMap(value => {
          this.isSearching$.next(true);
          return this.ChatService.search({
            query: value
          }).pipe(
            tap(() => this.isSearching$.next(false))
          );
        })
      );
  }

  ngOnDestroy(): void {
    this.isSearching$.complete();
  }

  startConversation(user: FlowChatResultUserInterface): void {
    this.conversationOpen.emit(user);
  }
}
