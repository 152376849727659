/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { Injectable } from '@angular/core';
import { FlowCmsService, FlowHelpers } from '@flow/core';
import { FlowAuthService, FlowUserService } from '@flow/auth';
import { Observable, of } from 'rxjs';
import { FlowChatSearchResultsInterface } from '../../interfaces/chat-search-results.interface';
import { FlowChatSearchResultInterface } from '../../interfaces/chat-search-result.interface';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class FlowChatService {

  constructor(
    private CmsService: FlowCmsService,
    private AuthService: FlowAuthService,
    private UserService: FlowUserService
  ) {
  }

  search(params: { from?: number; size?: number; query: string; project?: string }): Observable<FlowChatSearchResultInterface[]> {
    const defaults = {
      from: 0,
      size: 15,
      query: ''
    };

    params = {
      ...defaults,
      ...params
    };

    if (!this.AuthService.isDefaultVendor(this.AuthService.vendor)) {
      params.project = this.AuthService.vendor;
    }

    return this.CmsService.get<{ [key: string]: FlowChatSearchResultsInterface[] }>('chat/search', params).pipe(
      map(results => this.parseCompanies(results)),
      catchError(() => of([]))
    );
  }

  getOpenConversations(): Observable<any[]> {
    return of(this.parseConversations([
        {
          'id': 0,
          'success': {
            '_id': '5510712378778708815610969389944189782',
            'id': '5510712378778708815610969389944189782',
            'lastMessageId': '2b180b8b-53d7-9001-3c61-1521c37933ec',
            'date': '2019-09-13T14:02:51.152+0200',
            'users': {
              'jose#sanchez@tiekinetix#com': '2b180b8b-53d7-9001-3c61-1521c37933ec',
              'david#turonbes@tiekinetix#com': '2b180b8b-53d7-9001-3c61-1521c37933ec'
            },
            'userInfo': {
              'jose#sanchez@tiekinetix#com': {'company': '', 'role': []},
              'david#turonbes@tiekinetix#com': {'company': '', 'role': []}
            }
          }
        },
        {
          'id': 1,
          'success': {
            '_id': '46191010230589175476983511750240',
            'id': '46191010230589175476983511750240',
            'lastMessageId': '',
            'date': '2019-09-13T14:02:45.166+0200',
            'users': {'jose#sanchez@tiekinetix#com': '', 'bernd#lachmuth@tiekinetix#com': ''},
            'userInfo': {
              'jose#sanchez@tiekinetix#com': {'company': '', 'role': []},
              'bernd#lachmuth@tiekinetix#com': {'company': '', 'role': []}
            }
          }
        },
        {
          'id': 2,
          'success': {
            '_id': '67331132389213128264836251662',
            'date': '2019-09-13T14:02:54.328+0200',
            'userInfo': {'jose#sanchez@tiekinetix#com': {'company': '', 'role': []}},
            'id': '67331132389213128264836251662',
            'lastMessageId': '',
            'users': {'jose#sanchez@tiekinetix#com': ''}
          }
        }
     ]));

    return this.CmsService.get<any[]>('chat/conversations').pipe(
      map(results => this.parseConversations(results)),
      catchError(() => of([]))
    );
  }

  parseConversations(conversations: any[]): any[] {
    return conversations.reduce((acc, current) => {
      const parsed = {
        id: current.id,
        date: current.success.date,
        hash: current.success.id,
        isOpen: false,
        lastMessageId: current.success.lastMessageId,
        messages: [],
        from: '',
        isBlocked: current.success.blocked ? current.success.blocked === this.UserService.username : false,
        shouldLoadHistory: !!current.success.lastMessageId,
        to: '',
        _raw: current
      };

      for (const currentObjectIndex in current.success.userInfo) {
        if (current.success.userInfo.hasOwnProperty(currentObjectIndex)) {
          const normalizedEmail = this.normalizeEmail(currentObjectIndex);
          if (normalizedEmail !== this.UserService.username) {
            parsed.to = normalizedEmail;
          }
          else {
            parsed.from = normalizedEmail;
          }
        }
      }

      if (!parsed.to) {
        parsed.to = this.UserService.username;
      }

      acc.push(parsed);
      return acc;
    }, []);
  }


  parseCompanies(companies: { [key: string]: FlowChatSearchResultsInterface[] }): FlowChatSearchResultInterface[] {
    const data = [];

    for (const prop in companies) {
      if (companies.hasOwnProperty(prop)) {
        data.push({
          companyName: prop,
          usersCount: companies[prop].length || 0,
          users: companies[prop].map(user => ({
              email: user.Email,
              id: user.Id,
              roles: user.RolesText
                .filter(role => role.Name.indexOf('chat') > -1 && role.Name !== 'chat:writer')
                .map(role => {
                  const roleName = role.Name.split(':');
                  return roleName[2] ? roleName[2] : roleName[1] ? roleName[1] : 'general';
                })
            }))
        });
      }
    }
    return data;
  }

  normalizeEmail(candidate: string): string {
    return candidate.replace( /#/g , '.' );
  }

}
