import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { Observable } from 'rxjs';

import { FlowTranslateLabel, FlowTranslateService } from '@flow/translate';

@Component({
  selector: 'flow-missing-info',
  templateUrl: './missing-info.component.html',
  styleUrls: ['./missing-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlowMissingInfoComponent implements OnInit {

  isLoading$: Observable<boolean>;

  labels: FlowTranslateLabel;

  constructor(
    private TranslateService: FlowTranslateService
  ) { }

  ngOnInit() {
    this.labels = this._translateLabels();
  }

  private _translateLabels(): FlowTranslateLabel {
    return this.TranslateService.translateSync([
      'general.label.logout',
      'general.label.missing_info',
      'general.label.complete_missing_info',
      'error.general.error_loading'
    ]);
  }
}
