/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter, HostBinding } from '@angular/core';
import { FlowTranslateLabel, FlowTranslateService } from '@flow/translate';

@Component({
  selector: 'flow-chat-box',
  templateUrl: './chat-box.component.html',
  styleUrls: ['./chat-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlowChatBoxComponent implements OnInit {

  @Input() conversation: any;

  @Input() isChatOpen: boolean;

  @Input() isOnline: boolean;

  @Output() close: EventEmitter <void> = new EventEmitter();

  @HostBinding('class')

  labels: FlowTranslateLabel;

  get classes() {
    return this.isChatOpen ? 'chat-open' : '';
  }

  constructor(
    private TranslateService: FlowTranslateService
  ) { }

  ngOnInit() {
    this.labels = this.TranslateService.translateSync([
      'generic.btn.close'
    ]);
  }
}
