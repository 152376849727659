<ng-container *ngIf="title$$ | async as title">
  <h2 [innerHTML]="title"></h2>
</ng-container>
<ul *ngIf="!hidden && path.length > 1" class="breadcrumb">
  <li *ngFor="let part of path; let last = last">
    <a *ngIf="!last else isLast" [routerLink]="part.url" [innerHTML]="part.label"></a>
    <ng-template #isLast>
      <span *ngIf="last && !part.lastWithLink else isLastWithLink" [innerHTML]="part.label"></span>
      <ng-template #isLastWithLink>
        <a [routerLink]="part.url" [innerHTML]="part.label"></a>
      </ng-template>
    </ng-template>
  </li>
</ul>
