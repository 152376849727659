/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

/**
 * This guard does nothing else but loading Document Manager assets for testing DM components.
 */

import { Injectable } from '@angular/core';

import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { FlowEnvService, FlowRouterService } from '@flow/core';

const DM_API_URLS = {
  test:       'https://docman-eu-api-test.tiekinetix.net/',
  preAccept:  'https://docman-eu-api-test.tiekinetix.net/',
  accept:     'https://docman-eu-api-test.tiekinetix.net/',
  prod:       'https://docman-eu-api.tiekinetix.net/'
};

const DM_CSS_FILE = 'Tie.Kinetix.DocumentManager.UI.styles.css';

const DM_JS_FILE = '_framework/blazor.webassembly.js';

@Injectable({
  providedIn: 'root'
})
export class FlowDmComponentsTestGuard implements CanActivate {

  private _apiUrl: string;

  constructor(
    private router: FlowRouterService,
    private EnvService: FlowEnvService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    this._apiUrl = this._getApiUrl();

    this._loadScript();
    this._loadStyles();
    this.setApiUrl();

    return true;
  }

  private _getApiUrl(): string {
    let dmApiUrl = DM_API_URLS.test;

    if (this.EnvService.isProduction) {
      dmApiUrl = DM_API_URLS.prod;
    }
    else if (this.EnvService.isAcceptance) {
      dmApiUrl = DM_API_URLS.accept;
    }
    else if (this.EnvService.isPreAccept) {
      dmApiUrl = DM_API_URLS.preAccept;
    }

    return dmApiUrl;
  }

  private _loadScript() {
    const element = document.createElement('script');

    element.src = `${ this._apiUrl }${ DM_JS_FILE }`;
    element.type = 'text/javascript';
    element.nonce = '**CSP_NONCE**';

    document.getElementsByTagName('body')[0].appendChild(element);
  }

  private _loadStyles() {
    const element = document.createElement('link');

    element.href = `${ this._apiUrl }${ DM_CSS_FILE }`;
    element.rel = 'stylesheet';
    element.nonce = '**CSP_NONCE**';

    document.getElementsByTagName('head')[0].appendChild(element);
  }

  private setApiUrl() {
    document['dmApiUrl'] = this._apiUrl;
  }
}
