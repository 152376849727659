/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

/**
    This guard does nothing else but check if there's a query param "server-error" in the url.
    If so, it will store it's value in local storage and remove the query param from the url.
 */

import { Injectable, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { FlowRouterService, FlowStorageService } from '@flow/core';

const SERVER_ERROR_STORAGE_KEY = 'flow.server-error';

@Injectable({
  providedIn: 'root'
})
export class FlowServerErrorMessageGuard  {

  constructor(
    private _sanitizer: DomSanitizer,
    private router: FlowRouterService,
    private StorageService: FlowStorageService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const serverError = route.queryParamMap.get('server-error');

    if (serverError) {
      const sanitized = this._sanitizer.sanitize(SecurityContext.HTML, decodeURIComponent(serverError));

      this.StorageService.set( SERVER_ERROR_STORAGE_KEY, sanitized );

      // Remove query param from url
      this.router.navigate([], {
        queryParams: {
          'server-error': null,
        },
        queryParamsHandling: 'merge'
      });

      /*
          Wait 10sec then remove the entry from local storage.
          This is to prevent error msg from staying and being show in case where the login path is not hit on init,
          e.g. when user already logged in.
      */
      setTimeout(
        () => this.StorageService.remove(SERVER_ERROR_STORAGE_KEY),
        10000  /* 10sec */
      );
    }

    return true;
  }
}
