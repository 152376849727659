import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';

import { finalize } from 'rxjs/operators';

import { FlowTranslateLabel, FlowTranslateService } from '@flow/translate';
import { FlowTokensService, FlowUserService } from '@flow/auth';
import { FlowEnvService } from '@flow/core';

import { FlowGatewayService } from '../../services/gateway/gateway.service';
import { FlowProjectDomainService } from '../../../../services/project-domain.service';

const FLOW_ADMIN_URLS = {
  test:       'https://flow-admin-test.tiekinetix.net/',
  preAccept:  'https://flow-admin-preaccept.tiekinetix.net/',
  accept:     'https://flow-admin-accept.tiekinetix.net/',
  prod:       'https://flow-admin-live.tiekinetix.net/',
};

const WEBSHOP_URLS = {
  test:       'https://tiekinetix-site.flow-test.tiekinetix.net',
  preAccept:  'https://tiekinetix-site.flow-preAccept.tiekinetix.net',
  accept:     'https://tiekinetix-site.flow-accept.tiekinetix.net',
  prod:       'https://shop.tiekinetix.net',
};

@Component({
  selector: 'flow-gateway',
  templateUrl: './gateway.component.html',
  styleUrls: ['./gateway.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlowGatewayComponent implements OnInit {

  labels: FlowTranslateLabel;

  isLoading = false;

  isFlowUser = false;
  impersonationError = false;

  projects: {
    id: string;
    url: string;
  }[];

  ownProject;
  becomeOurCustomer;

  showOnboarding = false;
  showOnboardingSuccessMsg = false;
  showOnboardingErrorMsg = false;
  onboarding: {   /* current (vendor) domain onboarding (if any) */
    vendor: string;
    heading: string;
    label: string;
    url: string;
  };

  showRedirectErrorMsg = false;

  private _onboardingformUrl;
  private _isOnboardingExternal;

  constructor(
    private EnvService: FlowEnvService,
    private TranslateService: FlowTranslateService,
    private changeDetectorRef: ChangeDetectorRef,
    private TokensService: FlowTokensService,
    private UserService: FlowUserService,
    private GatewayService: FlowGatewayService,
    private ProjectDomainService: FlowProjectDomainService,
  ) {
    this.GatewayService.getOnboardingformUrl().subscribe( url => this._onboardingformUrl = url );
    this.GatewayService.isOnboardingInternal().subscribe( value => this._isOnboardingExternal = !value );
  }

  ngOnInit() {
    this.labels = this._translateLabels();

    this.isFlowUser = this.GatewayService.isFlowUser;

    this.GatewayService.getUserProjects().subscribe( this._generateTargets.bind(this) );

    this._generateOnboardingTarget();

    this._generateOwnTarget();

    this._generateBecomeOurCustomerTarget();
  }

  goto(vendorUrl: string) {
    // lock screen & toggle loading state
    this.isLoading = true;

    // hide any previous error message
    this.showRedirectErrorMsg = false;

    this.ProjectDomainService.initVendorDomainAuthorization()
    .pipe(
      finalize(() => {
        this.isLoading = false;
        setTimeout(() => this.changeDetectorRef.detectChanges());
      })
    )
    .subscribe(
      result => {
        if (result) {
          this.ProjectDomainService.redirectToVendorUrl(`${vendorUrl}/sso?productId=project`, false);
        }
        else {
          this.showRedirectErrorMsg = true;
        }
      }
    );
  }

  onboard() {
    if ( this.UserService.isAdmin() ) { // ADMIN user
      if ( this._isOnboardingExternal ) {
        this.TokensService.deleteTokens();  /* for the V1 onboarding user does not need to be auto logged in */
      }

      setTimeout( () => document.location.href = this.onboarding.url ); // timeout to allow tokens removal if needed
    }
    else {  // NORMAL user
      // lock screen & toggle loading state
      this.isLoading = true;

      // clear previous onboarding error msg if any
      this.showOnboardingErrorMsg = false;

      // do API request for BE to send email to ADMIN
      this.GatewayService.userWantsToOnboardOnProject( this.onboarding.vendor )
        .pipe(
          // unlock screen & toggle loading state
          finalize( () => {
            this.isLoading = false;

            setTimeout( () => this.changeDetectorRef.detectChanges() );
          } ),
        )
        .subscribe(
          result => {
            // onSuccess replace onboarding btn with success msg "Admin has been notified"
            this.showOnboarding = false;
            this.showOnboardingSuccessMsg = true;
          },
          error => {
            // serverError
            this.showOnboardingErrorMsg = true;
          });
    }
  }

  gotoFlowAdmin() {
    let url = FLOW_ADMIN_URLS.test;

    if ( this.EnvService.isProduction ) {
      url = FLOW_ADMIN_URLS.prod;
    }
    else if ( this.EnvService.isAcceptance ) {
      url = FLOW_ADMIN_URLS.accept;
    }
    else if ( this.EnvService.isPreAccept ) {
      url = FLOW_ADMIN_URLS.preAccept;
    }

    document.location.href = url;
  }

  gotoWebshop() {
    let url = WEBSHOP_URLS.test;

    if ( this.EnvService.isProduction ) {
      url = WEBSHOP_URLS.prod;
    }
    else if ( this.EnvService.isAcceptance ) {
      url = WEBSHOP_URLS.accept;
    }
    else if ( this.EnvService.isPreAccept ) {
      url = WEBSHOP_URLS.preAccept;
    }

    document.location.href = url;
  }

  impersonate(user: 'vendor' | 'partner') {
    this.impersonationError = false;  // reset

    const { vendor } = this.ProjectDomainService.urlHostToParts().subdomain;

    this.GatewayService.impersonateTestUser(vendor, user)
      .subscribe( result => {
        if ( !result ) {
          this.impersonationError = true;

          setTimeout( () => this.changeDetectorRef.detectChanges() );
        }
      });
  }

  private _generateTargets(companies) {
    const projects = this.GatewayService.allowedVendors;

    const targets = projects.map( vendor => {
      const companyName = companies[vendor];

      return {
        id: companyName === 'n/a' ? vendor : companyName, // fallback to companyId if companyName is "n/a"
        projectId: vendor.toLowerCase(),
        url: this.ProjectDomainService.getVendorBasedOrigin(vendor)
      };
    });

    this.projects = targets;

    setTimeout( () => this.changeDetectorRef.detectChanges() );
  }

  private _generateOnboardingTarget() {
    // if current location url has an "onboardingform", then also include the onboarding url as a target
    if ( this._onboardingformUrl ) {
      const { vendor } = this.ProjectDomainService.urlHostToParts().subdomain;
      const companyName = this.UserService.vendorDescription;

      this.onboarding = {
        heading: this.TranslateService.instant({
          key: 'login.label.gateway_goto_onboard',
          params: { company: companyName }
        }),
        vendor,
        label: this.TranslateService.instant({
          key: 'login.label.gateway_start_onboard',
          params: { company: companyName }
        }),
        url: `${ location.origin }/onboarding/${ vendor }`
      };

      this.showOnboarding = true;

      setTimeout( () => this.changeDetectorRef.detectChanges() );
    }
  }

  private _generateOwnTarget() {
    const projects = this.GatewayService.allowedVendors;

    // if user is a vendor, then also include its own subdomain as a project
    if ( this.UserService.isActingAsVendor() ) {
      const { platform } = this.UserService.user;
      const companyName = this.UserService.companyInformation.Description;

      if ( !projects.includes(platform) ) {
        if ( this.GatewayService.userHasBoughtProducts ) {  // only show the "go to own prj" IF user's company has acquired products
          this.ownProject = {
            heading: this.TranslateService.instant({
              key: 'login.label.gateway_goto_own',
              params: { company: companyName }
            }),
            id: companyName,
            url: this.ProjectDomainService.getVendorBasedOrigin(platform)
          };

          setTimeout( () => this.changeDetectorRef.detectChanges() );
        }
      }
    }
  }

  private _generateBecomeOurCustomerTarget() {
    // if user has NOT bought any product (is not a TIE customer), then also include the webshop so user can become our customer
    if ( !this.GatewayService.userHasBoughtProducts ) {
      this.becomeOurCustomer = true;

      setTimeout( () => this.changeDetectorRef.detectChanges() );
    }
  }

  private _translateLabels(): FlowTranslateLabel {
    return this.TranslateService.translateSync([
      'general.label.logout',

      'error.general.error_loading',

      'login.label.gateway_destination',
      'login.label.gateway_select_destination',

      'login.label.gateway_goto_onboard',
      'login.label.gateway_start_onboard',
      'login.label.gateway_goto_vendor',
      'login.label.gateway_goto_own',
      'login.label.gateway_goto_webshop',
      'login.label.gateway_visit_webshop',

      'login.label.gateway_onboard_admin_notified',

      'login.label.gateway_impersonation',
      'login.label.gateway_select_impersonation',
      'login.label.gateway_impersonation_error',
      'login.label.gateway_impersonate_vendor',
      'login.label.gateway_impersonate_partner',
    ]);
  }
}
