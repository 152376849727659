/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { FlowProjectAccessComponent } from './containers/project-access/project-access.component';
import { FlowLabelsLoaderGuard } from '@flow/translate';

const routes: Routes = [
  {
    path: '',
    component: FlowProjectAccessComponent,
    data: {
      guards: [
        FlowLabelsLoaderGuard
      ],
      labelsPaths: [
        'login'
      ]
    },
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class FlowProjectAccessRoutingModule { }
