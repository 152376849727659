import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { FlowRouterService } from '@flow/core';
import { FlowUserService } from '@flow/auth';
import { FlowPasswordExpiredService } from '../modules/password-expired/services/password-expired/password-expired.service';

@Injectable({
  providedIn: 'root'
})
export class FlowPasswordExpiredGuard  {
  constructor(
    private router: FlowRouterService,
    private UserService: FlowUserService,
    private PasswordExpiredService: FlowPasswordExpiredService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.UserService.user) {  // needed otherwise if the user is not available yet, it will fail in the service
      if (!this.PasswordExpiredService.hasExpiredPassword) {
        this.router.navigate(['/']);

        return false;
      }
    }
    else {
      this.router.navigate(['/']);
    }

    return true;
  }
}
