<mat-form-field [formGroup]="form">
  <mat-label *ngIf="fieldLabel">{{ fieldLabel }}</mat-label>
  <mat-select
    [formControlName]="controlName"
    (selectionChange)="selectionChanged($event)"
    [required]="required"
    panelClass="flow-products-dropdown">
    <mat-option
      *ngIf="showDashboard"
      [value]="false">
      <span [innerHTML]="label"></span>
    </mat-option>
    <mat-option
      *ngFor="let product of products$ | async"
      [value]="product.id">
      <i [class]="'flow-icon icon-' + product.id"></i>
      <span [innerHTML]="product.name"></span>
    </mat-option>
  </mat-select>
</mat-form-field>
