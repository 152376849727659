/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import {
  FlowCustomerService,
  FlowSsoService,
  FlowSsoUserInterface,
  FlowUserService
} from '@flow/auth';

import { shareReplay} from 'rxjs/operators';
import { Observable } from 'rxjs';

import { nameNotAllowedCharsValidator, nameNotNumberValidator } from '@flow/shared';
import { FlowTranslateLabel, FlowTranslateService } from '@flow/translate';
import { FlowRouterService} from '@flow/core';
import { FlowMissingInfoService } from '../../services/missing-info/missing-info.service';

@Component({
  selector: 'flow-missing-info-form',
  templateUrl: './missing-info-form.component.html',
  styleUrls: ['./missing-info-form.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FlowMissingInfoFormComponent implements OnInit {

  form: UntypedFormGroup;

  labels: FlowTranslateLabel;
  labelNameMinLengthError: string;

  nameMinLength = 2;

  saving: boolean;

  saved: boolean;

  saveError: boolean;

  missing = {
    GivenName: false,
    FamilyName:  false,
  };

  private _ctrls;

  private _user$: Observable<FlowSsoUserInterface>;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private changeDetectorRef: ChangeDetectorRef,
    private router: FlowRouterService,
    private CustomerService: FlowCustomerService,
    private SsoService: FlowSsoService,
    private UserService: FlowUserService,
    private TranslateService: FlowTranslateService,
    private MissingInfoService: FlowMissingInfoService,
  ) {
    this.form = this.formBuilder.group({} );

    this._ctrls = {
      GivenName: () => new UntypedFormControl('', [
        nameNotAllowedCharsValidator(),
        nameNotNumberValidator(),
      ]),
      FamilyName: () => new UntypedFormControl('', [
        nameNotAllowedCharsValidator(),
        nameNotNumberValidator(),
      ]),
    };

    this._user$ = this.CustomerService.getUserFromCustomer(this.UserService.username, this.UserService.company)
      .pipe(
        shareReplay(1),
      );

    // subscribe to pre-fetch and populate the buffer
    this._user$.subscribe();
  }

  ngOnInit(): void {
    this.labels = this._translateLabels();
    this.labelNameMinLengthError = this.TranslateService.instant({
      key: 'generic.error.minlength',
      params: { number: this.nameMinLength }
    });

    this.MissingInfoService.missingInfo.forEach( prop => {
      this.missing[prop] = true;

      this.form.addControl( prop, this._ctrls[prop]() );
    });

    if ( this.missing.GivenName && this.missing.FamilyName ) {
      this.form.setValidators([ this._validateNotAllowedFullName.bind(this) ]);
    }
  }

  labelNotAllowedFullNameError(fullName) {
    const label = this.TranslateService.instant({
      key: 'general.error.not_allowed_full_name',
      params: {
        firstname_field: this.labels['account_settings.label.given_name'],
        lastname_field: this.labels['account_settings.label.family_name'],
      }
    });

    return `${ label } <i>${ fullName }</i>`;
  }

  labelNameInvalidCharsError({chars}) {
    return `${ this.labels['generic.error.invalid_characters'] } <b>${ chars.join('') }</b>`;
  }

  labelFieldCannotBeNumberError(field) {
    return this.TranslateService.instant({
      key: 'generic.error.field_cannot_be_number',
      params: { field }
    });
  }

  save(): void {
    this.saving = true;

    this._user$.subscribe( user => {
      // update related user props with new values.
      // NOTE: works only for root level props; Deep level might require additional logic
      Object.assign( user, this.form.value );

      this.CustomerService.updateSsoUser(user, this.UserService.company)
        .subscribe( result => {
          if (result === true) {
            this.saveError = false;
            this.saving = false;
            this.saved = true;

            setTimeout( this._gotoHome.bind(this), 2000); // 2sec to allow loading completed checkmark icon to show
          }
          else {
            this.saveError = true;
            this.saving = false;
            this.saved = true;
          }

          setTimeout( () => this.changeDetectorRef.detectChanges() );
        });
    });
  }

  private _gotoHome() {
    /* NOTE:
          Using this.router.navigate(['/']) will not work as this will get block by the guard due to old data. Setting
          document.location.href will act similar to a reload and therefor not get block by the guard.
    */
    document.location.href = location.origin;
  }

  private _validateNotAllowedFullName(group: UntypedFormGroup): {[key: string]: any} | null {
    const givenName = group.get('GivenName').value;
    const familyName = group.get('FamilyName').value;

    if ( givenName && familyName ) {
      const fullName = `${ givenName } ${ familyName }`;

      if ( fullName.match( this.MissingInfoService.REGEX.notAllowedFullNames ) ) {
        return { notAllowedFullName: fullName };
      }
    }

    return null;
  }

  /**
   * Translate the labels
   */
  private _translateLabels(): FlowTranslateLabel {
    return this.TranslateService.translateSync([
      'general.password_requirements',
      'account_settings.label.given_name',
      'account_settings.label.family_name',
      'msg.updated.information',
      'general.btn.close',
      'generic.error.required',
      'generic.error.minlength',
      'generic.error.invalid_characters',
      'generic.error.field_cannot_be_number',
      'general.error.not_allowed_full_name',
      'general.error.request_error',
      'generic.btn.save',
      'general.btn.saving',
    ]);
  }
}


