/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { FlowPortalThemeInterface } from '@flow/core';
import { FlowTranslateLabel, FlowTranslateService } from '@flow/translate';

@Component({
  selector: 'flow-themes-dropdown',
  templateUrl: './themes-dropdown.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlowThemesDropdownComponent implements OnInit {

  @Input() form: UntypedFormGroup;

  @Input() controlName: string;

  @Input() themes: FlowPortalThemeInterface[];

  themeGroups: any[];

  labels: FlowTranslateLabel;

  required: boolean;

  constructor(
    private TranslateService: FlowTranslateService
  ) { }

  ngOnInit() {
    this.labels = this._translateLabels();
    this.themeGroups = this._getThemeGroups();
    this.required = true;
  }

  /**
   * Get theme group tree
   */
  private _getThemeGroups(): any[] {
    // TODO: Show only custom themes that have colors defined
    return [{
      title: this.labels['account_settings.label.my_themes'],
      themes: this.themes.map(theme => ({
          slug: theme.slug,
          name: theme.name
        }))
    }, {
      title: this.labels['account_settings.label.flow_themes'],
      themes: [{
        slug: 'default',
        name: this.labels['account_settings.label.default']
      }]
    }];
  }

  /**
   * Translate necessary labels
   */
  private _translateLabels(): FlowTranslateLabel {
    return this.TranslateService.translateSync([
      'account_settings.label.my_themes',
      'account_settings.label.flow_themes',
      'account_settings.label.default'
    ]);
  }
}
