/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { catchError, map, pluck, shareReplay, tap } from 'rxjs/operators';

import { FlowAuthService, FlowSsoService, FlowTokensService, FlowUserService } from '@flow/auth';
import { FlowCmsService, FlowEnvService, FlowModelsService } from '@flow/core';

import { FlowProjectDomainService } from '../../../../services/project-domain.service';

const IGNORE = [
  'TIEKinetix'
];
const INTERNAL_ONBOARDING_REGEX = /\/\/onboarding(-test|-preaccept|-accept)?\.tiekinetix\.net/;

@Injectable({
  providedIn: 'root'
})
export class FlowGatewayService {

  private readonly _onboardingformUrl$: Observable <any|boolean>;
  private readonly _onboardingIsInternal$: Observable <any|boolean>;

  constructor(
    private EnvService: FlowEnvService,
    private CmsService: FlowCmsService,
    private ModelsService: FlowModelsService,
    private SsoService: FlowSsoService,
    private AuthService: FlowAuthService,
    private TokensService: FlowTokensService,
    private UserService: FlowUserService,
    private ProjectDomainService: FlowProjectDomainService,
  ) {
    this._onboardingformUrl$ = this.SsoService.getOnBoardingFormForPartners()
      .pipe(
        pluck('url'),
        shareReplay(1),
      );

    this._onboardingIsInternal$ = this._onboardingformUrl$
      .pipe(
        map( url => !!INTERNAL_ONBOARDING_REGEX.test(url) ),
        shareReplay(1),
      );

    // subscribe to pre-fetch and populate the buffer
    this._onboardingformUrl$.subscribe();
  }

  get allowedVendors() {
    return this.UserService.user.vendors.filter( vendor => !IGNORE.includes(vendor) );
  }

  get isUserOnAllowedDomain() {
    return this.isVendorOnAllowedDomain || this.allowedVendors.includes( this.EnvService.portalDomain );
  }

  get isVendorOnAllowedDomain() {
    return this.EnvService.portalDomain.toLowerCase() === this.UserService.user.platform.toLowerCase();
  }

  get userHasAllowedVendor() {
    return !!this.allowedVendors.length;
  }

  get userHasOnlyOneAllowedVendor() {
    return this.allowedVendors.length === 1;
  }

  get userHasBoughtProducts() {
    const { boughtProducts } = this.UserService;

    return !!( boughtProducts && boughtProducts.length );
  }

  get isFlowUser() {
    return this.UserService.user.platform === 'Flow'; /* all flow employees (.platform==flow) are allow to impersonate  */
  }

  get isOnFlowVendorSubdomain() {
    return this.ProjectDomainService.urlHostToParts().subdomain.vendor === 'flow'
      || this.EnvService.portalDomain.toLowerCase() === 'flow'; // localhost fallback?
  }

  getOnboardingformUrl() {
    return this._onboardingformUrl$;
  }

  isOnboardingInternal() {
    return this._onboardingIsInternal$;
  }

  getUserProjects() {
    return this.CmsService.get<any>('projects');
  }

  userWantsToOnboardOnProject( project, user = this.UserService.user.email ) {
    return this.CmsService
      .get<any>(
        `${ this.ModelsService.getModel('SsoCustomers') }/${ project }/requestOnboard/${ user }`
      );
  }

  impersonateTestUser(project: string, user: 'vendor' | 'partner') {
    return this.CmsService.post(
      `authorize/${ project }/${ user }`,
      { token: this.TokensService.getToken() }
      )
      .pipe(
        tap( this._loginAsTestUser.bind(this) ),
        map(() => true),
        catchError(err =>
          /*if (err instanceof HttpErrorResponse && err.status === 406) {
            // 406 == test user not configured
            return of(false);
          }*/

           of(false)
        )
      );
  }

  private _loginAsTestUser( { access_token, refresh_token } ) {
    console.warn('_loginAsTestUser', );

    // remove old tokens
    this.TokensService.deleteTokens();

    // set tokens to those of the test user
    this.TokensService.setTokens( access_token, refresh_token );

    // refresh/redirect so tokens get picked up and user logged in
    setTimeout( () => document.location.href = location.origin ); // timeout to allow tokens update
  }
}
