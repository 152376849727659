import { Component, ElementRef, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { select, Store } from '@ngrx/store';

import { Observable } from 'rxjs';

import { FlowAuthService, FlowUserService } from '@flow/auth';
import { FlowRouterSlideTransition, FlowIframeMessageService, FlowEventService, FlowRouterService } from '@flow/core';
import { FlowDialogsService, FlowPortalService } from '@flow/shared';
import {
  FlowMarketplaceStateInterface,
  getIfApplicationIsLoading,
  IsApplicationLoading,
  LayoutLogoutEvent,
  UnloadCompany,
  UnloadCustomer,
  UnloadProducts,
  UnloadWidgets
} from '@flow/marketplaceStore';

import { FlowMarketplaceDashboardService } from './modules/marketplace-shared/services/marketplace-dashboard/marketplace-dashboard.service';
import { FlowIframeActionsService } from './services/iframe-actions.service';
import { FlowPasswordExpiredService } from './modules/password-expired/services/password-expired/password-expired.service';
import { FlowSubAppsLogoutService } from './services/sub-apps-logout.service';

declare const DotNet: any;

@Component({
  selector: 'flow-marketplace',
  templateUrl: './app.component.html',
  animations: [FlowRouterSlideTransition]
})
export class AppComponent implements OnInit {

  isApplicationLoading$: Observable <boolean>;

  private _responseError500Dialog;

  constructor(
    private elementRef: ElementRef,
    private store: Store <FlowMarketplaceStateInterface>,
    private AuthService: FlowAuthService,
    private UserService: FlowUserService,
    private RouterService: FlowRouterService,
    private IframeMessageService: FlowIframeMessageService,
    private EventService: FlowEventService,
    private IframeActionsService: FlowIframeActionsService,
    private MarketplaceDashboardService: FlowMarketplaceDashboardService,
    private PasswordExpiredService: FlowPasswordExpiredService,
    private DialogsService: FlowDialogsService,
    private PortalService: FlowPortalService,
    private SubAppsLogoutService: FlowSubAppsLogoutService,
  ) {
    IframeMessageService.on('openNewWindow',      this.IframeActionsService.openNewWindow.bind(this.IframeActionsService) );
    IframeMessageService.on('openIframeModal',    this.IframeActionsService.openIframeModal.bind(this.IframeActionsService) );
    IframeMessageService.on('logout',             this.IframeActionsService.logout.bind(this.IframeActionsService) );
    IframeMessageService.on('impersonate',        this.IframeActionsService.impersonate.bind(this.IframeActionsService) );

    // TODO: DEPRECATED on request from David, as it's affecting existing error catchers.
    //        A different solution needs to be implemented.
    // IframeMessageService.on('response.error.500', this._onResponseError500.bind(this) );
    // EventService.on('response.error.500',         this._onResponseError500.bind(this) );
  }

  ngOnInit(): void {
    this.AuthService.logoutEvent$.subscribe( this._onLogout.bind(this) );

    // ensure non-flow-auth-based sub-apps gets logout.
    this.SubAppsLogoutService.logoutSubApps();

    this.isApplicationLoading$ = this.store.pipe(select(getIfApplicationIsLoading));

    this.isApplicationLoading$.subscribe(loading => {
      if (!loading) {
        const spinner = this.elementRef.nativeElement.parentNode.querySelector('.app-loading');

        if (spinner) {
          spinner.remove();
        }

        // Apply partner theme if user is a partner
        if (this.UserService.isActingAsPartner()) {
          this.PortalService.setPortalThemeForPartners();
        }
      }
    });

    /* NOTE:
            - ONLY for local DEV.
            - Use this to get & test 500 error response (from 3rd-party service).
            - This (Test-A) will trigger both in logout and login mode.

            - Requires:
              - import:
                  import {HttpClient} from "@angular/common/http";
              - constructor:
                  private httpClient: HttpClient,
    */
    /*this.httpClient.get('https://tfmp500.free.beeceptor.com').subscribe(
      r => {
        console.warn('%c 500 ERROR test-A. The 3rd-party needs to be configured ', 'background:green', r);
      },
      err => { console.warn('%c 500 ERROR test-A err', 'background:magenta', err); }
    );*/
  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
  }

  /**
   * Logout actions
   */
  private _onLogout() {
    console.log('logout happened');

    // Unload the customer
    this.store.dispatch(new UnloadCustomer());

    // Unload the company
    this.store.dispatch(new UnloadCompany());

    // Reset the layout state.
    this.store.dispatch(new LayoutLogoutEvent());

    // Reset the app loading state.
    this.store.dispatch(new IsApplicationLoading(true));

    // Unload the products (they are translated on the effect)
    this.store.dispatch(new UnloadProducts());

    // Unload Widgets
    this.store.dispatch(new UnloadWidgets());

    // Clear the stored vendor and user dashboards.
    this.MarketplaceDashboardService.clear();

    // remove localStorage PasswordExpiredDate, because on next login we'll get it from the updated non-cached extras
    this.PasswordExpiredService.removeStoragePasswordExpiredDate();

    // indicate to the SubAppsLogoutService that a logout has happened
    this.SubAppsLogoutService.onParentLogout();
  }

  private _onResponseError500(data) {
    // close already exiting one, IF any.
    if ( this._responseError500Dialog ) {
      this._responseError500Dialog.close();

      this._responseError500Dialog = null;
    }

    const { isLoggedIn } = this.AuthService;

    this._responseError500Dialog = this.DialogsService.textModal({
      title: 'general.modal.internal_server_error.title',
      content: isLoggedIn
        ? 'general.modal.internal_server_error.content'
        : 'general.modal.internal_server_error.content_logged_out',
      showOkButton: true,
      showCancelButton: true,
      okButtonLabel: 'general.label.reload',
      cancelButtonLabel: isLoggedIn ? 'general.label.logout' : 'general.btn.cancel',
    });

    this._responseError500Dialog.afterClosed().subscribe(result => {
      if (result) {
        window.location.reload();
      }
      else if (result === false) {  // new dialog will cause old one to close, which will return result:undefined, which
                                    // will be seen as falsy and trigger an unwanted logout, therefore we must strictly
                                    // check false.
        if (isLoggedIn) {
          this.RouterService.navigate(['/auth', 'logout']);
        }
      }
    });
  }
}
