<nav class="tabs tabs-nav-overflow" *ngIf="!hideDashboard">
  <flow-menu-main-bookmark
    [extraClass]="'bookmark-visible'"
    [routerLink]="['/dashboard']"
    [routerLinkActive]="'current-menu-item'"
    [icon]="'dashboard'"
    [label]="labels['account_settings.label.dashboard']">
  </flow-menu-main-bookmark>
</nav>

<flow-menu-main-bookmarks
  [bookmarks]="bookmarks$ | async"
  [boughtProducts]="boughtProducts$ | async"
  (bookmarksChanged)="bookmarksChanged($event)">
</flow-menu-main-bookmarks>

<nav class="tabs tabs-nav-overflow">
  <ng-container *ngIf="hiddenBookmarks.length">
    <flow-menu-main-bookmark
      [extraClass]="'bookmark-visible'"
      [label]="labels['general.label.more']"
      [matMenuTriggerFor]="appMenu"
      [icon]="'more_vert'">
    </flow-menu-main-bookmark>

    <mat-menu #appMenu="matMenu" [class]="'bookmarks-menu'">
      <button mat-menu-item *ngFor="let bookmark of hiddenBookmarks; let i = index" (click)="openSolution(bookmark, i)">
        <i *ngIf="!bookmark.hideIcon" class="flow-icon mr-1" [ngClass]="'icon-' + bookmark.id "></i>
        <span>{{ bookmark.name }}</span>
      </button>
    </mat-menu>
  </ng-container>

  <ng-container *showOnDesktop>
    <flow-menu-main-bookmark
      *ngIf="!hideSolutionsTab"
      [extraClass]="'bookmark-visible'"
      [routerLink]="['/solutions']"
      [routerLinkActiveOptions]="{exact: true}"
      [label]="labels['account_settings.label.solutions']"
      [routerLinkActive]="'current-menu-item'"
      [icon]="'apps'">
    </flow-menu-main-bookmark>
  </ng-container>

  <flow-menu-main-bookmark
    *ngIf="showShopTab"
    [extraClass]="'bookmark-visible'"
    [routerLink]="['/solutions', 'internal-shop']"
    [routerLinkActiveOptions]="{exact: true}"
    [label]="labels['general.label.internal_shop']"
    [routerLinkActive]="'current-menu-item'"
    [icon]="'add_shopping_cart'">
  </flow-menu-main-bookmark>
</nav>
