/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { Component, OnInit, ChangeDetectionStrategy, Input, ChangeDetectorRef } from '@angular/core';
import { Store } from '@ngrx/store';

import { BehaviorSubject } from 'rxjs';

import { FlowHelpers } from '@flow/core';
import { ToggleHelpPanel, FlowMarketplaceStateInterface } from '@flow/marketplaceStore';

import { FlowUserlaneService } from '../../../marketplace-shared/services/userlane/userlane.service';

@Component({
  selector: 'flow-help-virtual-tours',
  templateUrl: './help-virtual-tours.component.html',
  styleUrls: ['./help-virtual-tours.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlowHelpVirtualToursComponent implements OnInit {

  @Input() label: string;

  firstLoad = true;

  loadingUserlane$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    private store: Store <FlowMarketplaceStateInterface>,
    private UserlaneService: FlowUserlaneService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  /**
   * Former implementation doesn't work anymore at all for unknown reasons
   * e.g. openAssistant() can not be triggered anymore
   * => "Userlane is currently not displaying the assistant, call to openAssistant discarded"
   *
   * Clarification needed also under UX aspects
   */

  ngOnInit(): void {
    // Prevents loaded userlane from popping up when help area is opened
    // console.log("ON INIT: this.UserlaneService.loaded", this.UserlaneService.loaded);

    /* FORMER IMPLEMENTATION
    if (!this.UserlaneService.loaded) {
      FlowHelpers.waitForNode({
        node: '.userlane-assistant-bubble',
        nodeBy: 'class',
        subtree: false,
        done: () => {
          this.UserlaneService.openAssistant();
          this.loading = false;
          this.UserlaneService.loaded = true;
          this.changeDetectorRef.markForCheck();
          setTimeout(() => this.store.dispatch(new ToggleHelpPanel()), 750);
        }
      });
    }
    */
  }

  open(): void {
    this.loadingUserlane$.next(true);

    this.initUserlane().then(() => {
      this.loadingUserlane$.next(false);

      if (!this.firstLoad) {
        //console.log("A: no first load");
        const userlaneBubble = document.getElementsByClassName('usln-p-assistant');

        // If a user logs out but has the app still open in the browser, the userlane script is still appended,
        // but the Userlane assistant got hidden (see modules/layout/containers/wrapper/wrapper.component.ts).
        // In this case the userlane needs to be initialized again.
        if (userlaneBubble.length) {
          //this.UserlaneService.openAssistant();
          // console.log("B: container available");
          setTimeout(() => this.store.dispatch(new ToggleHelpPanel()), 300);
        }
        else {
          // console.log("c: container not available");
          this.UserlaneService.init();
          setTimeout(() => this.store.dispatch(new ToggleHelpPanel()), 500);
        }

        this.UserlaneService.loaded = true;
      }
      else {
        // console.log("D: no first load");
        setTimeout(() => this.store.dispatch(new ToggleHelpPanel()), 500);
      }
    });

    /* FORMER IMPLEMENTATION
    this.loading = true;

    this.initUserlane().then(() => {
      if (!this.firstLoad) {
        const userlaneBubble = document.getElementsByClassName('userlane-assistant-bubble');

        // If a user logs out but has the app still open in the browser, the userlane script is still appended,
        // but the Userlane assistant got hidden (see modules/layout/containers/wrapper/wrapper.component.ts).
        // In this case the userlane needs to be initialized again.
        if (userlaneBubble.length) {
          this.UserlaneService.openAssistant();
          setTimeout(() => this.store.dispatch(new ToggleHelpPanel()), 500);
        } else {
          this.UserlaneService.init();
        }

        this.loading = false;
        this.UserlaneService.loaded = true;
      }
    });
    */
  }

  initUserlane(): Promise <void> {
    return new Promise(resolve => {
      if (!FlowHelpers.hasScriptById('flow-userlane')) {
        //console.log("initUserlane: flow-userlane not available");

        const tag = document.createElement('script');

        tag.src = 'https://cdn.userlane.com/userlane.js';
        tag.id = 'flow-userlane';
        tag.async = true;

        document.head.appendChild(tag);

        // Ensure that the script has loaded so the userlane command is available in the window object.
        document.getElementById('flow-userlane').addEventListener('load', () => {
          this.UserlaneService.init();
          // console.log("initUserlane: flow-userlane loaded");
          resolve();
        });
      }
      else {
        this.firstLoad = false;
        // console.log("initUserlane: flow-userlane available");
        resolve();
      }
    });
  }
}
