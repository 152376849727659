<div class="d-flex align-items-center justify-content-between p-3 modal-title bg-primary color-white">
  <h5
    class="truncate"
    [innerHTML]="multipleCompanies
      ? labels['general.modal.company_recommendation.title_multiple']
      : labels['general.modal.company_recommendation.title']">
  </h5>
</div>

<mat-dialog-content>
  <div *ngIf="multipleCompanies; else singleCompany">
    <div
      class="intro-multiple"
      [innerHTML]="labels['account_settings.label.accept_recommended_company_multiple']">
    </div>
    <div class="recommend-container">
      <mat-radio-group
        [(ngModel)]="selectedCompanyIndex"
        class="radio-group"
        [attr.aria-label]="labels['account_settings.label.accept_recommended_company_multiple']">
        <mat-radio-button
          *ngFor="let item of companyItems; let i = index; let even = even"
          [value]="i"
          [ngClass]="{'even': even, 'odd': !even, 'radio-button': true }"
          [disableRipple]="true">
          <div class="ml-3" [innerHTML]="item"></div>
        </mat-radio-button>
      </mat-radio-group>
    </div>
  </div>

  <ng-template #singleCompany>
    <div class="text-center">
      <div class="mb-4" [innerHTML]="labels['account_settings.label.accept_recommended_company']"></div>
      <div [outerHTML]="companyItems[0]"></div>
    </div>
  </ng-template>
</mat-dialog-content>

<mat-dialog-actions>
  <button
    class="button button-close-dialogs mr-2"
    type="button"
    (click)="onDecline()"
    [innerHTML]="multipleCompanies
                 ? labels['general.label.decline']
                 : labels['general.label.no']">
  </button>
  <button
    class="button button-primary accept"
    type="button"
    (click)="onAccept()"
    [disabled]="multipleCompanies && selectedCompanyIndex === null"
    [innerHTML]="multipleCompanies
                 ? labels['general.label.confirm']
                 : labels['general.label.yes']">
  </button>
</mat-dialog-actions>
