/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';

import { FlowTranslateLabel, FlowTranslateService } from '@flow/translate';
import { FlowGatewayService } from '../../../gateway/services/gateway/gateway.service';

@Component({
  selector: 'flow-onboarding-wrapper',
  templateUrl: './onboarding-wrapper.component.html',
  styleUrls: ['./onboarding-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlowOnboardingWrapperComponent implements OnInit {

  labels: FlowTranslateLabel;

  isOnboardingExternal = false;   // always start false so items don't get "temporarily" shown

  constructor(
    private TranslateService: FlowTranslateService,
    private GatewayService: FlowGatewayService,
  ) {
    this.GatewayService.isOnboardingInternal().subscribe( value => this.isOnboardingExternal = !value );
  }

  ngOnInit() {
    this.labels = this.TranslateService.translateSync([
      'general.btn.back'
    ]);
  }
}
