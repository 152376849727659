/**
 * Add CSP to ng inline css
 * Based on:
 *    - https://dev.to/ferdiesletering/how-to-implement-an-inline-styles-content-security-policy-with-angular-and-nginx-2ke2
 *    - https://github.com/ferdiesletering/angular-csp-nonce
 */

import { NgModule } from '@angular/core';
import { CustomDomSharedStylesHost } from '../inline-styles-csp/shared_styles_host';
import { ɵSharedStylesHost } from '@angular/platform-browser';

@NgModule({
  providers: [
    { provide: 'cspMetaSelector', useValue: 'meta[name="CSP-NONCE"]' },
    { provide: ɵSharedStylesHost, useClass: CustomDomSharedStylesHost },
  ],
})
export class InlineStylesCSPModule {}
