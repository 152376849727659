/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import {FlowComponentsModule, FlowMaterialModule, Flow2FaModule, FlowPipesModule} from '@flow/shared';

import { FlowLayoutModule } from '../layout/layout.module';

import { FlowPasswordExpiredRoutingModule } from './password-expired-routing.module';
import { FlowPasswordExpiredComponent } from './containers/password-expired/password-expired.component';
import { FlowPasswordExpiredFormComponent } from './components/password-expired-form/password-expired-form.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    FlowPipesModule,
    ReactiveFormsModule,
    FlowComponentsModule,
    FlowMaterialModule,
    FlowLayoutModule,
    FlowPasswordExpiredRoutingModule,
  ],
  declarations: [
    FlowPasswordExpiredComponent,
    FlowPasswordExpiredFormComponent
  ]
})

export class FlowPasswordExpiredModule {
}
