<form [formGroup]="form">

  <p class="bg-gray-20 fs-12 text-center py-1">
    {{ labels['account_settings.label.last_login'] }}: {{ lastLoginDate | dateLocale:'medium' }}
  </p>

  <mat-accordion
    [displayMode]="'flat'"
    [multi]="true">

    <!-- Personal panel start -->
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title [innerHTML]="labels['account_settings.label.personal_data']"></mat-panel-title>
      </mat-expansion-panel-header>

      <mat-form-field>
        <mat-label>{{ labels['account_settings.label.given_name'] }}</mat-label>
        <input
          matInput
          formControlName="GivenName"
          required
          minlength="{{ nameMinLength }}">
        <mat-error
          [hidden]="!form.get('GivenName').invalid"
          [innerHTML]="form.get('GivenName').hasError('required')
            ? labels['generic.error.required']
            : form.get('GivenName').hasError('minlength')
              ? labelNameMinLengthError
              : form.get('GivenName').hasError('nameNotAllowedCharacters')
                ? labelNameInvalidCharsError( form.get('GivenName').getError('nameNotAllowedCharacters') )
                : form.get('GivenName').hasError('nameNotNumber')
                  ? labelFieldCannotBeNumberError( labels['account_settings.label.given_name'] )
                  : null"
        ></mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{ labels['account_settings.label.family_name'] }}</mat-label>
        <input
          matInput
          formControlName="FamilyName"
          required
          minlength="{{ nameMinLength }}">
        <mat-error
          [hidden]="!form.get('FamilyName').invalid"
          [innerHTML]="form.get('FamilyName').hasError('required')
            ? labels['generic.error.required']
            : form.get('FamilyName').hasError('minlength')
              ? labelNameMinLengthError
              : form.get('FamilyName').hasError('nameNotAllowedCharacters')
                ? labelNameInvalidCharsError( form.get('FamilyName').getError('nameNotAllowedCharacters') )
                : form.get('FamilyName').hasError('nameNotNumber')
                  ? labelFieldCannotBeNumberError( labels['account_settings.label.family_name'] )
                  : null"
        ></mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{ labels['account_settings.label.phone'] }}</mat-label>
        <input matInput formControlName="PhoneNumber">
        <mat-error
          [hidden]="phoneNumber.valid"
          [innerHTML]="phoneNumber.hasError('pattern')
            ? labels['account_settings.label.error.invalid.phone']
            : null">
        </mat-error>
      </mat-form-field>
      <p>
        <button
          class="button button-primary-outline"
          [ngClass]="{ 'mt-4': phoneNumber.hasError('pattern') }"
          (click)="triggerPasswordChange()"
          [innerHTML]="labels['account_settings.btn.change_password']">
        </button>
      </p>
    </mat-expansion-panel>
    <!-- Personal panel end -->

    <!-- Extras panel start -->
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title [innerHTML]="labels['account_settings.label.my_portal_settings']"></mat-panel-title>
      </mat-expansion-panel-header>

      <ng-template matExpansionPanelContent>
        <div class="setting-rows-list">
          <div class="row setting">
            <div class="col-12">
              <flow-languages-dropdown
                [form]="extendedProperties.at(1)"
                [controlName]="'Value'"
                [inlineLabel]="labels['account_settings.label.language']">
            </flow-languages-dropdown>
            </div>
          </div>

          <div
            *ngIf="!isActingAsPartner"
            class="row no-gutters align-items-center setting"
          >
            <div class="col-1">
              <span class="form-group-label">
                <i
                  class="material-icons"
                  [matTooltip]="labels['account_settings.label.autoload_product.description']">help
                </i>
              </span>
            </div>
            <div class="col-11">
              <flow-bought-products-dropdown
                [form]="extendedProperties.at(14)"
                [controlName]="'Value'"
                [fieldLabel]="labels['account_settings.label.autoload_product']">
              </flow-bought-products-dropdown>
            </div>
          </div>

          <div
            *ngIf="!isActingAsPartner"
            class="row no-gutters align-items-center setting"
          >
            <div class="col-1">
              <span class="form-group-label">
                <i
                  class="material-icons"
                  [matTooltip]="labels['account_settings.label.bookmark_template.description']">help
                </i>
              </span>
            </div>
            <div class="col-11">
              <flow-bookmark-templates-dropdown
                [form]="extendedProperties.at(4)"
                [controlName]="'Value'"
                [disableDefault]="disableDefaultBookmarkTemplate"
                [fieldLabel]="labels['general.label.bookmark_template']">
              </flow-bookmark-templates-dropdown>
            </div>
          </div>
        </div>

        <div *ngIf="isChatBought" class="setting-rows-list" formGroupName="ChatEnabled">
          <div class="row py-3 align-items-center setting">
            <div class="col-9">
              <label class="form-group-label">
                <i class="material-icons pr-2"
                   [matTooltip]="labels['account_settings.label.enable_disable_chat.description']">help</i>
                {{ labels['account_settings.label.enable_disable_chat'] }}
              </label>
            </div>
            <div class="col-3 text-left text-sm-right">
              <mat-slide-toggle formControlName="enabled"></mat-slide-toggle>
            </div>
          </div>
        </div>
      </ng-template>
    </mat-expansion-panel>
    <!-- Extras panel end -->

    <!-- Geo panel start -->
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title [innerHTML]="labels['account_settings.label.geographic_information']"></mat-panel-title>
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
          <div class="setting-rows-list">
            <div *ngIf="geoProfilesText" class="setting pb-3">
              <label class="form-group-label mb-2">
                <i class="material-icons pr-2"
                   [matTooltip]="labels['account_settings.label.geo_profiles.description']">help</i>
                {{ labels['account_settings.label.geo_profiles'] }}
              </label>
              <p class="fs-12 color-infotext">{{ geoProfilesText }}</p>
            </div>
            <ng-container formArrayName="ExtendedProperties">
              <div
                *ngIf="!isActingAsPartner"
                class="row no-gutters align-items-center setting"
                [formGroupName]="'17'"
              >
                <div class="col-1">
                  <span class="form-group-label">
                    <i
                      class="material-icons"
                      [matTooltip]="labels['account_settings.label.timezone.description']">help
                    </i>
                  </span>
                </div>
                <div class="col-11">
                  <mat-form-field>
                    <mat-label>{{ labels['account_settings.label.timezone'] }}</mat-label>
                    <mat-select formControlName="Value">
                      <mat-option [value]="false">
                        {{ labels['account_settings.label.use_vendor_timezone'] }}
                      </mat-option>
                      <mat-option
                        *ngFor="let timezone of timezones"
                        [value]="timezone.name">
                        {{ timezone.name }} ({{ timezone.offset }})
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
              <p
                *ngIf="showVendorTimezone$$ | async"
                [innerHTML]="vendorTimezoneText | safe:'html'">
              </p>
            </ng-container>
          </div>
      </ng-template>
    </mat-expansion-panel>
    <!-- Geo panel end -->

    <!-- security panel start -->
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title [innerHTML]="labels['account_settings.label.security']"></mat-panel-title>
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>

        <div class="setting-rows-list" formArrayName="ExtendedProperties">

          <form [formGroup]="impersonationForm">

            <div class="row p-3 text-uppercase flex-column setting">
              <label class="form-group-label">{{ labels['account_settings.label.impersonation'] }}</label>
            </div>

            <div class="row py-3 align-items-center setting">
              <div class="col-9">
                <label class="form-group-label">
                  <i class="material-icons pr-2"
                     [matTooltip]="labels['account_settings.label.impersonation_by_flow_description']">help</i>
                  {{ labels['account_settings.label.impersonation_by_flow'] }}
                </label>
              </div>
              <div class="col text-left text-sm-right">
                <mat-slide-toggle formControlName="byFlow"></mat-slide-toggle>
              </div>
            </div>

            <ng-container *ngIf="isActingAsPartner">
              <div class="row py-3 align-items-center setting">
                <div class="col-9">
                  <label class="form-group-label">
                    <i class="material-icons pr-2"
                       [matTooltip]="labels['account_settings.label.impersonation_by_vendor_description']">help</i>
                    {{ labels['account_settings.label.impersonation_by_vendor'] }}
                  </label>
                </div>
                <div class="col text-left text-sm-right">
                  <mat-slide-toggle formControlName="byVendor"></mat-slide-toggle>
                </div>
              </div>

              <div class="row pb-3 align-items-center setting impersonation-users"
                   [ngClass]="{ inactive: !impersonationForm.get('byVendor').value }"
                   *ngIf="
                      impersonationForm.get('byVendor').value
                      ||
                      impersonationForm.get('allowedVendorUsers').value.length
                    "
                   [@slideInOutAnimation]
              >
                <div class="col text-left text-sm-right">
                  <mat-form-field class="example-chip-list">
                    <mat-label>{{ labels['account_settings.label.impersonation_allowed_users'] }}</mat-label>
                    <mat-chip-grid
                      #allowedVendorUsersRef
                      formControlName="allowedVendorUsers"
                      aria-label="Impersonation allowed users selection"
                    >
                      <mat-chip-row
                        *ngFor="let allowedVendorUser of impersonationForm.get('allowedVendorUsers').value"
                        [selectable]="true"
                        [removable]="true"
                        (removed)="onRemoveAllowedVendorUser(allowedVendorUser)"
                      >
                        {{ allowedVendorUser }}
                        <mat-icon matChipRemove>cancel</mat-icon>
                      </mat-chip-row>
                      <input
                        placeholder="{{ labels['account_settings.label.impersonation_enter_email'] }}"
                        [matChipInputFor]="allowedVendorUsersRef"
                        [matChipInputAddOnBlur]="true"
                        (matChipInputTokenEnd)="onAddAllowedVendorUser($event)"
                      >
                    </mat-chip-grid>
                  </mat-form-field>
                </div>
              </div>
            </ng-container>

            <div class="row py-3 align-items-center setting"
                 *ngIf="impersonationForm.get('byFlow').value || impersonationForm.get('byVendor').value"
                 [@slideInOutAnimation]
            >
              <div class="col-9">
                <label class="form-group-label">
                  <i class="material-icons pr-2"
                     [matTooltip]="labels['account_settings.label.impersonation_inform_description']">help</i>
                  {{labels['account_settings.label.impersonation_inform']}}
                </label>
              </div>
              <div class="col text-left text-sm-right">
                <mat-slide-toggle formControlName="inform"></mat-slide-toggle>
              </div>
            </div>

          </form>

          <hr class="mb-3">

          <div class="row py-3 align-items-center setting" [formGroupName]="'15'">
            <div class="col-9">
              <label class="form-group-label">
                <i class="material-icons pr-2"
                   [matTooltip]="labels['account_settings.label.two_factor_authentication_description']">help</i>
                {{ labels['account_settings.label.two_factor_authentication'] }}
              </label>
            </div>
            <div class="col text-left text-sm-right">
              <mat-slide-toggle
                [disabled]="isUsing2Fa"
                (change)="onChange2Fa($event)"
                formControlName="Value"></mat-slide-toggle>
            </div>
          </div>
          <div *ngIf="getValueFromExtra(15)" [@slideInOutAnimation]>
            <div class="row py-3 align-items-center" [formGroupName]="'6'">
              <div class="col-9">
                <label class="form-group-label">
                  <i class="material-icons pr-2"
                     [matTooltip]="labels['account_settings.label.remember_device.description']">help</i>
                  {{ labels['account_settings.label.trust_devices'] }}
                </label>
              </div>
              <div class="col text-left text-sm-right">
                <mat-slide-toggle formControlName="Value"></mat-slide-toggle>
              </div>
            </div>
            <mat-form-field
              [formGroupName]="3"
              [ngClass]="{'custom-mat-form-field-invalid': getExtra(3).invalid}">
              <mat-label>{{ labels['account_settings.label.recovery_email'] }}</mat-label>
              <input
                matInput
                formControlName="Value"
              >
            </mat-form-field>
            <div
              *ngIf="getExtra(3).invalid"
              class="custom-mat-form-error">
              <span
                *ngIf="getExtra(3).hasError('recoveryEmailMatchesUserEmail')"
                [innerHTML]="labels['account_settings.error.invalid.recovery_email']">
              </span>
              <span
                *ngIf="getExtra(3).hasError('invalidRecoveryEmail')"
                [innerHTML]="labels['generic.error.invalid.email']">
              </span>
            </div>

            <ng-container *ngIf="getExtra(3).valid; else recoveryEmailToProceedTpl">

              <div class="row align-items-center">
                <div class="col">
                  <mat-form-field>
                    <mat-label>{{ labels['account_settings.label.authentication_type'] }}</mat-label>
                    <mat-select  [(ngModel)]="selected2Fa" [ngModelOptions]="{standalone: true}" [disabled]="isUsing2Fa">
                      <mat-option
                        *ngFor="let tfa of allowed2Fa"
                        [value]="tfa.id">
                        {{ tfa.label }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>

              <!--
              <flow-yubico-register
                *ngIf="selected2Fa === tfa.yubico && !isUsing2Fa"
                (registrationResult)="confirm2Fa($event)"
              >
                {{ labels['account_settings.register.hardware_key'] }}
              </flow-yubico-register>
              -->

              <flow-google-auth-register
                *ngIf="selected2Fa === tfa.googleAuthenticator && !isUsing2Fa"
                (click)="onTriggerGoogleAuthRegister()"
                (registrationResult)="confirm2Fa($event)"
              >
                {{ labels['account_settings.button.register_google_authenticator_app'] }}
              </flow-google-auth-register>

              <flow-sms-auth-register
                *ngIf="selected2Fa === tfa.smsAuthenticator && !isUsing2Fa"
                (click)="onTriggerSmsAuthRegister()"
                (registrationResult)="confirm2Fa($event)"
              >
                <ng-container description>
                  {{ labels['account_settings.label.tfa_verification_sms_description'] }}
                </ng-container>

                <ng-container button>
                  {{ labels['account_settings.button.send_tfa_verification_sms'] }}
                </ng-container>
              </flow-sms-auth-register>

            </ng-container>
            <ng-template #recoveryEmailToProceedTpl>
              <small *ngIf="!isUsing2Fa">{{ labels['account_settings.label.recovery_email_before_tfa_setup'] }}</small>
            </ng-template>

            <p *ngIf="isUsing2Fa">
              <button
                class="button button-danger-outline button-small"
                [innerHTML]="labels['account_settings.label.disable_two_factor_authentication']"
                (click)="disableTwoFactorAuthentication()"
              ></button>
            </p>
          </div>
        </div>
      </ng-template>
    </mat-expansion-panel>
    <!-- security panel end -->

    <!-- portal notifications panel start -->
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title [innerHTML]="labels['account_settings.label.notifications']"></mat-panel-title>
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <div class="setting-rows-list" formArrayName="ExtendedProperties">
          <div class="row py-3 align-items-center setting" [formGroupName]="'7'">
            <div class="col-9">
              <label class="form-group-label">
                <i class="material-icons pr-2"
                   [matTooltip]="labels['account_settings.label.notifications_by_email_description']">help</i>
                {{ labels['account_settings.label.notifications_by_email'] }}
              </label>
            </div>
            <div class="col text-left text-sm-right">
              <mat-slide-toggle formControlName="Value"></mat-slide-toggle>
            </div>
          </div>

          <div class="row py-3 align-items-center setting" *ngIf="getValueFromExtra(7) === true" [formGroupName]="'8'" [@slideInOutAnimation]>
            <div class="col-9">
              <label class="form-group-label">
                <i class="material-icons pr-2"
                   [matTooltip]="labels['account_settings.label.notifications_by_email_daily_description']">help</i>
                {{labels['account_settings.label.notifications_by_email_daily']}}
              </label>
            </div>
            <div class="col text-left text-sm-right">
              <mat-slide-toggle formControlName="Value"></mat-slide-toggle>
            </div>
          </div>
        </div>
      </ng-template>
    </mat-expansion-panel>
    <!-- portal notifications panel end -->

    <!-- privacy panel start -->
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title [innerHTML]="labels['account_settings.label.privacy']"></mat-panel-title>
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>

        <ng-container *ngIf="isSupplierPortal; else defaultTermsTpl">
          <div class="setting-rows-list" *ngFor="let term of customerTerms">
            <div class="row p-3 flex-column setting">
              <label class="form-group-label">
                <span class="title" [innerHTML]="term.label" [matTooltip]="term.description"></span>
              </label>
              <p class="fs-12 color-infotext d-flex justify-content-between">
                <ng-container *ngIf="term.acceptedOn else notAccepted">
                  <span class="d-flex flex-column">
                    {{ labels['account_settings.label.accepted_on'] }} <strong [innerHTML]="term.acceptedOn | dateLocale:'medium'"></strong>
                  </span>

                  <button class="button button-small button-primary-outline mt-2"
                          (click)="withdrawConsent(term.extraName)"
                          [innerHTML]="labels['account_settings.label.withdraw_consent']">
                  </button>
                </ng-container>
              </p>
            </div>
          </div>
        </ng-container>

        <ng-template #defaultTermsTpl>
          <div class="setting-rows-list">
            <div class="row p-3 flex-column setting">
              <label class="form-group-label">
                <span class="title"
                      [innerHTML]="labels['generic.label.eula']"
                      [matTooltip]="interpolateHtmlParseLabelToString( labels['general.label.flow_terms_acceptance'] )"
                ></span>
              </label>
              <p class="fs-12 color-infotext d-flex justify-content-between">
                <ng-container *ngIf="getValueFromExtra(10) else notAccepted">
                  <span class="d-flex flex-column">
                    {{ labels['account_settings.label.accepted_on'] }} <strong [innerHTML]="getValueFromExtra(10) | dateLocale:'medium'"></strong>
                  </span>

                  <button class="button button-small button-primary-outline mt-2"
                          (click)="withdrawConsent( getExtra(10).value.PropertyName )"
                          [innerHTML]="labels['account_settings.label.withdraw_consent']">
                  </button>
                </ng-container>
              </p>
            </div>

            <div class="row p-3 flex-column setting">
              <label class="form-group-label">
                <span class="title"
                      [innerHTML]="labels['generic.label.flow_privacy']"
                      [matTooltip]="interpolateHtmlParseLabelToString( labels['general.label.flow_privacy_acceptance'] )"
                ></span>
              </label>
              <p class="fs-12 color-infotext d-flex justify-content-between">
                <ng-container *ngIf="getValueFromExtra(11) else notAccepted">
                  <span class="d-flex flex-column">
                    {{ labels['account_settings.label.accepted_on'] }} <strong [innerHTML]="getValueFromExtra(11) | dateLocale:'medium'"></strong>
                  </span>

                  <button class="button button-small button-primary-outline mt-2"
                          (click)="withdrawConsent( getExtra(11).value.PropertyName )"
                          [innerHTML]="labels['account_settings.label.withdraw_consent']">
                  </button>
                </ng-container>
              </p>
            </div>
            <div *ngIf="showMarketingPolicy" class="row p-3 flex-column setting">
              <label class="form-group-label">
                <span class="title"
                      [innerHTML]="labels['generic.label.flow_marketing_privacy']"
                      [matTooltip]="interpolateHtmlParseLabelToString( labels['general.label.marketing_policy'] )"
                ></span>
              </label>
              <p class="fs-12 color-infotext d-flex justify-content-between">
                <ng-container *ngIf="getValueFromExtra(12) else notAccepted">
                  <span class="d-flex flex-column">
                    {{ labels['account_settings.label.accepted_on'] }} <strong [innerHTML]="marketingPolicyAcceptanceDate | dateLocale:'medium'"></strong>
                  </span>

                  <button class="button button-small button-primary-outline mt-2"
                          (click)="withdrawConsent( getExtra(12).value.PropertyName )"
                          [innerHTML]="labels['account_settings.label.withdraw_consent']">
                  </button>
                </ng-container>
              </p>
            </div>

            <ng-container *ngIf="projectTerms.length">
              <div class="row p-3 flex-column setting" *ngFor="let projectTerm of projectTerms">
                <label class="form-group-label" [innerHTML]="projectTerm.PropertyName"></label>
                <p class="fs-12 color-infotext">
                  <ng-container *ngIf="projectTerm.Value else notAccepted">
                    {{ labels['account_settings.label.accepted_on'] }} <strong [innerHTML]="projectTerm.Value | dateLocale:'medium'"></strong>
                  </ng-container>
                </p>
              </div>
            </ng-container>
          </div>
        </ng-template>

        <hr class="mb-3">

        <div class="data-text-container color-infotext fs-13" [innerHTML]="labels['account_settings.label.privacy_about_description'] | safe:'html'"></div>

        <p class="mt-3">
          <button class="button button-primary-outline"
                  [disabled]="isExporting"
                  (click)="startExport.emit(form)"
                  [innerHTML]="labels['general.label.export_user_data_csv']">
          </button>
        </p>

        <ng-template #notAccepted>
          {{ labels['account_settings.label.not_accepted'] }}
        </ng-template>

      </ng-template>
    </mat-expansion-panel>
    <!-- privacy panel end -->
  </mat-accordion>
</form>
