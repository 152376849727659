/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[flowHelpSectionLoader]'
})
export class FlowHelpSectionLoaderDirective {

  constructor(public viewContainerRef: ViewContainerRef) { }

}
