/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { Component, OnInit, ChangeDetectionStrategy, Input, ChangeDetectorRef } from '@angular/core';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { FlowProjectService } from '@flow/auth';
import { FlowUtilsService, FlowTextBlockModelInterface, FlowLanguageInterface } from '@flow/core';
import { FlowDialogsService, FlowModalLanguageSelectionComponent } from '@flow/shared';
import { FlowTranslateLabel, FlowTranslateService } from '@flow/translate';

import { fadeInOutAnimation } from '../../../../animations';

@Component({
  selector: 'flow-textblock-translations',
  templateUrl: './textblock-translations.component.html',
  styleUrls: ['./textblock-translations.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeInOutAnimation]
})
export class FlowTextBlockTranslationsComponent implements OnInit {
  @Input() textBlock: FlowTextBlockModelInterface;
  @Input() maxLength: number;
  @Input() mode: string;
  @Input() helpTextId: string;
  @Input() rows: number;

  labels: FlowTranslateLabel;

  projectLanguages$: Observable<FlowLanguageInterface[]>;

  hasMissingTranslations = false;

  private _projectLanguages: FlowLanguageInterface[];
  private _missingTranslations: FlowLanguageInterface[];

  constructor(
    private ProjectService: FlowProjectService,
    private DialogsService: FlowDialogsService,
    private TranslateService: FlowTranslateService,
    private changeDetectorRef: ChangeDetectorRef,
    private UtilsService: FlowUtilsService
  ) {}

  ngOnInit(): void {
    this.maxLength = this.maxLength || 30;
    this.rows = this.rows || 2;

    this.labels = this._translateLabels();

    this.projectLanguages$ = this.ProjectService.getProjectLanguages()
    .pipe(
      tap(languages => {
        this._projectLanguages = languages.length ? languages : [];
        this._missingTranslations = this._getMissingTranslations();
        this.hasMissingTranslations = this._missingTranslations.length > 0;
      })
    );
  }

  addTranslation(): void {
    this.DialogsService.open(
      FlowModalLanguageSelectionComponent,
      {
        data: {
          languagesToSelect: this._missingTranslations
        }
      }
    )
    .afterClosed()
    .subscribe(selected => {
      if (selected && !this.textBlock.translations[selected]) {
        this.textBlock.translations[selected] = '';
        this._missingTranslations = this._getMissingTranslations();
        this.hasMissingTranslations = this._missingTranslations.length > 0;

        this.changeDetectorRef.markForCheck();
      }
    });
  }

  removeTranslation(key: string): void {
    delete this.textBlock.translations[key];

    this._missingTranslations = this._getMissingTranslations();
    this.hasMissingTranslations = this._missingTranslations.length > 0;
  }

  editTranslation(translation: {key: string; value: string }): void {
    this.DialogsService.textblockTranslationModal(translation.value)
      .afterClosed()
      .subscribe(result => this.textBlock.translations[translation.key] = result);
  }

  trackByFn(index: number): number {
    return index;
  }

  getLanguageName(code: string): string {
    return this.UtilsService.getLanguageName(code);
  }

  hasTranslations(): boolean {
    return !!Object.keys(this.textBlock.translations).length;
  }

  updateTranslation(key: string, value: string): void {
    this.textBlock.translations[key] = value;
  }

  /**
   * Get missing textblock translations
   */
  private _getMissingTranslations(): FlowLanguageInterface[] {
    return this._projectLanguages.filter(lang => !this.textBlock.translations.hasOwnProperty(lang.lcId));
  }

  /**
   * Translating the labels
   */
   private _translateLabels(): FlowTranslateLabel {
    const labelsToTranslate = [
      'general.label.no_translations',
      'general.label.add_translation',
      'general.btn.delete',
      'general.btn.edit',
      'general.label.text',
      'generic.error.required',
      {
        key: 'generic.error.maxlength',
        params: {
          number: this.maxLength
        }
      }
    ];

    this.helpTextId && (labelsToTranslate.push(this.helpTextId));

    return this.TranslateService.translateSync(labelsToTranslate);
  }
}
