<!--
This component was used in help area before.
As help area was removed this template is now different.
Keep this here until we have a final decision about the help area.

<ng-content></ng-content>
<button *ngIf="settings.view === 'modal'"
        class="button button-help-area button-block br-3"
        [innerHTML]="label"
        (click)="openHelpSite()">
</button>

<a *ngIf="settings.view !== 'modal'"
    class="button button-help-area button-block br-3"
    [innerHTML]="label"
    [href]="settings.url | safe:'resourceUrl'"
    (click)="closeHelp()"
    target="_blank">
</a>
-->

<button
  *ngIf="settings && settings.view === 'modal'"
  class="button"
  (click)="openHelpSite()">
  <i class="material-icons" [matTooltip]="label">help</i>
</button>

<a
  *ngIf="settings && settings.view !== 'modal'"
  class="button"
  [href]="settings.url | safe:'resourceUrl'"
  target="_blank">
  <i class="material-icons" [matTooltip]="label">help</i>
</a>
