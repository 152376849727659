<div id="header" class="d-flex justify-content-between">
  <a routerLink="/auth/logout" *ngIf="isOnboardingExternal; else internalTpl">
    <flow-portal-logo></flow-portal-logo>
  </a>

  <ng-template #internalTpl>
    <flow-portal-logo></flow-portal-logo>
  </ng-template>

  <div *ngIf="isOnboardingExternal">
    <button class="button" routerLink="/auth/logout">
      <i class="material-icons" [matTooltip]="labels['general.btn.back']">arrow_back</i>
    </button>
  </div>
</div>

<router-outlet></router-outlet>
