import { Action } from '@ngrx/store';
import { FlowSsoUserInterface } from '@flow/auth';

export enum CustomerActionTypes {
  LoadCustomer = '[FLOW Marketplace] FLOW Customer LOAD',
  LoadCustomerSuccess = '[FLOW Marketplace] FLOW Customer LOAD SUCCESS',
  LoadCustomerFail = '[FLOW Marketplace] FLOW Customer LOAD FAIL',
  CustomerLoadingStatus = '[FLOW Marketplace] FLOW Customer SET LOADING STATUS',
  UnloadCustomer = '[FLOW Marketplace] FLOW Customer UNLOAD',
  UpdateSsoUser = '[FLOW Marketplace] FLOW UpdateSsoUser UPDATE',
  UpdateSsoUserResult = '[FLOW Marketplace] FLOW UpdateSsoUserResult',
  SetPrivacyAgreement = '[FLOW Marketplace] Set Privacy Agreement',
  SetPrivacyAgreementResult = '[FLOW Marketplace] Set Privacy Agreement Result'
}

export class LoadCustomer implements Action {
  readonly type = CustomerActionTypes.LoadCustomer;
  constructor(public payload?: any) {}
}

export class LoadCustomerSuccess implements Action {
  readonly type = CustomerActionTypes.LoadCustomerSuccess;
  constructor(public payload: FlowSsoUserInterface) {}
}

export class LoadCustomerFail implements Action {
  readonly type = CustomerActionTypes.LoadCustomerFail;
  constructor(public payload: string) {}
}

export class CustomerLoadingStatus implements Action {
  readonly type = CustomerActionTypes.CustomerLoadingStatus;
  constructor(public payload: boolean) {}
}

export class UpdateSsoUser implements Action {
  readonly type = CustomerActionTypes.UpdateSsoUser;

  constructor(public payload: FlowSsoUserInterface) {}
}

export class UpdateSsoUserResult implements Action {
  readonly type = CustomerActionTypes.UpdateSsoUserResult;
  constructor(public payload: boolean) {}
}

export class UnloadCustomer implements Action {
  readonly type = CustomerActionTypes.UnloadCustomer;
}

export class SetPrivacyAgreement implements Action {
  readonly type = CustomerActionTypes.SetPrivacyAgreement;
  constructor(public payload: any) {}
}

export class SetPrivacyAgreementResult implements Action {
  readonly type = CustomerActionTypes.SetPrivacyAgreementResult;
  constructor(public payload: {
    user: FlowSsoUserInterface;
    result: boolean;
  }) {}
}

export type FlowCustomerActions =
  LoadCustomer |
  LoadCustomerSuccess |
  LoadCustomerFail |
  CustomerLoadingStatus |
  UpdateSsoUser |
  UpdateSsoUserResult |
  UnloadCustomer |
  SetPrivacyAgreement |
  SetPrivacyAgreementResult;
