/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { Component, ChangeDetectionStrategy, Input, ChangeDetectorRef, ElementRef, OnInit, AfterViewInit, OnDestroy, HostBinding } from '@angular/core';

import { Subscription } from 'rxjs';

import { FlowProductModelInterface } from '@flow/core';
import { FlowLayoutService } from '@flow/shared';

@Component({
  selector: 'flow-menu-main-bookmark',
  templateUrl: './menu-main-bookmark.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlowMenuMainBookmarkComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() iconClass: 'flow-icon' | 'material-icons' = 'material-icons';

  @Input() icon: string;

  @Input() label: string;

  @Input() extraClass: string;

  @Input() bookmark: any;

  @Input() boughtProducts: FlowProductModelInterface[];

  node: ElementRef;

  hideIcon: boolean;

  @HostBinding('class') get classes() {
    return `tab tab-hide-icon ${this.extraClass || ''}`;
  }

  private _subscription$$ = new Subscription();

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private elementRef: ElementRef,
    private LayoutService: FlowLayoutService
    ) { }

  ngOnInit(): void {
    this._subscription$$ = this.LayoutService.isMobile$.pipe(
    ).subscribe(isMobile => {
      this.hideIcon = isMobile ? false : this._getHiddenIcon();
      this.changeDetectorRef.markForCheck();
    });
  }

  ngAfterViewInit(): void {
    this.node = this.elementRef;
  }

  ngOnDestroy(): void {
    this._subscription$$.unsubscribe();
  }

  private _getHiddenIcon(): boolean {
    if (this.boughtProducts) {
      const foundProduct = this.boughtProducts.find(product => product.id === this.bookmark.id);

      if (foundProduct) {
        const { extra } = foundProduct;

        if (extra) {
          const hideIcon = extra.find(item => item.fieldName.toLowerCase() === 'hideicon');

          return hideIcon ? hideIcon.value.toLowerCase() === 'true' : false;
        }
      }
    }

    return false;
  }

}
