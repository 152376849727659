/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { FlowRouterService } from '@flow/core';
import { FlowUserService } from '@flow/auth';

import { FlowGatewayService } from '../modules/gateway/services/gateway/gateway.service';
import { FlowProjectDomainService } from '../services/project-domain.service';

@Injectable({
  providedIn: 'root'
})
export class FlowProjectDomainGuard  {
  constructor(
    private router: FlowRouterService,
    private UserService: FlowUserService,
    private GatewayService: FlowGatewayService,
    private ProjectDomainService: FlowProjectDomainService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

    // only if we're on a vendor subdomain, e.g: yes on xerox.flow-v2.tie, but not on flow-v2.tie
    if ( this.ProjectDomainService.hasVendorSubdomain ) {
      let sendToGateway = false;

      // only check when user has at least one "allowed" vendor; This is to prevent being redirected/gateway'ed when
      // user only has "TIEKinetix" in it's vendors array.
      if ( this.GatewayService.userHasAllowedVendor ) {
        if (
          !this.GatewayService.isUserOnAllowedDomain
          ||
          !this.GatewayService.userHasBoughtProducts  /* If company has NOT bought any product (is not a TIE customer)
                                                        but a supplier of a TIE Customer, then they won't have a portal,
                                                        meaning they cannot have the option to go to their own
                                                        vendor-domain. Aka they can be directly auto-redirect, however
                                                        we still send them to the gateway where they'll be given the
                                                        option to become a TIE customer by going to the webshop */
        ) {
          sendToGateway = true;
        }
      }
      else {
        if ( this.UserService.isActingAsVendor() ) {
          if ( !this.GatewayService.isVendorOnAllowedDomain ) {
            sendToGateway = true;
          }
        }
        else {
          console.warn('User has no allowed vendor; GATEWAY acting as "pass-through"');
        }
      }

      if ( !this.GatewayService.isOnFlowVendorSubdomain && this.GatewayService.isFlowUser ) {
        sendToGateway = true;
      }

      if (sendToGateway) {
        this.router.navigate(['gateway']);
        return false;
      }
    }

    return true;
  }
}
