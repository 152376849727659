import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';

import * as fromStore from '@flow/marketplaceStore';

@Injectable()
export class FlowApplicationReadyGuard  {
  constructor(
    private store: Store <fromStore.FlowMarketplaceStateInterface>,
  ) {}

  canActivate(): Observable <boolean> {
    return this.store
      .pipe(
        select(fromStore.getIfApplicationIsLoading),
        filter(loading => loading),
        take(1)
      );
  }
}
