/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  HostBinding,
  ViewChild
} from '@angular/core';

import { select, Store } from '@ngrx/store';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { FlowHelpers, FlowProductModelInterface, FlowVendorDashboardInterface } from '@flow/core';
import { FlowUserService } from '@flow/auth';
import { FlowTranslateLabel, FlowTranslateService } from '@flow/translate';
import { FlowMarketplaceStateInterface, getFlowProducts, getIsHelpPanelOpen } from '@flow/marketplaceStore';

import { FlowHelpSectionLoaderDirective } from '../../directives/help-section-loader/help-section-loader.directive';
import { FlowMarketplaceDashboardService } from '../../../marketplace-shared/services/marketplace-dashboard/marketplace-dashboard.service';

@Component({
  selector: 'flow-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlowHelpComponent implements OnInit {

  @ViewChild(FlowHelpSectionLoaderDirective, { static: true }) FlowHelpSectionLoaderDirective: FlowHelpSectionLoaderDirective;

  @HostBinding('class') className = 'd-block text-center px-3';

  show$: Observable <string>;

  products$: Observable <FlowProductModelInterface[]>;

  labels: FlowTranslateLabel;

  dashboard: FlowVendorDashboardInterface;

  hasCustomHelpSite: boolean;

  constructor(
    private store: Store <FlowMarketplaceStateInterface>,
    private UserService: FlowUserService,
    private TranslateService: FlowTranslateService,
    private MarketplaceDashboardService: FlowMarketplaceDashboardService
  ) { }

  ngOnInit(): void {
    this.dashboard = !this.UserService.isActingAsPartner()
                     ? this.MarketplaceDashboardService.getVendorDashboard()
                     : this.MarketplaceDashboardService.getProjectDashboard();

    this.hasCustomHelpSite = this._checkCustomHelpSite();

    this.show$ = this.store.pipe(
      select(getIsHelpPanelOpen),
      map(status => status ? 'active' : 'inactive' )
    );

    this.products$ = this.store.pipe(select(getFlowProducts));

    this.labels = this.TranslateService.translateSync([
      'help.label.title',
      'help.label.title_description',
      'help.label.quick_help',
      'help.label.virtual_tours',
      'help.label.button_start_tour',
      'help.label.help_zendesk',
      'help.label.visit_zendesk',
      'help.label.help',
      'help.label.visit_help',
      'help.label.community',
      'help.label.connect',
      'help.label.learning',
      'help.label.training_and_certification',
      'help.label.start_learning',
      'help.label.support',
      'help.label.ask_questions_support_desk',
      'help.label.contact_support',
      'help.label.marketplace_help',
      'help.label.all_programs'
    ]);
  }

  /**
   * Checks if customer has configured custom help site
   */
  _checkCustomHelpSite(): boolean {
    return FlowHelpers.hasProperty(this.dashboard, 'customHelpSite') &&
           FlowHelpers.hasProperty(this.dashboard.customHelpSite, 'url') &&
           this.dashboard.customHelpSite.url !== '';

  }
}
