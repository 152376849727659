import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { FlowRouterService } from '@flow/core';
import { FlowUserService } from '@flow/auth';
import { FlowTranslateLabel, FlowTranslateService } from '@flow/translate';

@Component({
  selector: 'flow-project-access',
  templateUrl: './project-access.component.html',
  styleUrls: ['./project-access.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlowProjectAccessComponent implements OnInit {

  labels: FlowTranslateLabel;

  // Possible values: onboarding_pending, rejected, disabled
  projectAccess: string;

  onboarding: {
    heading: string;
    label: string;
    path: string[];
  };

  private _project: string;

  constructor(
    private RouterService: FlowRouterService,
    private UserService: FlowUserService,
    private TranslateService: FlowTranslateService
  ) {
    this.projectAccess = this.RouterService.getPropertyFromNavigationExtras('projectAccess');
    this._project = this.RouterService.getPropertyFromNavigationExtras('project');
  }

  ngOnInit() {
    this.labels = this._translateLabels();

    // Allow onboarding again for rejected companies
    if (this.projectAccess === 'rejected') {
      this._setOnboardingTarget();
    }
  }

  /**
   * Redirect to onboarding path
   */
  onboard() {
    this.RouterService.navigate(this.onboarding.path);
  }

  /**
   * Set onboarding object for showing the onboarding possibility
   */
  private _setOnboardingTarget(): void {
    const companyName = this.UserService.vendorDescription;

    this.onboarding = {
      heading: this.TranslateService.instant({
        key: 'login.label.gateway_goto_onboard_again',
        params: { company: companyName }
      }),
      label: this.TranslateService.instant({
        key: 'login.label.gateway_start_onboard_again',
        params: { company: companyName }
      }),
      path: ['onboarding', this._project]
    };
  }

  /**
   * Translate the labels
   */
  private _translateLabels(): FlowTranslateLabel {
    return this.TranslateService.translateSync([
      'general.label.logout',

      'error.general.error_loading',
      'login.label.accesspage.onboarding_pending',
      'login.label.accesspage.onboarding_rejected',
      'login.label.accesspage.onboarding_disabled'
    ]);
  }
}
