/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { AfterViewInit, Directive, ElementRef, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[flowIframe]'
})
export class FlowIframeDirective implements OnInit, AfterViewInit {

  constructor(
    private elementRef: ElementRef,
    public renderer2: Renderer2
  ) { }

  ngOnInit(): void {
    this.renderer2.addClass(this.elementRef.nativeElement, 'flow-iframe');
  }

  ngAfterViewInit(): void {
    this.elementRef.nativeElement.onload = () => {
      console.log('test');
    };
  }

}
