/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { Component, OnInit, ChangeDetectionStrategy, HostBinding } from '@angular/core';

import { select, Store } from '@ngrx/store';

import { Observable } from 'rxjs';

import { FlowProductModelInterface, FlowVendorDashboardInterface, KinetixExtraModel } from '@flow/core';
import { FlowProjectService, FlowUserService } from '@flow/auth';
import { FlowTranslateLabel, FlowTranslateService } from '@flow/translate';
import { FlowMarketplaceStateInterface, getFlowProducts } from '@flow/marketplaceStore';

import { FlowMarketplaceDashboardService } from '../../../marketplace-shared/services/marketplace-dashboard/marketplace-dashboard.service';
import { FlowPortalCustomHelpSiteService } from '../../../admin/modules/portal/components/portal-custom-help-site/portal-custom-help-site.service';

@Component({
  selector: 'flow-help-icon',
  templateUrl: './help-icon.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlowHelpIconComponent implements OnInit {

  @HostBinding('class') classNames = 'help-icon';

  labels: FlowTranslateLabel;

  dashboard: FlowVendorDashboardInterface;

  customHelpSiteUrl = '';
  customHelpSiteInModal = false;

  products$: Observable <FlowProductModelInterface[]>;
  projectExtras$: Observable <KinetixExtraModel[]>;

  constructor(
    private store: Store <FlowMarketplaceStateInterface>,
    private ProjectService: FlowProjectService,
    private UserService: FlowUserService,
    private TranslateService: FlowTranslateService,
    private MarketplaceDashboardService: FlowMarketplaceDashboardService,
    private PortalCustomHelpSiteService: FlowPortalCustomHelpSiteService,
  ) { }

  ngOnInit(): void {
    this.labels = this._translateLabels();

    this.dashboard = !this.UserService.isActingAsPartner()
      ? this.MarketplaceDashboardService.getVendorDashboard()
      : this.MarketplaceDashboardService.getProjectDashboard();

    const { customHelpSite } = this.dashboard;

    if ( customHelpSite ) {
      const { target } = customHelpSite;
      let value;

      switch (target) {
        case 'website':
          value = this.PortalCustomHelpSiteService.getLanguageBasedUrl(this.dashboard.customHelpSite.url);
          this.customHelpSiteInModal = this.dashboard.customHelpSite.view === 'modal';
          break;
        case 'email':
          value = this.PortalCustomHelpSiteService.generateMailto( this.dashboard.customHelpSite );
          break;
        default: /* backwards-compatibility; aka pre "target", ake pre separation of "website" and "email" */
          value = this.dashboard.customHelpSite.url;
          break;
      }

      this.customHelpSiteUrl = value;
    }

    this.products$ = this.store.pipe(select(getFlowProducts));

    this.projectExtras$ = this.ProjectService.getProjectExtras();
  }

  /**
   * Translate necessary labels
   */
  private _translateLabels(): FlowTranslateLabel {
    return this.TranslateService.translateSync(['general.label.help']);
  }
}
