/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FlowAuthModule } from '@flow/auth';
import { FlowComponentsModule, FlowMaterialModule, FlowPipesModule, FlowDirectivesModule } from '@flow/shared';
import { FlowTranslateModule } from '@flow/translate';

import { FlowLayoutModule } from '../layout/layout.module';

import { FlowDisclaimerRoutingModule } from './disclaimer-routing.module';
import { FlowDisclaimerComponent } from './containers/disclaimer.component';

@NgModule({
  imports: [
    CommonModule,
    FlowAuthModule,
    FlowComponentsModule,
    FlowMaterialModule,
    FlowPipesModule,
    FlowDirectivesModule,
    FlowTranslateModule,
    FlowLayoutModule,
    FlowDisclaimerRoutingModule
  ],
  declarations: [
    FlowDisclaimerComponent
  ]
})

export class FlowDisclaimerModule {
}
