<ng-container *ngIf="!isSaved; else registerBtn">
  <form [formGroup]="form" class="enforce-tfa">
    <div *ngIf="allowed2Fa.length === 1; else multipleTfaTypes">
      <p class="my-4" [innerHTML]="labels['account_settings.tfa.subtitle.single']"></p>
      <h4 class="mb-4">{{ allowed2Fa[0].label }}</h4>
    </div>

    <ng-template #multipleTfaTypes>
      <p class="my-4" [innerHTML]="labels['account_settings.tfa.subtitle.multiple']"></p>
      <mat-form-field class="form-area">
        <mat-label>{{ labels['account_settings.label.authentication_type'] }}</mat-label>
        <mat-select
          [(ngModel)]="selected2Fa"
          [ngModelOptions]="{standalone: true}"
          (selectionChange)="onChange2FaType($event.value)">
          <mat-option
            *ngFor="let tfa of allowed2Fa"
            [value]="tfa.id">
            {{ tfa.label }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </ng-template>

    <div *ngIf="selected2Fa" class="d-flex justify-content-center">
      <div class="form-area">
        <div class="mb-2">
          <mat-form-field>
            <mat-label>{{ labels['account_settings.label.recovery_email'] }}</mat-label>
            <input
              matInput
              formControlName="recoveryEmail"
              required>
            <mat-error
              [hidden]="recoveryEmail.valid"
              [innerHTML]="recoveryEmail.hasError('required')
                ? labels['generic.error.required']
                : recoveryEmail.hasError('invalidRecoveryEmail')
                ? labels['generic.error.invalid.email']
                : recoveryEmail.hasError('recoveryEmailMatchesUserEmail')
                ? labels['account_settings.error.invalid.recovery_email']
                : null">
            </mat-error>
          </mat-form-field>
        </div>
        <div class="d-flex mb-4">
          <label class="form-group-label with-info">
            {{ labels['account_settings.label.trust_devices'] }}
            <span class="info text-left">{{ labels['account_settings.tfa.help.trust_devices'] }}</span>
          </label>
          <mat-slide-toggle
            formControlName="trustDevices"
            class="ml-2">
          </mat-slide-toggle>
        </div>
        <div *ngIf="selected2Fa === tfa.smsAuthenticator">
          <mat-form-field>
            <mat-label>{{ labels['account_settings.label.phone'] }}</mat-label>
            <input
              matInput
              formControlName="phoneNumber"
              required>
              <mat-hint>{{ labels['account_settings.tfa.help.phone'] }}</mat-hint>
              <mat-error
                [hidden]="phoneNumber.valid"
                [innerHTML]="phoneNumber.hasError('required')
                  ? labels['generic.error.required']
                  : phoneNumber.hasError('pattern')
                  ? labels['account_settings.label.error.invalid.phone']
                  : null">
              </mat-error>
          </mat-form-field>
        </div>
        <div class="mt-4">
          <button
            [disabled]="form.invalid"
            [innerHTML]="labels['general.btn.save']"
            (click)="onSubmit()"
            class="button button-primary-outline">
          </button>
        </div>
      </div>
    </div>
  </form>
</ng-container>

<ng-template #registerBtn>
  <div class="mt-4">
    <div
      class="mb-4"
      [innerHTML]="labels['account_settings.tfa.instruction']">
    </div>
    <div class="register-buttons">
      <button
        [innerHTML]="labels['general.btn.back']"
        (click)="onBack()"
        class="button button-secondary-outline">
      </button>
      <flow-google-auth-register
        *ngIf="selected2Fa === tfa.googleAuthenticator"
        (registrationResult)="confirm2Fa($event)">
        {{ labels['account_settings.button.register_google_authenticator_app'] }}
      </flow-google-auth-register>
      <flow-sms-auth-register
        *ngIf="selected2Fa === tfa.smsAuthenticator"
        [isEnforce2FaPage]="true"
        (registrationResult)="confirm2Fa($event)">
        <ng-container button>
          {{ labels['account_settings.button.send_tfa_verification_sms'] }}
        </ng-container>
      </flow-sms-auth-register>
    </div>
  </div>
</ng-template>
