import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FlowMarketplaceStateInterface } from '@flow/marketplaceStore';
import { CompanyStateInterface } from '@flow/marketplaceStore/reducers/company.reducer';

export const getCustomerCompany = createFeatureSelector<FlowMarketplaceStateInterface, CompanyStateInterface>('company');

export const getIfCompanyIsLoaded = createSelector(
  getCustomerCompany,
  (state: CompanyStateInterface) => state.loaded
);

export const getCurrentCompany = createSelector(
  getCustomerCompany,
  (state: CompanyStateInterface) => state.data
);

export const getIfCompanyIsLoading = createSelector(
  getCustomerCompany,
  (state: CompanyStateInterface) => state.loading
);

export const getIfCompanySaved = createSelector(
  getCustomerCompany,
  (state: CompanyStateInterface) => state.saved
);
