/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  OnInit,
  OnChanges,
  SimpleChanges,
  OnDestroy,
  EventEmitter,
  HostBinding
} from '@angular/core';

import { Observable, Subscription } from 'rxjs';

import { FlowWebsocketNotificationMessageInterface, FlowWebsocketService } from '@flow/shared';
import { FlowTranslateLabel, FlowTranslateService } from '@flow/translate';
import { FlowUserService } from '@flow/auth';

import { FlowNotificationsService } from '../../../marketplace-shared/services/notifications/notifications.service';

@Component({
  selector: 'flow-menu-top-notifications',
  templateUrl: './menu-top-notifications.component.html',
  styleUrls: ['./menu-top-notifications.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlowMenuTopNotificationsComponent implements OnInit, OnChanges, OnDestroy {

  @Input() isMenuOpen: boolean;

  @Input() notifications$: Observable<FlowWebsocketNotificationMessageInterface>;

  @Output() hasNotifications: EventEmitter<void> = new EventEmitter();

  @HostBinding('id') id = 'notifications-list';

  subscription$$: Subscription = new Subscription();

  get notifications(): FlowWebsocketNotificationMessageInterface[] {
    return this._notifications;
  }

  private _lastNotification: FlowWebsocketNotificationMessageInterface;

  private _notifications: FlowWebsocketNotificationMessageInterface[] = [
    // {
    //   color: 'green',
    //   from: 'webshop_published',
    //   hideCounter: false,
    //   icon: 'websh',
    //   id: '0d69a9dd-ed6a-4189-b802-028db0f252f1',
    //   link: null,
    //   message: 'Your webshop has been published !',
    //   messagesCount: 1,
    //   progress: false,
    //   progressMode: 'determinate',
    //   read: false
    // },
    // {
    //   color: 'green',
    //   from: 'webshop_published',
    //   hideCounter: false,
    //   icon: 'ppcca',
    //   id: '0d69a9dd-ed6a-4189-b802-028db0f252f2',
    //   link: null,
    //   message: 'A new campaign has been created. A new campaign has been created. A new campaign has been created.',
    //   messagesCount: 1,
    //   progress: false,
    //   progressMode: 'determinate',
    //   read: false
    // }
  ];

  labels: FlowTranslateLabel;

  constructor(
    private UserService: FlowUserService,
    private WebsocketService: FlowWebsocketService,
    private TranslateService: FlowTranslateService,
    private NotificationsService: FlowNotificationsService
  ) {}

  ngOnInit(): void {
    this.labels = this._translateLabels();

    this.subscription$$ = this.notifications$.subscribe(notification => {
      if (notification) {
        this.addNotification(notification as FlowWebsocketNotificationMessageInterface);
        this.hasNotifications.emit();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isMenuOpen && !changes.isMenuOpen.firstChange && changes.isMenuOpen.currentValue === false ) {
      this.markNotificationsAsRead();
    }
  }

  ngOnDestroy(): void {
    this.subscription$$.unsubscribe();
  }

  /**
   * Add incoming notification
   */
  addNotification(notification: FlowWebsocketNotificationMessageInterface): void {
    const exists = this._notifications.find(stored => stored.id === notification.id);

    this._lastNotification = notification;

    // Group notifications by id
    if (exists) {
      exists.messagesCount += 1;
      exists.read = false;
    }
    else {
      this._notifications.unshift(notification);
    }

    // Update notification service
    this.NotificationsService.set(this._notifications);
  }

  /**
   * Remove notification and the ones below
   */
  onRemove(index: number): void {
    const toBeRemoved = this._notifications[index];

    // Remove selected notification and the ones below from the UI
    this._notifications.splice(index, this.notifications.length - index);

    // Update notification service
    this.NotificationsService.set(this._notifications);

    // Confirm the selected notification to update the BE pointer.
    toBeRemoved._raw.subject = 'confirmation';
    toBeRemoved._raw.from = this.UserService.username;

    this.WebsocketService.send(toBeRemoved._raw);
  }

  /**
   * Mark all notifications as read
   */
  markNotificationsAsRead(): void {
    if (!this._notifications.length || !this._lastNotification) {
      return;
    }

    // Mark all notifications as "read"
    this._notifications.forEach(notification => notification.read = true);

    // Update notification service
    this.NotificationsService.set(this._notifications);

    // The last notifcation shouldn't be removed anymore when clicking the bell icon.
    // Keeping the code in case this should be changed again

    // Confirm the last notification to update the BE pointer.
    //this._lastNotification._raw.subject = 'confirmation';
    //this._lastNotification._raw.from = this.UserService.username;

    // const lastNotification = this._notifications.slice(-1);
    // lastNotification[0]._raw.subject = 'confirmation';
    // lastNotification[0]._raw.from = this.UserService.username;


    //this.WebsocketService.send(this._lastNotification._raw);
    // this.WebsocketService.send(lastNotification[0]._raw);

    // this._lastNotification = undefined;
  }

  /**
   * Translate the labels
   */
  private _translateLabels(): FlowTranslateLabel {
    return this.TranslateService.translateSync([
      'general.label.no_new_notifications',
      'general.label.delete_notifications',
      'general.label.delete_notification'
    ]);
  }
}
