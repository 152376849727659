/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FlowComponentsModule } from '@flow/shared';

import { FlowLayoutModule } from '../layout/layout.module';

import { FlowProjectAccessRoutingModule } from './project-access-routing.module';
import { FlowProjectAccessComponent } from './containers/project-access/project-access.component';


@NgModule({
  imports: [
    CommonModule,
    FlowComponentsModule,
    FlowLayoutModule,
    FlowProjectAccessRoutingModule
  ],
  declarations: [
    FlowProjectAccessComponent
  ]
})

export class FlowProjectAccessModule {
}
