/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { Component, OnInit, ChangeDetectionStrategy, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FlowTranslateLabel, FlowTranslateService } from '@flow/translate';
import { FlowHelpBlockInterface } from '../../interfaces/help-block.interface';

@Component({
  selector: 'flow-help-menu',
  templateUrl: './help-menu.component.html',
  styleUrls: ['./help-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: []
})
export class FlowHelpMenuComponent implements OnInit, OnDestroy {

  @Output() sectionChanged: EventEmitter <string> = new EventEmitter();

  labels: FlowTranslateLabel;

  blocks: FlowHelpBlockInterface[];

  state = 'opened';

  constructor(
    private TranslateService: FlowTranslateService
  ) {}

  ngOnDestroy(): void {
    this.state = 'closed';
  }

  ngOnInit(): void {
    this.labels = this.TranslateService.translateSync([
      'help.label.title',
      'help.label.title_description',
      'help.label.quick_help',
      'help.label.virtual_tours',
      'help.label.button_start_tour',
      'help.label.help_zendesk',
      'help.label.visit_zendesk',
      'help.label.community',
      'help.label.connect',
      'help.label.learning',
      'help.label.training_and_certification',
      'help.label.start_learning',
      'help.label.support',
      'help.label.ask_questions_support_desk',
      'help.label.contact_support',
      'help.label.marketplace_help',
      'help.label.all_programs'
    ]);

    this.blocks = [
      {
        title: this.labels['help.label.quick_help'],
        columns: 'col-xl-5',
        classes: ['mb-sm-5 mb-xl-0'],
        blocks: [
          {
            id: 'userlane',
            columns: 'col-md-4',
            title: this.labels['help.label.virtual_tours'],
            buttonTitle: this.labels['help.label.button_start_tour'],
            image: 'assets/images/support-1.jpg'
          },
          {
            id: 'zendesk',
            columns: 'col-md-4',
            title: this.labels['help.label.help_zendesk'],
            buttonTitle: this.labels['help.label.visit_zendesk'],
            image: 'assets/images/support-2.jpg'
          },
          {
            id: 'community',
            columns: 'col-md-4',
            title: this.labels['help.label.community'],
            buttonTitle: this.labels['help.label.connect'],
            image: 'assets/images/support-1.jpg'
          }
        ]
      },
      {
        title: this.labels['help.label.learning'],
        columns: 'col-md-4 col-xl-2',
        classes: ['learning-block'],
        blocks: [
          {
            id: 'learning',
            columns: 'col-sm-12',
            title: this.labels['help.label.training_and_certification'],
            buttonTitle: this.labels['help.label.start_learning'],
            image: 'assets/images/support-1.jpg'
          }
        ]
      },
      {
        title: this.labels['help.label.support'],
        columns: 'col-md-8 col-xl-5',
        blocks: [
          {
            id: 'support',
            columns: 'col-md-6',
            title: this.labels['help.label.ask_questions_support_desk'],
            buttonTitle: this.labels['help.label.contact_support'],
            image: 'assets/images/support-1.jpg'
          },
          {
            id: 'marketplace',
            columns: 'col-md-6',
            title: this.labels['help.label.marketplace_help'],
            buttonTitle: this.labels['help.label.all_programs'],
            image: 'assets/images/support-2.jpg'
          }
        ]
      }
    ];
  }
}
