/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { Component, ChangeDetectionStrategy } from '@angular/core';
import { FlowTranslateLabel, FlowTranslateService } from '@flow/translate';

@Component({
  selector: 'flow-admin-overview-link',
  templateUrl: './admin-overview-link.component.html',
  styleUrls: ['./admin-overview-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlowAdminOverviewLinkComponent {

  labels: FlowTranslateLabel;

  constructor(
    private TranslateService: FlowTranslateService
  ) {
    this.labels = this.TranslateService.translateSync(['breadcrumbs.label.administration']);
  }
}
