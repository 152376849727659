<div class="block-inner__image"
     flowBackgroundImage
     [imageUrl]="image"
     [backgroundSizeType]="'contain'"
     [backgroundPosition]="['center', 'center']"></div>
<div class="block-inner__title d-flex align-items-center justify-content-center">
  <h6 [innerHTML]="title"></h6>
</div>
<button class="button button-white-outline button-block"
        (click)="clickEvent.emit()"
        [innerHTML]="buttonTitle"></button>
