/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { FlowRouterService } from '@flow/core';
import { FlowPasswordExpiredService } from '../modules/password-expired/services/password-expired/password-expired.service';

@Injectable({
  providedIn: 'root'
})
export class FlowPasswordExpireGuard  {
  constructor(
    private router: FlowRouterService,
    private PasswordExpiredService: FlowPasswordExpiredService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

    if ( this.PasswordExpiredService.hasExpiredPassword ) {
      // date has been surpasses, aka pass must change, so redirect to change password page
      this.router.navigate(['password-expired']);
      return false;
    }

    return true;
  }
}
