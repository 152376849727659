<div class="help-container container">
  <div class="py-5">
    <h2 class="mb-2 fw-400" [innerHTML]="labels['help.label.title']"></h2>
    <h6 class="mb-3 fw-400" [innerHTML]="labels['help.label.title_description']"></h6>

    <div class="row pt-md-4">
      <div class="col-md-5">
        <div class="row">
          <div class="col-6">
            <flow-help-virtual-tours [label]="labels['help.label.button_start_tour']">
              <div class="block-image"
                   flowBackgroundImage
                   [imageUrl]="'assets/images/support-1.png'"
                   [backgroundSizeType]="'contain'"
                   [backgroundPosition]="['center', 'center']"></div>
              <div class="block-title d-flex align-items-center justify-content-center">
                <h6 class="fw-400" [innerHTML]="labels['help.label.virtual_tours']"></h6>
              </div>
            </flow-help-virtual-tours>
          </div>
          <div class="col-6">
            <flow-help-zendesk
              *ngIf="!hasCustomHelpSite"
              [label]="labels['help.label.visit_zendesk']"
              [products]="products$ | async">
              <div class="block-image"
                   flowBackgroundImage
                   [imageUrl]="'assets/images/support-1.png'"
                   [backgroundSizeType]="'contain'"
                   [backgroundPosition]="['center', 'center']"></div>
              <div class="block-title d-flex align-items-center justify-content-center">
                <h6 class="fw-400" [innerHTML]="labels['help.label.help_zendesk']"></h6>
              </div>
            </flow-help-zendesk>
            <flow-help-custom-site
              *ngIf="hasCustomHelpSite"
              [label]="labels['help.label.visit_help']"
              [settings]="dashboard.customHelpSite">
              <div class="block-image"
                   flowBackgroundImage
                   [imageUrl]="'assets/images/support-1.png'"
                   [backgroundSizeType]="'contain'"
                   [backgroundPosition]="['center', 'center']"></div>
              <div class="block-title d-flex align-items-center justify-content-center">
                <h6 class="fw-400" [innerHTML]="labels['help.label.help']"></h6>
              </div>
            </flow-help-custom-site>
          </div>
        </div>
      </div>
      <div class="col-md-1"></div>
      <div class="col-md-5"></div>
    </div>

  </div>
</div>
