export const environment = {
  name: 'test',
  production: false,
  cmsUrl: 'https://backend-cms-test.tiekinetix.net',
  googleImagesUrl: 'https://tie-flow-images.storage.googleapis.com',
  googleStorageUrl: 'https://storage.googleapis.com',
  flowShopUrls: {
    nl: 'https://tiekinetix-site.flow-test.tiekinetix.net',
    fr: 'https://tiekinetixfr-site.flow-test.tiekinetix.net'
  },
  shopUrl: 'https://backend-shop-test.tiekinetix.net',
  proxyUrl: 'https://flow-proxy-test.tiekinetix.net',
  validatorUrl: 'https://staging-blackbox.tiekinetix.net/flowsso',
  wsUrl: 'wss://backend-cms-test.tiekinetix.net',
  docManUrl: 'https://docman-internal-api.azurewebsites.net',
  tpmUrl: 'https://backend-leads-test.tiekinetix.net',
  local: false
};
