<div [ngClass]="['notification-idx-' + currentIndex, 'notification-content d-flex align-items-top']">
  <i class="flow-icon" [ngClass]="'icon-' + notification.icon"></i>
  <div class="notification-content-inner">
    <h6 [innerHTML]="productName$ | async"></h6>
    <p>{{ notification.time | dateLocale:'short' }} </p>
    <p
      [flowTranslate]="flattenRootDefaultReplacementCodes(notification.message, notification.extras)"
      [translateParams]="getDefaultReplacementCodes(notification.extras)"
    ></p>
  </div>
</div>
<ul class="notification-options">
  <li *ngIf="messagesCount && messagesCount > 1" [innerHTML]="messagesCount"></li>
</ul>

<div class="absolute-center d-flex align-items-top justify-content-end remove-notification">
  <button
    (click)="onRemoveClick($event)"
    (mouseover)="onMouseOver(currentIndex)"
    (mouseout)="onMouseOut(currentIndex)"
    class="button button-icon color-text"
    [matTooltip]="notificationsCount > currentIndex + 1
                  ? labels['general.label.delete_notifications']
                  : labels['general.label.delete_notification']">
    <i class="material-icons">delete_outline</i>
  </button>
</div>
