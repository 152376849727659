/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, PRIMARY_OUTLET, Router } from '@angular/router';

import { BehaviorSubject, Subscription } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

import { FlowRouterService, FlowHelpers } from '@flow/core';
import { FlowTranslateService } from '@flow/translate';

export interface FlowBreadCrumbPathInterface {
  label: string;
  url: string[];
  lastWithLink: boolean;
}

@Component({
  selector: 'flow-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlowBreadcrumbComponent implements OnInit, OnDestroy {

  @Input() hidden: boolean;

  @Input() staticTitle: boolean;

  @Input()
  set title(value) {
    if (value) {
      this.title$$.next(value);
    }
  }

  @Output() isAdminSolution: EventEmitter<boolean> = new EventEmitter();

  title$$: BehaviorSubject<string> = new BehaviorSubject(null);
  subscription$$: Subscription = new Subscription();

  path: FlowBreadCrumbPathInterface[] = [];

  constructor(
    private routerService: FlowRouterService,
    private router: Router,
    private TranslateService: FlowTranslateService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    if (!this.hidden) {
      this.subscription$$ = this.routerService.navigationEnd$
      .pipe(
        map(() => this.activatedRoute),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        tap(route => this.isAdminSolution.emit(route.outlet === 'admin-solution')),
        filter(route => route.outlet === PRIMARY_OUTLET || route.outlet === 'admin-solution')
      )
      .subscribe(route => {
        const basePath = ['/'];
        const routeSnapshotData = route.snapshot.data;

        this.path = this.router.routerState.snapshot.url.split('/').reduce((acc, current: string) => {
          const hideId = /^([a-f0-9]{8}(?:-[a-f0-9]{4}){3}-[a-f0-9]{12}|([0-9]*))$/.test(current);

          if (current) {
            if (!hideId) {
              basePath.push(current);
              acc.push({
                label: this._getLabel(current, routeSnapshotData),
                url: [ ...basePath ],
                lastWithLink: false
              });
            }
            else {
              acc[acc.length -1].lastWithLink = true;
            }
          }
          return acc;
        }, []);

        if (!this.staticTitle) {
          let translation = null;

          if (routeSnapshotData.breadcrumb && routeSnapshotData.breadcrumb.title) {
            // Extension for dynamic menu title in FlowEinvoSettingsComponent and FlowEinvoNotificationsComponent
            if (
              FlowHelpers.hasProperty(routeSnapshotData.breadcrumb, 'params')
              &&
              FlowHelpers.hasProperty(routeSnapshotData.breadcrumb.params, 'product')
              &&
              routeSnapshotData.breadcrumb.params.product !== ''
            ) {
              const params = {
                ...routeSnapshotData.breadcrumb.params,
                product: this.TranslateService.instant({ key: routeSnapshotData.breadcrumb.params.product })
              };

              translation = this.TranslateService.instant({ key: routeSnapshotData.breadcrumb.title, params });
            }
            else {
              translation = this.TranslateService.instant({ key: routeSnapshotData.breadcrumb.title });
            }
          }

          this.title$$.next(translation);
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.subscription$$.unsubscribe();
    this.title$$.complete();
  }

  /**
   * Building the labels and taking into account some special features
   */
  private _getLabel(current: string, snapshotData: any): string {
    // Basic company integration settings
    if (current === 'basic-settings' || current === 'system-connectors') {
      return this.TranslateService.instant({key: snapshotData.breadcrumb.title});
    }
    else {
      const translationId = `breadcrumbs.label.${current.replace(/-/g, '_')}`;
      const translation = this.TranslateService.instant({ key: translationId });

      return (translation !== translationId) ? translation : current;
    }
  }
}
