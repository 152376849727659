<flow-abs-preloader *ngIf="isLoading$ | async else view"></flow-abs-preloader>

<ng-template #view>
  <flow-textblock-translations
    [textBlock]="textBlockData"
    [mode]="textBlockData.type"
  ></flow-textblock-translations>

  <flow-save-button
    (click)="save()"
    [classes]="'pt-5 pull-right text-right'"
    [isSaving]="isSaving$ | async"
  ></flow-save-button>
</ng-template>
