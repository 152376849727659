<div class="p-3 modal-title bg-primary color-white">
  <h5 class="truncate" [innerHTML]="data.title"></h5>
</div>
<mat-dialog-content [ngStyle]="{'max-height': iframeHeight, 'height': iframeHeight}">
  <div *ngIf="isLoading" class="preloader">
    <flow-preloader></flow-preloader>
  </div>
  <ng-container *ngIf="(validatorLink$ | async) as link">
    <ng-container [ngSwitch]="link">
      <ng-container *ngSwitchDefault>
        <iframe [src]="link | safe:'resourceUrl'" frameborder="0" width="100%" height="100%" (load)="onLoad()"></iframe>
      </ng-container>
      <ng-container *ngSwitchCase="'loadError'">
        <flow-alert-message
          [type]="'danger'"
          [messageId]="'error.general.error_loading'"
          [extraClasses]="'justify-content-center'">
        </flow-alert-message>
      </ng-container>
    </ng-container>
  </ng-container>
</mat-dialog-content>
<mat-dialog-actions>
  <button
    class="button button-close-dialogs"
    [mat-dialog-close]="false"
    [innerHTML]="labels['general.btn.close']">
  </button>
</mat-dialog-actions>

<!-- <div class="p-3 modal-title bg-primary color-white">
  <h5 class="fw-500 truncate" [innerHTML]="data.title"></h5>
</div>
<mat-dialog-content [ngStyle]="{'max-height': iframeHeight, 'height': iframeHeight}">
  <div *ngIf="isLoading" class="preloader">
    <flow-preloader></flow-preloader>
  </div>
  <ng-container *ngIf="(validatorLink$ | async) as link">
    <iframe [src]="link | safe:'resourceUrl'" frameborder="0" width="100%" height="100%" (load)="onLoad()"></iframe>
  </ng-container>
</mat-dialog-content>
<mat-dialog-actions>
  <button class="button button-close-dialogs"
          [mat-dialog-close]="false"
          [innerHTML]="labels['general.btn.close']"></button>
</mat-dialog-actions> -->
