/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FlowComponentsModule, FlowDirectivesModule, FlowPipesModule } from '@flow/shared';
import { FlowTranslateModule } from '@flow/translate';
import { FlowAuthModule } from '@flow/auth';

import { FlowGatewayRoutingModule } from './gateway-routing.module';
import { FlowGatewayComponent } from './containers/gateway/gateway.component';
import { FlowLayoutModule } from '../layout/layout.module';

@NgModule({
  imports: [
    CommonModule,
    FlowComponentsModule,
    FlowDirectivesModule,
    FlowPipesModule,
    FlowTranslateModule,
    FlowAuthModule,
    FlowGatewayRoutingModule,
    FlowLayoutModule
  ],
  declarations: [
    FlowGatewayComponent
  ]
})

export class FlowGatewayModule {
}
