/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { Injectable } from '@angular/core';

import { tap } from 'rxjs/operators';

import { FlowHelpers, FlowEnvService, FlowUtilsService, FlowProductModelInterface, KinetixExtraModel } from '@flow/core';
import { FlowProjectService, FlowUserService } from '@flow/auth';
import { FlowTranslateService } from '@flow/translate';

import { FlowGatewayService } from '../../../../modules/gateway/services/gateway/gateway.service';
import { FlowProductSchemaInterface } from './interfaces/product-schema.interface';

@Injectable({
  providedIn: 'root'
})
export class FlowProductsService {

  private _projectExtras: KinetixExtraModel[];

  constructor(
    private EnvService: FlowEnvService,
    private ProjectService: FlowProjectService,
    private UtilsService: FlowUtilsService,
    private UserService: FlowUserService,
    private TranslateService: FlowTranslateService,
    private GatewayService: FlowGatewayService
  ) {
    this.ProjectService.getProjectExtras()
    .pipe(
      tap(extras => this._projectExtras = extras)
    )
    .subscribe();
  }

  productSchema(productObj: FlowProductModelInterface): FlowProductSchemaInterface | any {

    if (!productObj) {
      return productObj;
    }

    const productTheme = this.UtilsService.getExtra(productObj.extra, 'pageTheme', 'fieldName');
    const moduleName = FlowHelpers.slugify(productObj.name) || '';
    const solutionName = productObj.categories.length > 0 ? FlowHelpers.slugify(productObj.categories[0].name) : '';
    const hasTranslation = this.TranslateService.translateModel('flow', 'SimpleProduct', productObj.id, 'name');

    let fixTheme = 'flow';

    if (!FlowHelpers.isEmptyObject(productTheme)) {
      fixTheme = productTheme.value;
    }
    else {
      if (!productTheme) {
        if (productObj.extra['pageTheme']) {
          fixTheme = productObj.extra['pageTheme'].value;
        }
      }
    }

    return {
      acronim: FlowHelpers.acronym(productObj.name),
      id: productObj.id || 'id',
      module: moduleName,
      name: productObj.name,
      i18Name: hasTranslation ? hasTranslation : productObj.name,
      solution: solutionName,
      theme: fixTheme
    };
  }

  /**
   * 20-09-2023: ST043787 - By default admin users of TIE customers from NL (vendors and partners)
   *                        should have access to the internal shop and the "My orders" page
   * 18-04-2023: ST041997 - Defines which users of TIE Kinetix customers have access to the internal shop and the "My orders" page
   */
  hasInternalShopPermission(): boolean {
    let hasPermission = false;

    // User is of TIE Kinetix customer
    const isTieCustomer = this.UserService.isCustomer();

    // User is of Dutch customer
    const isNlCustomer = this.UserService.customer.Country.toLowerCase() === 'nl';

    // Customer is on its own domain
    const isOnOwnDomain = this.GatewayService.isVendorOnAllowedDomain;

    // User is acting as partner
    const isPartner = this.UserService.isActingAsPartner();

    // User has products
    const hasProducts = this.hasBoughtProducts();

    // User is admin
    const isAdmin = this.UserService.hasPermission([
      'portal:admin',
      'portal:module-admin',
      'portal:projectadmin',
      'portal:country-admin'
    ]);

    // Project extra 'hideInternalShopTab'
    const hideInternalShopTabExtra = this.UtilsService.getExtra(this._projectExtras, 'hideInternalShopTab', 'fieldName');

    // Projext extra 'showInternalShopTabForPartners'
    const showInternalShopTabForPartnersExtra = this.UtilsService.getExtra(this._projectExtras, 'showInternalShopTabForPartners', 'fieldName');

    // Only TIE Kinetix customers have access
    if (isTieCustomer) {

      // User is acting as partner
      if (isPartner) {
        // Base requirements
        if (hasProducts && isAdmin) {
          // Grant access by default for NL partners
          hasPermission = isNlCustomer;

          // Extra 'showInternalShopTabForPartners' is set
          if (showInternalShopTabForPartnersExtra) {
            const { value } = showInternalShopTabForPartnersExtra;

            if (value) {
              const cleanValue = value.trim().toLowerCase();

              // Explicit check for true/false value as it has different meaning based on customer country
              // ignore other values, aka use default permission per country.
              if (isNlCustomer && cleanValue === 'false') {
                hasPermission = false;
              }
              else if (!isNlCustomer && cleanValue === 'true') {
                hasPermission = true;
              }
            }
          }
        }
      }

      // User is acting as vendor
      else {
        // Base requirements
        if (hasProducts && isAdmin && isOnOwnDomain) {
          // Grant access by default for NL customers
          hasPermission = isNlCustomer;

          // Extra 'hideInternalShopTabExtra' is set
          if (hideInternalShopTabExtra) {
            const { value } = hideInternalShopTabExtra;

            if (value) {
              const cleanValue = value.trim().toLowerCase();

              // Explicit check for true/false value as it has different meaning based on customer country
              // ignore other values, aka use default permission per country.
              if (isNlCustomer && cleanValue === 'true') {
                hasPermission = false;
              }
              else if (!isNlCustomer && cleanValue === 'false') {
                hasPermission = true;
              }
            }
          }
        }
      }
    }

    /* console.warn({
      isTieCustomer,
      isNlCustomer,
      isOnOwnDomain,
      isPartner,
      hasProducts,
      isAdmin,
      hideInternalShopTabExtra,
      showInternalShopTabForPartnersExtra,
      hasPermission
    }); */

    return hasPermission;
  }

  hasBoughtProducts(): boolean {
    const { boughtProducts } = this.UserService;

    return !!(boughtProducts && boughtProducts.length);
  }
}
