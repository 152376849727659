<div class="d-flex align-items-center justify-content-between py-2 px-3 modal-title bg-primary color-white">
  <h5
    class="truncate"
    [innerHTML]="labels['dashboard.label.add_widgets']">
  </h5>
  <button
    matTooltipPosition="left"
    [matTooltip]="labels['generic.btn.close']"
    mat-dialog-close class="button button-icon button-white-outline button-no-border">
    <i class="material-icons animated rotateIn">close</i>
  </button>
</div>

<div *ngIf="loading$ | async else content" class="loading-wrapper">
  <flow-preloader [diameter]="36"></flow-preloader>
</div>

<!--<mat-tab-group-->
<!--  [disableRipple]="true"-->
<!--  [selectedIndex]="selectedIndex"-->
<!--&gt;-->
<!--  <mat-tab *ngFor="let section of sections" [label]="section.label"></mat-tab>-->
<!--</mat-tab-group>-->

<ng-template #content>
  <div class="search-form-wrapper">
    <mat-form-field [appearance]="'fill'" [formGroup]="searchForm" [floatLabel]="'always'">
      <mat-label>{{ labels['dashboard.sidebar.label.search'] }}</mat-label>
      <input matInput formControlName="search" autocomplete="off" cdkFocusInitial>
      <mat-icon
        *ngIf="!searchForm.get('search').value"
        matSuffix>
        search
      </mat-icon>
      <mat-icon
        *ngIf="searchForm.get('search').value"
        matSuffix
        (click)="searchForm.get('search').reset()"
        style="cursor: pointer;">
        close
      </mat-icon>
    </mat-form-field>
  </div>
  <mat-dialog-content>
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 list-item"
             *ngFor="let widget of visibleWidgets">
          <div class="d-flex align-items-center justify-content-start">

            <i class="material-icons">
              <ng-container [ngSwitch]="widget.type">
                <ng-container *ngSwitchCase="'chart'">bar_chart</ng-container>
                <ng-container *ngSwitchCase="'einvo'">list_alt</ng-container>
                <ng-container *ngSwitchCase="'list'">bar_chart</ng-container>
                <ng-container *ngSwitchCase="'link'">link</ng-container>
                <ng-container *ngSwitchCase="'logo'">image</ng-container>
                <ng-container *ngSwitchCase="'iframe'">code</ng-container>
                <ng-container *ngSwitchCase="'notifications'">notifications</ng-container>
                <ng-container *ngSwitchCase="'total'">assignment</ng-container>
                <ng-container *ngSwitchCase="'webcomponent'">ondemand_video</ng-container>
                <ng-container *ngSwitchCase="'youtube video'">ondemand_video</ng-container>
              </ng-container>
            </i>

            <div class="pl-3">
            <h6>
              <span [innerHTML]="widget.name"></span>
            </h6>
            <p *ngIf="widget.description" [innerHTML]="widget.description"></p>
            </div>
          </div>
          <div class="absolute-center d-flex align-items-center justify-content-center list-item-hover" (click)="addWidget(widget)">
            <button class="button button-white">
              <i class="material-icons">add</i>
              {{labels['generic.btn.add']}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </mat-dialog-content>
</ng-template>
