<flow-abs-preloader
  *ngIf="isLoading$ | async"
  [completed]="isSaved$ | async"
></flow-abs-preloader>

<ng-container *ngIf="customer$ | async as customer">
  <div id="settings-top" class="d-flex align-items-center justify-content-between p-2">
    <div class="d-flex align-items-center justify-content-start">
      <flow-gravatar
        [classNames]="['mr-2', 'user-avatar', 'bg-white', 'p-1', 'br-3']"
        [width]="36"
        [height]="36"
        [email]="customer.UserName"></flow-gravatar>
      <div id="settings-top__data" class="">
        <h5 class="fw-500 d-block">
          {{ customer.GivenName }} {{ customer.FamilyName }}
        </h5>
        <small class="d-block" [innerHTML]="customer.Email"></small>
      </div>
    </div>
    <button
      matTooltipPosition="left"
      [matTooltip]="labels['generic.btn.close']"
      (click)="dialogRef.close()"
      class="button button-icon button-white-outline button-no-border">
      <i class="material-icons animated rotateIn">close</i>
    </button>
  </div>
  <div id="settings-content" class="d-flex flex-column">
    <flow-profile-form
      *ngIf="company$ | async as company"
      [isExporting]="isExporting$$ | async"
      [countries]="countries$ | async"
      [disableDefaultBookmarkTemplate]="disableDefaultBookmarkTemplate"
      (startExport)="onExport($event)"
      (save)="doSave($event)"
      [model]="customer"
      [company]="company">
    </flow-profile-form>
  </div>
  <div id="settings-bottom" class="d-flex align-items-center justify-content-end p-2">
    <button
      [disabled]="isProfileFormInvalid"
      [innerHTML]="labels['generic.btn.save']"
      type="button"
      class="button button-primary"
      (click)="onSave()">
    </button>
  </div>
</ng-container>
