/*
* helper utility to extract/process current browser url
* */

import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { FlowEnvService } from '@flow/core';
import { FlowTokensService, FlowSsoService } from '@flow/auth';

@Injectable({
  providedIn: 'root'
})
export class FlowProjectDomainService {

  constructor(
    private EnvService: FlowEnvService,
    private TokensService: FlowTokensService,
    private SsoService: FlowSsoService,
  ) { }

  get hasVendorSubdomain() {
    return !!this.urlHostToParts().subdomain.vendor;
  }

  redirectToVendorDomain( vendor, tokens?, authenticated = true, hiddenAuthentication = false ) {
    const vendorBasedOrigin = hiddenAuthentication
      ? `${this.getVendorBasedOrigin(vendor)}/sso?productId=project`
      : this.getVendorBasedOrigin(vendor);

    this.redirectToVendorUrl( vendorBasedOrigin, authenticated, tokens );
  }

  redirectToVendorUrl( url, authenticated = true, tokens? ) {
    const redirectUrl = authenticated ? this.getAuthenticatedOrigin(url, tokens) : url;

    /* remove the tokens so user can navigate back, otherwise when navigating back he'll be logged in by token which
        would trigger the gateway logic again ending stuck in the same loop */
    this.TokensService.deleteTokens();

    setTimeout( () => document.location.href = redirectUrl ); // timeout to allow tokens removal
  }

  getAuthenticatedOrigin( origin, tokens = this.TokensService.getTokens() ) {
    const { token, refreshToken } = tokens;

    return `${ origin }/sso?token=${ token }&refreshToken=${ refreshToken }`;
  }

  getVendorBasedOrigin(vendor) {
    const parts = this.urlHostToParts();

    parts.subdomain.vendor = vendor.toLowerCase();  // this way we only change the subdomain.vendor

    return location.origin.replace( location.host, this.urlPartsToHost(parts) );
  }

  urlHostToParts() {
    const [ extension, name, subdomainEnv, subdomainVendor ] = location.host.split('.').reverse();

    return {
      subdomain: {
        vendor: subdomainVendor,
        'env-prefix': subdomainEnv
      },
      name,
      extension //incl port number
    };
  }

  urlPartsToHost( { subdomain = { vendor: '', 'env-prefix': '' }, name, extension } ) {
    return `${ subdomain.vendor ? `${ subdomain.vendor }.` : '' }${ subdomain['env-prefix'] }.${ name }.${ extension }`;
  }

  initVendorDomainAuthorization(): Observable<any> {
    return this.SsoService.initChildAppAuthorization(`${this.EnvService.get('cmsUrl')}/authorize/project`);
  }

}
