/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FlowComponentsModule, FlowDirectivesModule } from '@flow/shared';
import { FlowTranslateModule } from '@flow/translate';
import { FlowAuthModule } from '@flow/auth';

import { FlowLayoutModule } from '../layout/layout.module';

import { FlowSupportRoutingModule } from './support-routing.module';
import { FlowSupportComponent } from './containers/support.component';

@NgModule({
  imports: [
    CommonModule,
    FlowComponentsModule,
    FlowDirectivesModule,
    FlowTranslateModule,
    FlowAuthModule,
    FlowSupportRoutingModule,
    FlowLayoutModule
  ],
  declarations: [
    FlowSupportComponent
  ]
})

export class FlowSupportModule {
}
