import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FlowMarketplaceStateInterface } from '@flow/marketplaceStore';
import { LayoutModelStateInterface } from '@flow/marketplaceStore/reducers/layout.reducer';

export const getLayoutSelector = createFeatureSelector<FlowMarketplaceStateInterface, LayoutModelStateInterface>('layout');

export const getIsHelpPanelOpen = createSelector(
  getLayoutSelector,
  (state: LayoutModelStateInterface) => state.isHelpPanelOpen
);

export const getIfSolutionState = createSelector(
  getLayoutSelector,
  (state: LayoutModelStateInterface) => state.isSolutionState
);

export const getIfSolutionIframeState = createSelector(
  getLayoutSelector,
  (state: LayoutModelStateInterface) => state.isSolutionIframeState
);

export const getIfPortalThemePreviewState = createSelector(
  getLayoutSelector,
  (state: LayoutModelStateInterface) => state.isPortalThemePreviewState
);

export const getIfSavingSettings = createSelector(
  getLayoutSelector,
  (state: LayoutModelStateInterface) => state.isSavingSettings
);

export const getIfSavingDashboard = createSelector(
  getLayoutSelector,
  (state: LayoutModelStateInterface) => state.isSavingDashboard
);

export const getIfMainMenuIsVisible = createSelector(
  getLayoutSelector,
  (state: LayoutModelStateInterface) => state.isMainMenuVisible
);

export const getIfApplicationIsLoading = createSelector(
  getLayoutSelector,
  (state: LayoutModelStateInterface) => state.isApplicationLoading
);

export const getIfChatIsOpen = createSelector(
  getLayoutSelector,
  (state: LayoutModelStateInterface) => state.isChatOpen
);

export const getCurrentSolution = createSelector(
  getLayoutSelector,
  (state: LayoutModelStateInterface) => state.currentSolution
);
