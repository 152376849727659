import { Action } from '@ngrx/store';
import { FlowSsoCompanyInterface } from '@flow/auth';

export enum CompanyActionTypes {
  LoadCompany = '[FLOW Marketplace] FLOW Company LOAD',
  LoadCompanySuccess = '[FLOW Marketplace] FLOW Company LOAD SUCCESS',
  LoadCompanyFail = '[FLOW Marketplace] FLOW Company LOAD FAIL',
  CompanyLoadingStatus = '[FLOW Marketplace] FLOW Company SET LOADING STATUS',
  UnloadCompany = '[FLOW Marketplace] FLOW Company UNLOAD',
  UpdateCompany = '[FLOW Marketplace] FLOW Company Update',
  UpdateCompanyResult = '[FLOW Marketplace] FLOW Update Company result',
}

export class LoadCompany implements Action {
  readonly type = CompanyActionTypes.LoadCompany;
  constructor(public payload?: any) {}
}

export class LoadCompanySuccess implements Action {
  readonly type = CompanyActionTypes.LoadCompanySuccess;
  constructor(public payload: FlowSsoCompanyInterface) {}
}

export class LoadCompanyFail implements Action {
  readonly type = CompanyActionTypes.LoadCompanyFail;

  constructor(public payload: string) {
  }
}

export class CompanyLoadingStatus implements Action {
  readonly type = CompanyActionTypes.CompanyLoadingStatus;

  constructor(public payload: boolean) {
  }
}

export class UnloadCompany implements Action {
  readonly type = CompanyActionTypes.UnloadCompany;
}

export class UpdateCompany implements Action {
  readonly type = CompanyActionTypes.UpdateCompany;

  constructor(public payload: any) {}
}

export class UpdateCompanyResult implements Action {
  readonly type = CompanyActionTypes.UpdateCompanyResult;

  constructor(public payload: any) {}
}

export type FlowCompanyActions =
  LoadCompany |
  LoadCompanySuccess |
  LoadCompanyFail |
  CompanyLoadingStatus |
  UnloadCompany |
  UpdateCompany |
  UpdateCompanyResult;
