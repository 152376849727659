import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FlowMarketplaceStateInterface } from '@flow/marketplaceStore';
import { CustomerModelStateInterface } from '@flow/marketplaceStore/reducers/customer.reducer';

export const getCustomerSelector = createFeatureSelector<FlowMarketplaceStateInterface, CustomerModelStateInterface>('customer');

export const getIfCustomerIsLoaded = createSelector(
  getCustomerSelector,
  (state: CustomerModelStateInterface) => state.loaded
);

export const getCurrentCustomer = createSelector(
  getCustomerSelector,
  (state: CustomerModelStateInterface) => state.user
);

export const getIfCustomerIsLoading = createSelector(
  getCustomerSelector,
  (state: CustomerModelStateInterface) => state.loading
);

export const getIfCustomerSaved = createSelector(
  getCustomerSelector,
  (state: CustomerModelStateInterface) => state.saved
);
