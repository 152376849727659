/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { Component, ChangeDetectionStrategy, Input, HostBinding } from '@angular/core';

@Component({
  selector: 'flow-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlowContentComponent {
  @Input() isChatOpen: boolean;

  @HostBinding('class')
  get className() {
    return this.isChatOpen ? 'chat-opened' : null;
  }
}
