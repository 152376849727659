import {
  ActionReducerMap,
} from '@ngrx/store';

import * as fromLayout from './layout.reducer';
import * as fromProducts from './product.reducer';
import * as fromCategories from './category.reducer';
import * as fromWidgets from './widget.reducer';
import * as fromCustomer from './customer.reducer';
import * as fromCompany from './company.reducer';

export interface FlowMarketplaceStateInterface {
  categories: fromCategories.CategoryModelStateInterface;
  products: fromProducts.ProductModelStateInterface;
  layout: fromLayout.LayoutModelStateInterface;
  customer: fromCustomer.CustomerModelStateInterface;
  company: fromCompany.CompanyStateInterface;
  widgets: fromWidgets.WidgetModelStateInterface;
}

export const reducers: ActionReducerMap <FlowMarketplaceStateInterface, any> = {
  categories: fromCategories.reducer,
  products: fromProducts.reducer,
  layout: fromLayout.reducer,
  customer: fromCustomer.reducer,
  company: fromCompany.reducer,
  widgets: fromWidgets.reducer
};




