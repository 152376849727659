/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { FlowHelpers, FlowBookmarkTemplateInterface } from '@flow/core';
import { FlowUserService } from '@flow/auth';
import { FlowTranslateService, FlowTranslateLabel } from '@flow/translate';

import { FlowMarketplaceDashboardService } from '../../services/marketplace-dashboard/marketplace-dashboard.service';

@Component({
  selector: 'flow-bookmark-templates-dropdown',
  templateUrl: './bookmark-templates-dropdown.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlowBookmarkTemplatesDropdownComponent implements OnInit {

  @Input() form: UntypedFormGroup;

  @Input() controlName: string;

  @Input() inlineLabel?: string;
  @Input() inlineHint?: string;

  @Input() defaultOptionLabel?: string;

  @Input() required?: boolean;

  @Input() disableDefault: boolean;

  labels: FlowTranslateLabel;

  bookmarkTemplates: FlowBookmarkTemplateInterface[];

  constructor(
    private UserService: FlowUserService,
    private TranslateService: FlowTranslateService,
    private MarketplaceDashboardService: FlowMarketplaceDashboardService
  ) { }

  ngOnInit() {
    const dashboard = this.UserService.isActingAsPartner()
                      ? this.MarketplaceDashboardService.getProjectDashboard()
                      : this.MarketplaceDashboardService.getVendorDashboard();

    this.labels = this._translateLabels();

    this.bookmarkTemplates = FlowHelpers.hasProperty(dashboard, 'bookmarkTemplates')
                             ? dashboard.bookmarkTemplates
                             : [];

    this._setNoneAsDefault();
  }

  /**
   * Show default label in dropdown if assigned bookmark template was not found
   */
  private _setNoneAsDefault(): void {
    const selectedTemplate = this.form.get(this.controlName).value;

    if (selectedTemplate !== 'default' && this.bookmarkTemplates.findIndex(template => template.id === selectedTemplate) === -1) {
     this.form.get(this.controlName).setValue('default');
    }
  }

  /**
   * Translate necessary labels
   */
  private _translateLabels(): FlowTranslateLabel {
    const labels = [];

    if (!this.defaultOptionLabel) {
      this.defaultOptionLabel = 'account_settings.label.default';
    }

    labels.push(this.defaultOptionLabel);

    if (this.inlineLabel) {
      labels.push(this.inlineLabel);
    }

    if (this.inlineHint) {
      labels.push(this.inlineHint);
    }

    return this.TranslateService.translateSync(labels);
  }
}
