import * as fromCustomer from '../actions/customer.actions';
import { FlowSsoUserInterface } from '@flow/auth';

export interface CustomerModelStateInterface {
  loading: boolean;
  loaded: boolean;
  saved: boolean;
  user: FlowSsoUserInterface;
}

const initialState: CustomerModelStateInterface = {
  loading: false,
  loaded: false,
  saved: false,
  user: null,
};

export function reducer(
  state = initialState,
  action: fromCustomer.FlowCustomerActions
): CustomerModelStateInterface {
  switch (action.type) {

    case fromCustomer.CustomerActionTypes.LoadCustomer: {
      return {
        ...state,
        loading: !state.loaded
      };
    }

    case fromCustomer.CustomerActionTypes.LoadCustomerSuccess: {
      return {
        ...state,
        loaded: true,
        loading: false,
        user: action.payload
      };
    }

    case fromCustomer.CustomerActionTypes.LoadCustomerFail: {
      return {
        ...state,
        loaded: false,
        loading: false,
        user: null,
      };
    }

    case fromCustomer.CustomerActionTypes.CustomerLoadingStatus: {
      return {
        ...state,
        loading: action.payload
      };
    }

    case fromCustomer.CustomerActionTypes.UnloadCustomer: {
      return {
        ...state,
        loading: false,
        loaded: false,
        user: null,
      };
    }

    case fromCustomer.CustomerActionTypes.UpdateSsoUser: {
      return {
        ...state,
        loading: true,
        saved: false,
        user: action.payload
      };
    }

    case fromCustomer.CustomerActionTypes.UpdateSsoUserResult: {
      return {
        ...state,
        saved: action.payload
      };
    }

    case fromCustomer.CustomerActionTypes.SetPrivacyAgreement: {
      return {
        ...state,
        loading: true
      };
    }

    case fromCustomer.CustomerActionTypes.SetPrivacyAgreementResult: {
      return {
        ...state,
        loading: false,
        saved: true,
        user: action.payload.user
      };
    }

    default:
      return {
        ...state
      };
    break;

  }
}
