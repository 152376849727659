import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlowComponentsModule, FlowMaterialModule, FlowPipesModule, Flow2FaModule } from '@flow/shared';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlowProfileComponent } from './containers/profile/profile.component';
import { FlowProfileFormComponent } from './components/profile-form/profile-form.component';
import { FlowMarketplaceSharedModule } from '../marketplace-shared/marketplace-shared.module';
import { FlowProfileChangePasswordComponent } from './modals/profile-change-password/profile-change-password.component';

@NgModule({
  declarations: [
    FlowProfileComponent,
    FlowProfileFormComponent,
    FlowProfileChangePasswordComponent
  ],
  imports: [
    CommonModule,
    FlowComponentsModule,
    FlowPipesModule,
    FormsModule,
    ReactiveFormsModule,
    FlowMaterialModule,
    Flow2FaModule,
    FlowMarketplaceSharedModule
  ],
  providers: []
})

export class FlowMarketplaceProfileModule { }
