/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { Component, OnInit, ChangeDetectionStrategy, Input, HostBinding } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FlowTranslateLabel, FlowTranslateService } from '@flow/translate';

@Component({
  selector: 'flow-save-button',
  templateUrl: './save-button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlowSaveButtonComponent implements OnInit {

  @Input() isSaving: boolean;

  @Input() form: UntypedFormGroup;

  @Input() valid = true;

  @Input() classes: string;

  @Input() buttonClass = 'button-primary-outline';

  @HostBinding('class')
  get hostClass() {
    return this.classes;
  }

  labels: FlowTranslateLabel;

  constructor(
    private TranslateService: FlowTranslateService
  ) { }

  ngOnInit() {
    this.labels = this.TranslateService.translateSync([
      'general.btn.save',
      'general.btn.saving',
    ]);
  }
}
