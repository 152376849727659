/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, HostBinding, OnInit } from '@angular/core';

@Component({
  selector: 'flow-help-block',
  templateUrl: './help-block.component.html',
  styleUrls: ['./help-block.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlowHelpBlockComponent implements OnInit {

  @Input() id: string;
  @Input() title: string;
  @Input() image: string;
  @Input() buttonTitle: string;

  @Output() clickEvent: EventEmitter <void> = new EventEmitter();

  @HostBinding('class') classes = 'd-block block-inner text-center py-3';

  ngOnInit(): void {
    console.log(this);
  }
}
