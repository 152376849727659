import { Injectable } from '@angular/core';


import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { FlowUserService } from '@flow/auth';
import { FlowGoogleStorageService, FlowEnvService } from '@flow/core';

import {
  FlowMarketplaceDashboardService
} from '../modules/marketplace-shared/services/marketplace-dashboard/marketplace-dashboard.service';


@Injectable()
export class FlowDashboardsGuard  {
  constructor(
    private GoogleStorageService: FlowGoogleStorageService,
    private EnvService: FlowEnvService,
    private UserService: FlowUserService,
    private MarketplaceDashboardService: FlowMarketplaceDashboardService
  ) {}

  canActivate(): Observable <boolean> {
    if (!this.UserService.isActingAsPartner()) {
      // If a user is a vendor then he can have his own user dashboard (setUserDashboard)
      // and a vendor (company) dashboard (setVendorDashboard)
      return forkJoin([
        this.GoogleStorageService.getVendorDashboard(this.UserService.company),
        this.GoogleStorageService.getUserDashboard(this.UserService.username, this.UserService.company)
      ]).pipe(
        map(results => {
          this.MarketplaceDashboardService.setUserDashboard(this.parseWidgets(results[1]));
          this.MarketplaceDashboardService.setVendorDashboard(this.parseWidgets(results[0]));
          return true;
        })
      );
    }
    else {
      // If a user is a partner then he can have his own user dashboard (setUserDashboard),
      // his own vendor (company) dashboard (setVendorDashboard)
      // and the vendor (company) dashboard of his vendor (setProjectDashboard)
      return forkJoin([
        this.GoogleStorageService.getVendorDashboard(this.UserService.company),
        this.GoogleStorageService.getUserDashboard(this.UserService.username, this.UserService.company),
        this.GoogleStorageService.getVendorDashboard(this.EnvService.portalDomain),
      ]).pipe(
        map(results => {
          this.MarketplaceDashboardService.setUserDashboard(this.parseWidgets(results[1]));
          this.MarketplaceDashboardService.setVendorDashboard(this.parseWidgets(results[0]));
          this.MarketplaceDashboardService.setProjectDashboard(this.parseWidgets(results[2]));
          return true;
        })
      );
    }
  }

  parseWidgets<T>(dashboard): T {
    const parsedWidgets = dashboard.widgets.map(widget => ({
        ...widget,
        cols: widget.cols || widget.sizeX || 1,
        rows: widget.rows || widget.sizeY || 1,
        x: widget.x || widget.col || 0,
        y:  widget.y || widget.row || 0,
      }));

    return {
      ...dashboard,
      widgets: parsedWidgets
    };
  }

}
