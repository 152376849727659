/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import {
  Component,
  OnInit,
  OnDestroy,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter
} from '@angular/core';

import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
  ValidatorFn,
  ValidationErrors
} from '@angular/forms';

import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { FlowHelpers, FlowUtilsService, FlowRegexService } from '@flow/core';
import { FlowSsoUserInterface, FlowUserService, FlowUserInterface } from '@flow/auth';
import { FlowTranslateLabel, FlowTranslateService } from '@flow/translate';
import { Flow2faService } from '@flow/shared';

@Component({
  selector: 'flow-enforced-2fa-form',
  templateUrl: './enforced-2fa-form.component.html',
  styleUrls: ['./enforced-2fa-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlowEnforced2FaFormComponent implements OnInit, OnDestroy {

  @Input() model: FlowSsoUserInterface;
  @Input() allowed2FaConfig: any;
  @Input() isSaved: boolean;

  @Output() save: EventEmitter <boolean> = new EventEmitter();

  labels: FlowTranslateLabel;

  form: UntypedFormGroup;

  user: FlowUserInterface;

  tfa: any;
  allowed2Fa: any;
  selected2Fa: any;

  get recoveryEmail(): UntypedFormControl {
    return this.form.get('recoveryEmail') as UntypedFormControl;
  }

  get phoneNumber(): UntypedFormControl {
    return this.form.get('phoneNumber') as UntypedFormControl;
  }

  private readonly _TFA = this.TwoFaService.tfaMappings;
  private readonly _TFA_LABELS = this.TwoFaService.tfaLabels;
  private readonly _DEFAULT_TFA = { id: this._TFA.googleAuthenticator, label: this._TFA_LABELS.googleAuthenticator };

  private _phoneNumberChangedSubscription$$: Subscription;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private UtilsService: FlowUtilsService,
    private TranslateService: FlowTranslateService,
    private UserService: FlowUserService,
    private RegexService: FlowRegexService,
    private TwoFaService: Flow2faService
  ) { }

  ngOnInit() {
    this.tfa = this._TFA;
    this.allowed2Fa = [this._DEFAULT_TFA];

    this.labels = this._translateLabels();

    if (this.allowed2FaConfig) {
      const { value } = this.allowed2FaConfig;

      if (value) {
        const allowed2FaParts = value.split(',');

        // clear previous options if any
        this.allowed2Fa = [];

        allowed2FaParts.forEach(tfa => {
          switch (tfa.trim().toLowerCase()) {
            case 'google':
              this.allowed2Fa.push({ id: this._TFA.googleAuthenticator, label: this._TFA_LABELS.googleAuthenticator });
              break;
            case 'sms':
              this.allowed2Fa.push({ id: this._TFA.smsAuthenticator, label: this._TFA_LABELS.smsAuthenticator });
              break;
            case 'yubico':
              this.allowed2Fa.push({ id: this._TFA.yubico, label: this._TFA_LABELS.yubico });
              break;

            default:
              break;
          }
        });

        // set default tfa in case of wrong configuration
        if (FlowHelpers.isEmptyArray(this.allowed2Fa)) {
          this.allowed2Fa = [this._DEFAULT_TFA];
        }
      }
    }

    // if there is only one allowed 2FA type then this will be the selected one
    if (this.allowed2Fa.length === 1) {
      this.selected2Fa = this.allowed2Fa[0].id;
    }

    // create the form
    this._createForm();

    // Remove whitespaces from phone number field
    this._phoneNumberChangedSubscription$$ = this.phoneNumber.valueChanges.pipe(
      debounceTime(100),
      distinctUntilChanged()
    ).subscribe(value => {
      this.phoneNumber.setValue(value.replace(/\s/g,''));
    });
  }

  ngOnDestroy(): void {
    this._phoneNumberChangedSubscription$$.unsubscribe();
  }

  /**
   * Add or remove validator for phone number field
   * depending on selected 2FA type
   */
  onChange2FaType(value): void {
    if (value === this._TFA.smsAuthenticator) {
      this.phoneNumber.setValidators([Validators.required, Validators.pattern(/^\+\d{7,14}$/)]);
    }
    else {
      this.phoneNumber.clearValidators();
    }

    this.phoneNumber.updateValueAndValidity();
  }


  /**
   * Trigger "Go back"
   */
  onBack(): void {
    this.isSaved = false;
  }

  /**
   * Save 2fa related data to user profile
   */
  onSubmit(): void {
    this.save.emit(this.form.value);
  }

  /**
   * Page reload after confirming 2Fa
   */
  confirm2Fa(confirmed: boolean): void {
    if (confirmed) {
      window.location.reload();
    }
  }

  /**
   * Create the form
   */
  private _createForm(): void {
    const trustDevices = this.UtilsService.getExtraValue(this.model.ExtendedProperties, 'trustDevices', 'PropertyName');
    const recoveryEmail = this.UtilsService.getExtraValue(this.model.ExtendedProperties, 'FlowRecoveryEmail', 'PropertyName');
    const { PhoneNumber } = this.model;

    this.form = this.formBuilder.group({
      recoveryEmail: [recoveryEmail ? atob(recoveryEmail) : '', [Validators.required, this._validateRecoveryEmail()]],
      trustDevices: [trustDevices],
      phoneNumber: [''],
    });

    if (!PhoneNumber) {
      if (this.selected2Fa === this._TFA.smsAuthenticator) {
        this.phoneNumber.setValue('+');
      }
    }
    else {
      if (!PhoneNumber.startsWith('+')) {
        this.phoneNumber.setValue(`+${PhoneNumber}`);
      }
      else {
        this.phoneNumber.setValue(PhoneNumber);
      }
    }

    // Add validators to phone number field if allowed2fa is sms
    if (this.allowed2Fa.length === 1 && this.selected2Fa === this._TFA.smsAuthenticator) {
      this.onChange2FaType(this.selected2Fa);
    }
  }

  /**
   * Custom validator for recovery email field
   */
  private _validateRecoveryEmail(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const userEmail = this.UserService.user.email;
      const value = control.value;
      const regExp = this.RegexService.getRegex('email');

      if (!value) {
        return null;
      }

      if (regExp.test(value)) {
        if (value === userEmail) {
          return { 'recoveryEmailMatchesUserEmail': true };
        }
        else {
          return null;
        }
      }
      else {
        return { 'invalidRecoveryEmail': true };
      }
    };
  }

  /**
   * Translate labels
   */
  private _translateLabels(): FlowTranslateLabel {
    return this.TranslateService.translateSync([
      'account_settings.tfa.subtitle.single',
      'account_settings.tfa.subtitle.multiple',
      'account_settings.label.authentication_type',
      'account_settings.label.recovery_email',
      'account_settings.help.recovery_email',
      'account_settings.error.invalid.recovery_email',
      'generic.error.invalid.email',
      'account_settings.label.trust_devices',
      'account_settings.tfa.help.trust_devices',
      'account_settings.label.phone',
      'account_settings.tfa.help.phone',
      'account_settings.label.error.invalid.phone',
      'account_settings.button.register_google_authenticator_app',
      'account_settings.button.send_tfa_verification_sms',
      'account_settings.tfa.instruction',
      'generic.error.required',
      'general.btn.save',
      'general.btn.back'
    ]);
  }
}
