import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';

import { Observable } from 'rxjs';
import { filter, take, tap } from 'rxjs/operators';

import { FlowHelpers, FlowRouterService } from '@flow/core';
import { FlowSsoUserInterface } from '@flow/auth';
import { FlowTranslateLabel, FlowTranslateService } from '@flow/translate';
import {
  CustomerLoadingStatus,
  FlowMarketplaceStateInterface,
  getCurrentCustomer,
  getIfCustomerIsLoading,
  getIfCustomerSaved,
  LoadCustomer,
  UpdateSsoUser
} from '@flow/marketplaceStore';

@Component({
  selector: 'flow-enforced-2fa',
  templateUrl: './enforced-2fa.component.html',
  styleUrls: ['./enforced-2fa.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlowEnforced2FaComponent implements OnInit {

  customer$: Observable<FlowSsoUserInterface>;
  isLoading$: Observable<boolean>;
  isSaved$: Observable<boolean>;

  labels: FlowTranslateLabel;

  allowed2Fa: string;

  private _model: FlowSsoUserInterface;

  constructor(
    private store: Store <FlowMarketplaceStateInterface>,
    private RouterService: FlowRouterService,
    private TranslateService: FlowTranslateService
  ) {
    this.allowed2Fa = this.RouterService.getPropertyFromNavigationExtras('allowed2Fa');
  }

  ngOnInit() {
    this.labels = this._translateLabels();

    this.store.dispatch(new LoadCustomer());

    this.isSaved$ = this.store.pipe(
      select(getIfCustomerSaved),
      tap(status => {
        if (true === status) {
          setTimeout(() => this.store.dispatch(new CustomerLoadingStatus(false)), 1000);
        }
      })
    );

    this.isLoading$ = this.store.pipe(select(getIfCustomerIsLoading));

    this.customer$ = this.store.pipe(
      select(getCurrentCustomer),
      filter(customer => FlowHelpers.isObject(customer)),
      take(1),
      tap(customer => this. _model = customer)
    );
  }

  doSave($event: any): void {
    this.onSave($event);
  }

  onSave(formValue: any): void {
    const clonedModel = FlowHelpers.deepClone(this._model);
    const extra = clonedModel.ExtendedProperties;
    const trustDevicesIndex = extra.findIndex(prop => prop.PropertyName === 'trustDevices');
    const FlowRecoveryEmailIndex = extra.findIndex(prop => prop.PropertyName === 'FlowRecoveryEmail');
    const FlowTwoFactorAuthenticationIndex = extra.findIndex(prop => prop.PropertyName === 'FlowTwoFactorAuthentication');
    const { phoneNumber } = formValue;

    if (trustDevicesIndex === -1) {
      extra.push({
        PropertyName: 'trustDevices',
        Value: String(formValue.trustDevices)
      });
    }
    else {
      extra[trustDevicesIndex].Value = String(formValue.trustDevices);
    }

    if (FlowRecoveryEmailIndex === -1) {
      extra.push({
        PropertyName: 'FlowRecoveryEmail',
        Value: String(btoa(formValue.recoveryEmail))
      });
    }
    else {
      extra[FlowRecoveryEmailIndex].Value = String(btoa(formValue.recoveryEmail));
    }

    if (FlowTwoFactorAuthenticationIndex === -1) {
      extra.push({
        PropertyName: 'FlowTwoFactorAuthentication',
        Value: 'true'
      });
    }
    else {
      extra[FlowTwoFactorAuthenticationIndex].Value = 'true';
    }

    if (phoneNumber) {
      clonedModel['PhoneNumber'] = String(phoneNumber);
    }

    this.store.dispatch(new UpdateSsoUser(clonedModel));
  }

  private _translateLabels(): FlowTranslateLabel {
    return this.TranslateService.translateSync([
      'general.label.logout',
      'account_settings.label.two_factor_authentication',
      'error.general.error_loading'
    ]);
  }
}
