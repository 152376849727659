/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Observable, of } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';

import { FlowCmsService, FlowEnvService, FlowModelsService } from '@flow/core';
import { FlowSsoService } from '@flow/auth';
import { FlowTranslateLabel, FlowTranslateService } from '@flow/translate';

@Component({
  selector: 'flow-einvo-modal',
  templateUrl: './einvo-modal.component.html',
  styleUrls: ['./einvo-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlowEinvoModalComponent implements OnInit {

  dialogData: any;

  labels: FlowTranslateLabel;

  validatorLink$: Observable<string>;
  isLoading = true;

  iframeHeight: string;

  private _productTokenLinkUrl: string;
  private _productTokenLinkUrlForAuth: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private CmsService: FlowCmsService,
    private EnvService: FlowEnvService,
    private ModelsService: FlowModelsService,
    private SsoService: FlowSsoService,
    private TranslateService: FlowTranslateService
  ) { }

  ngOnInit(): void {
    this._productTokenLinkUrl = this.SsoService.getProductTokenLink(this.data.solution);

    this.labels = this._translateLabels();

    this.iframeHeight = `${window.screen.height * 0.7}px`;

    this.validatorLink$ = this._getEinvoDeepLink(this.data.path)
     .pipe(
       map(shareLink => {
        if (shareLink) {
          let useNewTokenSystem = false;

          // Check if product has a productTokenLinkUrl
          if (this._productTokenLinkUrl) {

            // Check productTokenLink extras
            const productTokenLinkAttr = this.data.solution.attrs.find(attr => attr.id === 'productTokenLink');
            const { extra } = productTokenLinkAttr;

            const productTokenLinkExtraUrls = extra ? extra.map(item => item.value) : [];

            // Merge productTokenLink URLs for check
            const tokenLinkUrlsToCheck = [ this._productTokenLinkUrl, ...productTokenLinkExtraUrls ];

            // Check if shareLink host is in list of productTokenLink hosts
            const foundUrl = tokenLinkUrlsToCheck.find(url => new URL(url).hostname === new URL(shareLink).hostname);

            // If match -> use new token system with matched URL for authentication
            if (foundUrl) {
              useNewTokenSystem = true;
              this._productTokenLinkUrlForAuth = foundUrl;
            }
            else {
               useNewTokenSystem = false;
            }
          }
          // Use old token system for products without productTokenLinkUrl
          else {
            useNewTokenSystem = false;
          }

           return useNewTokenSystem ? 'true' : shareLink;
         }
         else {
           return shareLink;
         }
       }),
       switchMap(result => {
         // Use new token system
         if (result === 'true') {
           return this.SsoService.initChildAppAuthorization(this._productTokenLinkUrlForAuth)
           .pipe(
             switchMap(result => result ? this._getEinvoDeepLink(this.data.path) : of(result))
           );
         }
         // Use old token system (return shareLink with tokens)
         else {
           return of(result);
         }
       }),
       map(result => {
        if (result) {
          // Only encode parameters for old token system
          if (result.includes('flowsso')) {
            // Encode parameters substring
            const separator = '&parameters=';
            const parts = result.split(separator);

            if (parts.length > 1) {
              return `${parts[0]}${separator}${encodeURIComponent(parts[1])}`;
            }

            return result;
          }
          else {
            return result;
          }
        }
        else {
          this.isLoading = false;
          return 'loadError';
        }
       })
     );

    /* this.validatorLink$ = this.SsoService.getEinvoDeepLink(this.data.path)
    .pipe(
      map(link => {
        // Encode parameters substring
        const separator = '&parameters=',
              parts = link.split(separator);

        if (parts.length > 1) {
          return `${parts[0]}${separator}${encodeURIComponent(parts[1])}`;
        }

        return link;
      })
    ); */
  }

  /**
   * When iframe has loaded, hide loading indicator
   */
  onLoad() {
    this.isLoading = false;
  }

  /**
   * Returns full einvo deep link based on given path
   */
  private _getEinvoDeepLink(path: string, args?: any): Observable<string> {
    if (!path) {
      throw new ReferenceError(`Path was not supplied.`);
    }

    const defaults = {
      isPartner: false,
      redirect: false,
      pid: false,
      urlQueryParams: {}
    };

    args = Object.assign(defaults, args);

    return this.SsoService.createProductLink('einvo', args)
    .pipe(
      map(link => new URL(link)),
      switchMap(link => {
        let mode = '&mode=accept';

        let encodedLink;

        if (this.EnvService.isAcceptance || this.EnvService.isPreAccept) {
          mode = '&mode=test';
        }
        else if (this.EnvService.isProduction) {
          mode = '';
        }

        // Old token system -> use path as it is provided
        // e.g. page=ContactInfo&view=Index&parameters=expand=companyinfo&hidesidebar=true
        if (link.pathname.includes('flowsso')) {
          encodedLink = encodeURIComponent(
            link.protocol + '//' + link.hostname + link.pathname +
            '?accessToken={{accessToken}}&refreshToken={{refreshToken}}' +
            mode +
            '&' + path
          );
        }
        // New token system -> use path without page param
        // e.g. ContactInfo&view=Index&parameters=expand=companyinfo&hidesidebar=true
        else {
          const newPath = path.replace('page=','').replace('&', '?');

          const queryParams = link.search.replace('?', '&');

          const url = link.protocol + '//' + link.hostname + '/' + newPath + queryParams + mode;

          encodedLink = encodeURIComponent(url);
        }

        return this.CmsService
        .get<string>(`${this.ModelsService.getModel('ShareLink')}?link=${encodedLink}`, {}, {}, {responseType: 'text'});
      }),
      catchError(() => of(null))
    );
  }

  /**
   * Translate necessary labels
   */
  private _translateLabels(): FlowTranslateLabel {
    return this.TranslateService.translateSync(['general.btn.close']);
  }
}
