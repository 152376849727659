<mat-form-field [formGroup]="form">
  <mat-label *ngIf="inlineLabel">{{ labels[inlineLabel] }}</mat-label>
  <mat-select
    [formControlName]="controlName"
    [required]="required">
    <mat-option
      value="default"
      [disabled]="disableDefault">
      {{ labels[defaultOptionLabel] }}
    </mat-option>
    <mat-option
      *ngFor="let template of bookmarkTemplates"
      [value]="template.id">
      {{ template.title }}
    </mat-option>
  </mat-select>
  <mat-hint
    *ngIf="inlineHint"
    [innerHTML]="labels[inlineHint]">
  ></mat-hint>
</mat-form-field>
