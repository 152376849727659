/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { FlowChatService } from '../../services/chat/chat.service';
import { FlowWebsocketService } from '@flow/shared';
import { filter, first, map } from 'rxjs/operators';
import { FlowTranslateLabel, FlowTranslateService } from '@flow/translate';
import { FlowChatResultUserInterface } from '../../interfaces/chat-search-result.interface';

@Component({
  selector: 'flow-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlowChatComponent implements OnInit, OnDestroy {

  conversations$: Observable <any[]>;

  onlineUsersInterval: any;

  onlineUsers$: Observable <any>;

  subscription$: Subscription = new Subscription();

  labels: FlowTranslateLabel;

  currentConversation: any;

  selectedIndex = 0;

  @Input() isVisible: boolean;

  @Output() close: EventEmitter <void> = new EventEmitter();

  constructor(
    private ChatService: FlowChatService,
    private WebsocketService: FlowWebsocketService,
    private TranslateService: FlowTranslateService
  ) { }

  ngOnInit(): void {
    this.labels = this.TranslateService.translateSync([
      'general.btn.close',
      'chat.label.chats',
      'chat.label.search_companies'
    ]);

    this.conversations$ = this.ChatService.getOpenConversations();

    this.subscription$ = this.WebsocketService.waitForConnection$.pipe(first()).subscribe(() => {
      this.requestOnlineUsers();
      this.setOnlineUsersInterval();
    });

    this.onlineUsers$ = this
      .WebsocketService
      .feed$
      .pipe(
        filter(message => message.topic === 'chat' && message.subject === 'user_list'),
        map(message => message.message.length
            ? message.message.map(user => user._id)
            : [])
      );
  }

  setOnlineUsersInterval(): void {
    this.onlineUsersInterval = setInterval(() => this.requestOnlineUsers(), 30000);
  }

  cancelOnlineUsersInterval(): void {
    if (!this.onlineUsersInterval) {
      return;
    }

    clearInterval(this.onlineUsersInterval);
  }

  ngOnDestroy(): void {
    this.cancelOnlineUsersInterval();
    this.subscription$.unsubscribe();
  }

  requestOnlineUsers(): void {
    this.WebsocketService.send({
      subject: 'user_list',
      topic: 'chat',
    });
  }

  startConversation($event: FlowChatResultUserInterface): void {
    this.selectedIndex = 0;
    this.WebsocketService.send({
      topic: 'chat',
      subject: 'hash',
      message: {
        role: $event.roles
      }
    });
  }
}
