import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlowChatComponent } from './containers/chat/chat.component';
import { FlowComponentsModule, FlowMaterialModule, FlowPipesModule } from '@flow/shared';
import { FlowChatSearchComponent } from './components/chat-search/chat-search.component';
import { ReactiveFormsModule } from '@angular/forms';
import { FlowChatService } from './services/chat/chat.service';
import { FlowChatConversationComponent } from './components/chat-conversation/chat-conversation.component';
import { FlowChatConversationsComponent } from './components/chat-conversations/chat-conversations.component';
import { FlowChatBoxComponent } from './components/chat-box/chat-box.component';
import { FlowChatBoxMessageComponent } from './components/chat-box-message/chat-box-message.component';

@NgModule({
  declarations: [
    FlowChatComponent,
    FlowChatSearchComponent,
    FlowChatConversationsComponent,
    FlowChatConversationComponent,
    FlowChatBoxComponent,
    FlowChatBoxMessageComponent
  ],
  imports: [
    CommonModule,
    FlowMaterialModule,
    FlowPipesModule,
    ReactiveFormsModule,
    FlowComponentsModule
  ],
  exports: [
    FlowChatComponent
  ],
  providers: [
    FlowChatService
  ]
})
export class FlowChatModule { }
