<div class="search-form-wrapper">
  <mat-form-field [appearance]="'fill'" [formGroup]="searchForm" [floatLabel]="'always'">
    <mat-label>{{ labels['chat.label.search_companies'] }}</mat-label>
    <input matInput type="search" formControlName="search">
    <flow-preloader matSuffix [diameter]="24" *ngIf="isSearching$ | async"></flow-preloader>
  </mat-form-field>
</div>
<div class="full-height full-height-flex">
  <ng-container *ngIf="results$ | async as results">
    <ng-container *ngIf="results else noResults">
      <mat-accordion [multi]="true" [displayMode]="'flat'">
        <mat-expansion-panel *ngFor="let result of results" [disabled]="result.usersCount === 0">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div>
                {{ result.companyName }}
                <small class="d-block">{{ result.usersCount }} users</small>
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <ng-template matExpansionPanelContent>
            <ul>
              <li *ngFor="let user of result.users" class="list-item">
                <flow-gravatar
                  [classNames]="['mr-2', 'user-avatar', 'bg-white', 'p-1', 'br-3']"
                  [width]="36"
                  [height]="36"
                  [email]="user.email"></flow-gravatar>
                <div class="d-flex flex-column align-items-start chat-search-result-user">
                  <small class="color-infotext">{{ labels['chat.label.scope'] }}:</small>
                <ng-container *ngIf="user.roles.length else userRole">
                  <span *ngFor="let role of user.roles" [innerHTML]="role | uppercaseToWords"></span>
                </ng-container>
                <ng-template #userRole>
                  {{labels['chat.label.general']}}
                </ng-template>
                </div>
                <div class="absolute-center d-flex align-items-center justify-content-center list-item-hover" (click)="startConversation(user)">
                  <button class="button button-white">
                    <i class="material-icons">add</i>
                    {{labels['chat.label.start_conversation']}}
                  </button>
                </div>
              </li>
            </ul>
          </ng-template>
        </mat-expansion-panel>
      </mat-accordion>
    </ng-container>

    <ng-template #noResults>
      <div class="absolute-center  d-flex align-items-center justify-content-center">
      No results.
      </div>
    </ng-template>

  </ng-container>
</div>
