<div class="d-block">
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="row">
      <div class="col-12 col-md-6">
        <mat-form-field [appearance]="'fill'" [color]="'primary-i-30'">
          <mat-label>Your name</mat-label>
          <input type="text" matInput formControlName="name">
        </mat-form-field>
      </div>
      <div class="col-12 col-md-6">
        <mat-form-field [appearance]="'fill'" [color]="'primary-i-30'">
          <mat-label>Your email address</mat-label>
          <input type="text" matInput formControlName="email">
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <mat-form-field [appearance]="'fill'" [color]="'primary-i-30'">
          <mat-label>How can we help you?</mat-label>
          <textarea matInput formControlNAame="message"></textarea>
        </mat-form-field>
      </div>
    </div>
    <div class="d-block text-right">
      <button type="button" class="button button-flat button-rounded button-small mr-2" (click)="goBack.emit()">Cancel</button>
      <button class="button button-white button-rounded button-small">Send message</button>
    </div>
  </form>
</div>
