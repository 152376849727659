import { Action } from '@ngrx/store';

export enum LayoutActionTypes {
  ToggleHelpPanel = '[FLOW Marketplace] Toggle help panel.',
  ToggleSolutionState = '[FLOW Marketplace] Toggle if we are in a solution state.',
  ToggleSolutionIframeState = '[FLOW Marketplace] Toggle if we are in an iframe solution state.',
  TogglePortalThemePreviewState = '[FLOW Marketplace] Toggle if we are in a portal theme preview state.',
  SetCurrentSolution = '[FLOW Marketplace] Set actual solution.',
  ToggleMainMenu = '[FLOW Marketplace] Toggle the main menu visibility.',
  LayoutLogoutEvent = '[FLOW Marketplace] On Layout Logout Event.',
  SetIfSavingDashboard = '[FLOW Marketplace] Set the dashboard saving status.',
  IsApplicationLoading = '[FLOW Marketplace] If the application is loading',
  SetIfChatIsOpen = '[FLOW Marketplace] Set if the chat is visible'
}

export class ToggleHelpPanel implements Action {
  readonly type = LayoutActionTypes.ToggleHelpPanel;
}

export class ToggleSolutionState implements Action {
  readonly type = LayoutActionTypes.ToggleSolutionState;
  constructor(public payload: boolean) {}
}

export class ToggleSolutionIframeState implements Action {
  readonly type = LayoutActionTypes.ToggleSolutionIframeState;
  constructor(public payload: boolean) {}
}

export class TogglePortalThemePreviewState implements Action {
  readonly type = LayoutActionTypes.TogglePortalThemePreviewState;
  constructor(public payload: boolean) {}
}

export class SetCurrentSolution implements Action {
  readonly type = LayoutActionTypes.SetCurrentSolution;
  constructor(public payload: string) {}
}

export class ToggleMainMenu implements Action {
  readonly type = LayoutActionTypes.ToggleMainMenu;
  constructor(public payload?: boolean) {}
}

export class LayoutLogoutEvent implements Action {
  readonly type = LayoutActionTypes.LayoutLogoutEvent;
}

export class IsApplicationLoading implements Action {
  readonly type = LayoutActionTypes.IsApplicationLoading;
  constructor(public payload: boolean) {}
}

export class SetIfSavingDashboard implements Action {
  readonly type = LayoutActionTypes.SetIfSavingDashboard;
  constructor(public payload: boolean) {}
}

export class SetIfChatIsOpen implements Action {
  readonly type = LayoutActionTypes.SetIfChatIsOpen;
}

export type FlowLayoutActions = ToggleHelpPanel |
  ToggleSolutionState |
  ToggleSolutionIframeState |
  TogglePortalThemePreviewState |
  SetCurrentSolution |
  ToggleMainMenu |
  LayoutLogoutEvent |
  IsApplicationLoading |
  SetIfSavingDashboard |
  SetIfChatIsOpen;
