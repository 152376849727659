/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

/*
NOTE:
    with the exception of the "changeDetectorRef.detectChanges" and "_gotoHome", this file is an exact copy of
    profile-change-password.component.ts.
    The relate template is also a copy, but then without any mat-dialog logic
*/

import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { FlowSsoService, FlowUserService } from '@flow/auth';
import {
  passwordNoPersonalInfoValidator,
  passwordSameAsOldValidator,
  passwordsMatchValidator,
  passwordStrengthValidator,
  rulesRegex
} from '@flow/shared';
import { FlowTranslateLabel, FlowTranslateService } from '@flow/translate';
import { FlowRouterService } from '@flow/core';
import { FlowPasswordExpiredService } from '../../services/password-expired/password-expired.service';

@Component({
  selector: 'flow-password-expired-form',
  templateUrl: './password-expired-form.component.html',
  styleUrls: ['./password-expired-form.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FlowPasswordExpiredFormComponent implements OnInit {

  form: UntypedFormGroup;

  labels: FlowTranslateLabel;

  saving: boolean;

  saved: boolean;

  saveError: boolean;

  unknownPassword = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private changeDetectorRef: ChangeDetectorRef,
    private router: FlowRouterService,
    private SsoService: FlowSsoService,
    private UserService: FlowUserService,
    private TranslateService: FlowTranslateService,
    private PasswordExpiredService: FlowPasswordExpiredService,
  ) {}

  ngOnInit(): void {
    this.form = this.formBuilder.group(
      {
        customerId: new UntypedFormControl(this.UserService.company, [Validators.required]),
        userId: new UntypedFormControl(this.UserService.username, [Validators.required]),
        currentPassword: new UntypedFormControl('', [
          Validators.required,
          passwordStrengthValidator(),
        ]),
        newPassword: new UntypedFormControl('', [
          Validators.required,
          passwordStrengthValidator(),
          passwordNoPersonalInfoValidator(this.UserService.user),
        ]),
        repeatNewPassword: new UntypedFormControl('', [
          Validators.required,
        ]),
        token: new UntypedFormControl(''),
      },
      {
        validators: [
          passwordSameAsOldValidator,
          passwordsMatchValidator,
        ]
      });

    this.labels = this.TranslateService.translateSync([
      'general.password_requirements',
      'general.password_requirements.title',
      'general.password_requirements.rules.length',
      'general.password_requirements.rules.upper_lower_chars',
      'general.password_requirements.rules.numbers',
      'general.password_requirements.rules.special_chars',
      'general.password_requirements.rules.no_personal_info',
      'account_settings.label.current_password',
      'account_settings.label.new_password',
      'account_settings.label.repeat_password',
      'account_settings.btn.change_password',
      'account_settings.label.change_account_password',
      'msg.updated.password',
      'general.btn.close',
      'generic.error.required',
      'generic.error.password_has_personal_info',
      'generic.error.password_strength',
      'generic.error.password_mismatch',
      'generic.error.password_unknown',
      'generic.error.same_current_password',
      'general.error.request_error'
    ]);
  }

  passwordConformsTo(rule): boolean {
    let valid = false;
    const ctrl = this.form.get('newPassword');
    const password = ctrl.value;

    if (password) {
      valid = rule === 'noPersonalInfo'
        ? !ctrl.hasError('passwordPersonal')
        : rulesRegex[rule].test(password);
    }

    return valid;
  }

  changePassword(): void {
    this.saving = true;

    this.SsoService.changePassword(this.form.value, { skipAuth: false }).subscribe(result => {

      // Everything went well
      if (result === true) {
        this.unknownPassword = false;
        this.saveError = false;
        this.saving = false;
        this.saved = true;

        setTimeout( this._gotoHome.bind(this), 2000);
      }
      // Unknown current password
      else if (result instanceof HttpErrorResponse && result.status === 406) {
        this.saving = false;
        this.unknownPassword = true;
        this.form.get('currentPassword').setValue('');
      }
      else {
        this.unknownPassword = false;
        this.saveError = true;
        this.saving = false;
        this.saved = true;
      }

      setTimeout( () => this.changeDetectorRef.detectChanges() );
    });
  }

  private _gotoHome() {
    /* NOTE:
          The extra's are bound to the session, so refresh will only giv u the old (cached) values, which in turn will
          keep prompting the user to change his password because 'LastPasswordUpdatedDate' extra will only update
          after a logout/login.
          Deleting the token will trigger a new auth token/session and update the extras, but this only works for a
          normal user and does not work when impersonating. Therefore we use a localStorage approach instead.
     */
    this.PasswordExpiredService.storagePasswordExpiredDate = Date.now();

    this.router.navigate(['/']);
  }
}

