import * as fromLayout from '../actions/layout.actions';

export interface LayoutModelStateInterface {
  isHelpPanelOpen: boolean;
  isSolutionState: boolean;
  isSolutionIframeState: boolean;
  isPortalThemePreviewState: boolean;
  currentSolution: string;
  isMainMenuVisible: boolean;
  isSavingSettings: boolean;
  isSavingDashboard: boolean;
  isApplicationLoading: boolean;
  isChatOpen: boolean;
}

const initialState: LayoutModelStateInterface = {
  isHelpPanelOpen: false,
  currentSolution: 'portal',
  isSolutionState: false,
  isSolutionIframeState: false,
  isPortalThemePreviewState: false,
  isMainMenuVisible: true,
  isSavingDashboard: false,
  isSavingSettings: false,
  isApplicationLoading: true,
  isChatOpen: false
};

export function reducer(
  state = initialState,
  action: fromLayout.FlowLayoutActions
): LayoutModelStateInterface {

  switch (action.type) {
    case fromLayout.LayoutActionTypes.ToggleHelpPanel: {
      return {
        ...state,
        isHelpPanelOpen: !state.isHelpPanelOpen
      };
    }

    case fromLayout.LayoutActionTypes.ToggleSolutionState: {
      return {
        ...state,
        isSolutionState: action.payload
      };
    }

    case fromLayout.LayoutActionTypes.ToggleSolutionIframeState: {
      return {
        ...state,
        isSolutionIframeState: action.payload
      };
    }

    case fromLayout.LayoutActionTypes.TogglePortalThemePreviewState: {
      return {
        ...state,
        isPortalThemePreviewState: action.payload
      };
    }

    case fromLayout.LayoutActionTypes.SetCurrentSolution: {
      return {
        ...state,
        currentSolution: action.payload
      };
    }

    case fromLayout.LayoutActionTypes.ToggleMainMenu: {
      return {
        ...state,
        isMainMenuVisible: action.payload || !state.isMainMenuVisible
      };
    }

    case fromLayout.LayoutActionTypes.LayoutLogoutEvent: {
      return {
        ...state,
        isHelpPanelOpen: false,
        isSolutionState: false,
        isSolutionIframeState: false,
        isPortalThemePreviewState: false,
        isMainMenuVisible: true,
        isSavingDashboard: false,
        isSavingSettings: false,
        isApplicationLoading: true,
        isChatOpen: false
      };
    }

    case fromLayout.LayoutActionTypes.IsApplicationLoading: {
      return {
        ...state,
        isApplicationLoading: action.payload
      };
    }

    case fromLayout.LayoutActionTypes.SetIfSavingDashboard: {
      return {
        ...state,
        isSavingDashboard: action.payload
      };
    }

    case fromLayout.LayoutActionTypes.SetIfChatIsOpen: {
      return {
        ...state,
        isChatOpen: !state.isChatOpen
      };
    }

    default: {
      return { ...state };
    }
  }

}
