import { ProductEffect } from './product.effect';
import { CategoryEffect } from './category.effect';
import { WidgetEffect } from './widget.effect';
import { CustomerEffect } from './customer.effect';
import { CompanyEffect } from './company.effect';

export const effects: any[] = [
  ProductEffect,
  CategoryEffect,
  CustomerEffect,
  WidgetEffect,
  CompanyEffect
];

// export * from './product.effect';
// export * from './category.effect';
// export * from './customer.effect';
// export * from './widget.effect';
// export * from './layout.effect';
