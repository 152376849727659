import * as fromProducts from '../actions/product.actions';
import { FlowProductModelInterface } from '@flow/core';

export interface ProductModelStateInterface {
  total: number;
  data: FlowProductModelInterface[];
  loaded: boolean;
}

const initialState: ProductModelStateInterface = {
  total: 0,
  data: [],
  loaded: false,
};

export function reducer(
  state = initialState,
  action: fromProducts.FlowProductsActions
): ProductModelStateInterface {
  switch (action.type) {
    case fromProducts.ProductsActionTypes.LoadProducts: {
      return state;
    }

    case fromProducts.ProductsActionTypes.LoadProductsSuccess: {

      return {
        ...state,
        total: action.payload.total,
        data: action.payload.data,
        loaded: true
      };
    }

    case fromProducts.ProductsActionTypes.LoadProductsFail: {
      return {
        ...state,
        total: 0,
        data: [],
        loaded: true
      };
    }

    case fromProducts.ProductsActionTypes.UnloadProducts: {
      return {
        ...state,
        data: [],
        total: 0,
        loaded: false
      };
    }

    default: {
      return { ...state };
    }
  }
}
