import { Action } from '@ngrx/store';

export enum WidgetsActionTypes {
  LoadWidgets = '[FLOW Marketplace] FLOW Widgets LOAD',
  LoadWidgetsSuccess = '[FLOW Marketplace] FLOW Widgets LOAD SUCCESS',
  LoadWidgetsFail = '[FLOW Marketplace] FLOW Widgets LOAD FAIL',
  UnloadWidgets = '[FLOW Marketplace] UNLOAD WIDGETS'
}

export class LoadWidgets implements Action {
  readonly type = WidgetsActionTypes.LoadWidgets;
  constructor(public payload: any = {}) {}
}

export class LoadWidgetsSuccess implements Action {
  readonly type = WidgetsActionTypes.LoadWidgetsSuccess;

  constructor(public payload: any) {}
}

export class LoadWidgetsFail implements Action {
  readonly type = WidgetsActionTypes.LoadWidgetsFail;

  constructor(public payload: string) {}
}

export class UnloadWidgets implements Action {
  readonly type = WidgetsActionTypes.UnloadWidgets;
}

export type FlowWidgetsActions = LoadWidgets | LoadWidgetsSuccess | LoadWidgetsFail | UnloadWidgets;
