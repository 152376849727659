import {
  animate, group, query,
  style,
  transition,
  trigger
} from '@angular/animations';

export const fadeInOutAnimation = trigger(
  'fadeInOutAnimation',
  [
    transition(
      ':enter',
      [
        style({opacity: 0}),
        animate('400ms ease-in',
          style({opacity: 1}))
      ]
    ),
    transition(
      ':leave',
      [
        style({opacity: 1}),
        animate('300ms ease-in',
          style({opacity: 0}))
      ]
    )
  ]
);

export const fadeOutDelayedAnimation = trigger(
  'fadeOutDelayedAnimation',
  [
    transition(
      ':leave',
      [
        style({opacity: 1}),
        animate('1000ms ease-in',
          style({opacity: 0}))
      ]
    )
  ]
);

export const slideInOutAnimation = trigger(
  'slideInOutAnimation',
  [
    transition(
      ':enter',
      [
        style({height: 0, opacity: 0}),
        animate('300ms ease-in-out',
          style({height: '*', opacity: 1}))
      ]
    ),
    transition(
      ':leave',
      [
        style({height: '*', opacity: 1}),
        animate('300ms ease-in-out',
          style({height: 0, opacity: 0}))
      ]
    )
  ]
);

export const slideInOutStateAnimation = trigger(
  'slideInOutStateAnimation',
  [
    transition(
      'opened',
      [
        style({height: 0, opacity: 0}),
        animate('300ms ease-in-out',
          style({height: '*', opacity: 1}))
      ]
    ),
    transition(
      'closed',
      [
        style({height: '*', opacity: 1}),
        animate('300ms ease-in-out',
          style({height: 0, opacity: 0}))
      ]
    )
  ]
);
