/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 * https://docs.userlane.com/en/collections/1813884-technical-documentation#userlane-api-commands
 */

import { Injectable } from '@angular/core';

import { FlowEnvService, FlowUtilsService } from '@flow/core';
import { FlowAuthService, FlowUserService } from '@flow/auth';

declare let Userlane: any;

@Injectable({
  providedIn: 'root'
})
export class FlowUserlaneService {

  private _currentUserTags: string[];
  private _loaded = false;

  get loaded(): boolean {
    return this._loaded;
  }

  set loaded(loaded: boolean) {
    this._loaded = loaded;
  }

  constructor(
    private EnvService: FlowEnvService,
    private UtilsService: FlowUtilsService,
    private AuthService: FlowAuthService,
    private UserService: FlowUserService
  ) { }

  init(): void {
    this._currentUserTags = this.getCurrentUserTags();

    Userlane('init', 31682);
    Userlane('config', {elementSearchAttempts: 60});
    Userlane('user', this.UserService.username);
    Userlane('tag', ...this._currentUserTags);
    Userlane('lang', this.UserService.language);
  }

  openAssistant(): void {
    Userlane('openAssistant');
  }

  hide(): void {
    if (typeof Userlane !== 'function') {
      return;
    }

    Userlane('untag', ...this._currentUserTags);
    Userlane('hide');
  }

  /**
   * Collect and return tags used for user segmentation at userlane and zendesk
   *
   * @param type userlane (default) or zendesk
   */
  getCurrentUserTags(type = 'userlane'): string[] {
    const vendor = this.AuthService.vendor;
    const company = this.UserService.company;
    const isPartner = this.UserService.isActingAsPartner();
    const env = this.EnvService.get('name');
    const country = `country:${this.UserService.customer.Country}`;

    let currentUserTags = [];

    // Used for user segmentation
    currentUserTags = [
      ...[
        // ensures that productive, i.e. tested userlanes/zendesk articles are shown as fallback
        env ? `IS${env.toUpperCase()}` : 'ISPRODUCTION',
        company.toUpperCase(),
        country,
        vendor,
        isPartner ? `ISPARTNER_${vendor}` : `ISVENDOR_${company}`,
        isPartner ? `ISPARTNER` : `ISVENDOR`,
      ],
      ...this.UserService.roles,
      ...this.UserService.boughtProducts
    ];

    if (type === 'userlane') {
      currentUserTags.unshift('ISVERSION2');

      // Used for showing the userlane to "Company Integration Settings"
      if (this.UtilsService.hasExtra(this.UserService.customer.ExtendedProperties, 'OnboardingSource')) {
        currentUserTags.push('OnboardingSource');
      }
    }

    return currentUserTags;
  }
}
