/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import {FlowComponentsModule, FlowMaterialModule, Flow2FaModule, FlowPipesModule} from '@flow/shared';

import { FlowLayoutModule } from '../layout/layout.module';

import { FlowMissingInfoRoutingModule } from './missing-info-routing.module';
import { FlowMissingInfoComponent } from './containers/missing-info/missing-info.component';
import { FlowMissingInfoFormComponent } from './components/missing-info-form/missing-info-form.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    FlowPipesModule,
    ReactiveFormsModule,
    FlowComponentsModule,
    FlowMaterialModule,
    FlowLayoutModule,
    FlowMissingInfoRoutingModule,
  ],
  declarations: [
    FlowMissingInfoComponent,
    FlowMissingInfoFormComponent
  ]
})

export class FlowMissingInfoModule {
}
